import agent from '~/src/shared/locales/domains/agent/agent-ES'
import backoffice from '~/src/shared/locales/domains/backoffice/backoffice-EN'
import crm from '~/src/shared/locales/domains/crm/crm-ES'
import devices from '~/src/shared/locales/domains/devices/devices-ES'
import floorplan from '~/src/shared/locales/domains/floorplan/floorplan-ES'
import guestEngagement from '~/src/shared/locales/domains/guest-engagement/guest-engagement-ES'
import lite from '~/src/shared/locales/domains/lite/lite-ES'
import menuManagement from '~/src/shared/locales/domains/menu-management/menu-management-ES'
import menus from '~/src/shared/locales/domains/menus/menus-ES'
import ordering from '~/src/shared/locales/domains/ordering/ordering-ES'
import permissions from '~/src/shared/locales/domains/permissions/permissions-ES'
import reports from '~/src/shared/locales/domains/reports/reports-ES'
import reservations from '~/src/shared/locales/domains/reservations/reservations-ES'
import settings from '~/src/shared/locales/domains/settings/settings-ES'
import staff from '~/src/shared/locales/domains/staff/staff-ES'
import venueInfo from '~/src/shared/locales/domains/venue-info/venue-info-ES'

const general = {
  add_device: 'Agregar Dispositivo',
  add_device_confirm_title: 'Modo de Demostración Encendido',
  add_device_confirm_message:
    'El restaurante está en modo de demostración. Comuníquese con soporte antes de agregar cualquier otro dispositivo.',
  add_modifier: 'Agregar Modificador',
  add_modifiers: 'Agregar Modificadores',
  add_schedule: 'Añadir horario',
  add_scheduled_report_success:
    'La programación de su informe se realizó correctamente. Su informe se está procesando antes de enviarlo.',
  add_scheduled_report_error: 'Hubo un error al programar su informe.',
  additional_settings: 'Configuración Adicionales',
  address: 'Address',
  ai_generated: '[ES] Generated by AI',
  ai_disclaimer:
    '[ES] This content will be generated by OpenAI. Always review AI-generated content before saving. You agree to be bound by <1>OpenAI’s TOS</1> and <2>TouchBistro’s TOS</2>, and you accept that you, not TouchBistro, are responsible for the content you save.',
  alcohol: 'Alcohol',
  alcohol_margins: 'Márgenes de Alcohol',
  all: 'Todos',
  all_restaurant_groups: '[ES] All restaurant groups',
  all_restaurant_groups_text:
    "[ES] Selecting a group includes all current and future venues for that group. Selecting individual venues won't apply changes to the group level.",
  all_venues: 'Todos los restaurantes',
  all_locations: 'Todas las localizaciones',
  allow_returns: 'Permitir devoluciones',
  allows_three_taxes: 'TouchBistro permite hasta tres impuestos.',
  alphabetically: 'Alfabetico',
  alphanumeric_error: 'El nombre de impuesto solo puede contener numeros y letras.',
  alternative_image_text: 'Imagen alternativa',
  america_mexico_city: 'América / Ciudad de México',
  america_toronto: 'América / Toronto',
  and_the: ' y el',
  answer: 'Responder',
  apiKey: 'API Key',
  appID: 'App ID',
  applicable_menu_groups_helper_text:
    'Seleccione las categorias de menu a las que se puede aplicar este descuento.',
  applicable_menu_groups: 'Categorias de Menu Aplicables',
  apply: 'Aplicar',
  avero_partner_config_bad_state_error:
    'La integración con Avero está habilitada pero aún no se ha configurado',
  back_button: 'Espalda',
  bar_tab: 'Cuenta del bar',
  base_number_of_hours: 'Base # de horas',
  bill_report: 'Informe de Factura',
  bills: 'Facturas',
  biweekly: 'Quincenal',
  bills_with_outstanding_balance: 'Facturas Con Saldo Pendiente',
  break_details: 'Detalles de Ruptura',
  cancel: 'Cancelar',
  cannot_delete:
    'No puede eliminar esta página de menú. El menú debe tener al menos una página de menú',
  cannot_find_user: 'No se pudo encontar usuario con VenueID or userID',
  cannot_find_user_generic: 'No se pudo encontar usuario',
  category_default: 'Predeterminado por Categoria',
  category: 'Categoria',
  choose_your_reports: 'Elige Tus Reportes',
  clear: 'Limpiar',
  close: 'Cerrar',
  cloud_name: 'Cloud Name',
  cloud_pos_successfully_linked: '¡Tus cuentas de TPV y de Cloud se han vinculado con éxito!',
  cloud_pos_successfully_linked_paragraph:
    'Ahora puedes acceder libremente a Cloud a través del TPV sin tus credenciales de Cloud. ',
  color: 'Color',
  configure: 'Configurar',
  get_a_quote: 'Obtener Cotización',
  published_by: '[ES] Published By',
  connect: '[ES] Connect',
  confirm_dialogue_content: 'La certeza de esta información te ayuda a proteger tu cuenta.',
  confirm_dialogue_title: 'Por favor revisa y confirma tu información.',
  confirm_message: '¿Estas seguro de dejar esta pagina sin guardar tus cambios?',
  confirm_publish: '¿Estás seguro de que deseas publicar todos los cambios del menú?',
  confirm_exit_dialog_title: '[ES] You have unsaved changes.',
  confirm_exit_dialog_message:
    '[ES] These changes will not be saved.\n Are you sure you want to leave?',
  confirm_remove: 'Confirmar Eliminación',
  confirm: 'Confirmar',
  contact_support: 'Soporte de contacto',
  contact_me: 'Contáctame\n',
  try_now: 'Prueba ahora',
  content: 'contenido',
  continue_sign_up: 'Configurar la integración con la unidad DoorDash.',
  continue_without_saving: 'Continuar sin guardar',
  continue: 'Continuar',
  continue_setup: '[ES] Continue Setup',
  copied: 'Copiado',
  copy_access_token_text: 'Haz click aqui para copiar el token',
  cost: 'Costo',
  cost_of_goods_sold: 'Costo de los bienes vendidos',
  course: 'Plato',
  course1: 'Plato 1',
  course10: 'Plato 10',
  course11: 'Plato 11',
  course12: 'Plato 12',
  course2: 'Plato 2',
  course3: 'Plato 3',
  course4: 'Plato 4',
  course5: 'Plato 5',
  course6: 'Plato 6',
  course7: 'Plato 7',
  course8: 'Plato 8',
  course9: 'Plato 9',
  coverdash_consent:
    'Al hacer clic arriba, das tu consentimiento para permitir que nuestra empresa comparta tu información con Coverdash con el propósito de proporcionarte cotizaciones de seguro personalizadas y opciones de cobertura.',
  coverdash_dashboard_button: 'Ir al panel de Coverdash',
  coverdash_existing_policies: 'My Existing Policies',
  coverdash_get_quote_subtitle:
    'Explora pólizas de seguro que se ajusten a las necesidades de tu negocio, desde responsabilidad general hasta ciberseguridad y más.',
  coverdash_get_quote_title: 'Obtén una cotización en tan solo unos pocos pasos rápidos',
  coverdash_page_title: 'TouchBistro x Coverdash',
  'covid-19_resources': 'Recursos de COVID-19',
  'covid-19_resources_description':
    'Hemos reunido recursos para ayudarte a superar el COVID-19. Su objetivo es ayudarte a aumentar las ventas, mejorar los márgenes, administrar tus finanzas y manejar los problemas de seguridad.',
  covid_resources_title: 'Éxito de Restaurantes Durante COVID-19',
  covid_resources_statement_1:
    'Hablamos con muchos restauradores sobre cómo están superando el COVID-19. Escuche sobre su',
  covid_resources_statement_2: ' historias de supervivencia ',
  covid_resources_statement_3: 'aquí',
  covid_resources_statement_4:
    'Analizamos los datos de los restaurantes y encontramos algunas ideas:',
  covid_resources_statement_5:
    'Las cervecerías están teniendo éxito cuando facilitan a los clientes realizar pedidos en línea y tienen opciones de formato más grande (paquetes de varios sabores, paquetes de 6 y 24).',
  covid_resources_statement_6:
    'Los restaurantes tienen éxito cuando facilitan a los clientes realizar pedidos online, Vender productos secos / envasados ​​y comunicarse frecuentemente con los clientes a través de las redes sociales. (Facebook o Instagram).',
  covid_resources_marketing_statement_1:
    'Para ayudar a aumentar las ventas, asegúrese de que su sitio web y sus cuentas de redes sociales tengan contenido atractivo. ¿Qué deberías publicar? ¿Necesitas un profesional de las redes sociales?',
  covid_resources_marketing_statement_2: ' Nuestra guía de marketing gratuita ',
  covid_resources_marketing_statement_3: 'responde estas preguntas y más.',
  covid_resources_delivery_commissions_statement_1:
    '¿Quiere reducir las comisiones de las aplicaciones de terceros? Puede ofrecer a los comensales una forma sencilla de realizar pedidos directamente a usted con ',
  covid_resources_delivery_commissions_statement_2:
    ' (¡y es gratis durante los primeros 3 meses!). Aquí están',
  covid_resources_delivery_commissions_statement_3:
    ' cinco razones por las que realizar pedidos directos en línea ',
  covid_resources_delivery_commissions_statement_4:
    'es fundamental en este momento. Además, para ayudar a generar más tráfico a su sitio, consulte nuestra',
  covid_resources_delivery_commissions_statement_5: ' guía de marketing ',
  covid_resources_delivery_commissions_statement_6: 'de tácticas que pueden ayudar.',
  covid_resources_alcohol_margins_statement_1:
    'La comida para llevar y la entrega de bebidas alcohólicas pueden ayudarlo a liberar flujo de efectivo y aumentar los márgenes con un mínimo esfuerzo. Hemos reunido',
  covid_resources_alcohol_margins_statement_2: ' esta guía ',
  covid_resources_alcohol_margins_statement_3: 'ayudar.',
  covid_resources_safety_operations_basics_statement_1: 'Nosotros tenemos',
  covid_resources_safety_operations_basics_statement_2:
    ' un puñado de consejos de seguridad alimentaria ',
  covid_resources_safety_operations_basics_statement_3:
    'que debería implementar. Asegúrese de comunicarse también con los comensales durante este tiempo -',
  covid_resources_safety_operations_basics_statement_4: ' aquí hay algunos consejos ',
  covid_resources_safety_operations_basics_statement_5: 'sobre cómo hacer precisamente eso.',
  covid_resources_finances_rent_statement_1:
    'Hay mucha información sobre la financiación de pequeñas empresas en línea, por lo que hemos intentado recopilarla en nuestro blog.',
  covid_resources_finances_rent_statement_2:
    'Para los restaurantes canadienses, hemos elaborado una lista de opciones de financiación',
  covid_resources_finances_rent_statement_3: ' aquí ',
  covid_resources_finances_rent_statement_4: '. Si prefiere mirar o escuchar, mire',
  covid_resources_finances_rent_statement_5: ' nuestro webinar ',
  covid_resources_finances_rent_statement_6: 'sobre el mismo tema.',
  covid_resources_finances_rent_statement_7:
    'También analizamos qué arreglos han hecho los restaurantes con los propietarios para ayudarlos a superar este período.',
  covid_resources_finances_rent_statement_8: ' Esto es lo que encontramos',
  create_category: 'Create Category',
  create_discount: 'Crear Descuento',
  create_menu_group_success: '{{menuGroup}} ha sido añadido a tu categoria de menus',
  create_menu_group: 'Crear Categoria de Menu',
  create_menu_item: 'Crear Elemento de Menu',
  add_menu_item: '[ES] Add Menu Item',
  create_menu_success: '{{menuName}} ha sido añadido a tus menus',
  create_menu: 'Crear Pagina de Menu',
  create_modifier: 'Create Modifier',
  create_new_tax: 'Crear Nuevo Impuesto',
  create_payout_name: 'Create Payout Name',
  create_payout_reason: 'Create Payout Reasons',
  create_permission: 'crear',
  create_policy: 'Crear Norma',
  create_printer: 'Crear Impresora',
  create_role: 'Crear Rol',
  create_sales_category_success: '{{salesCategoryName}} ha sido creado exitosamente',
  create_sales_category: 'Crear categoria de ventas',
  create_scheduled_report: 'Crear informe programado',
  create_take_out: 'Create Take Out',
  create_tax: 'Crear impuesto',
  create_void_success: '{{void}} ha sido agregado a tus razones nulas',
  create_void: 'Crear razón vacía',
  create_deposit: '[ES] Create Deposit',
  edit_deposit: '[ES] Edit Deposit',
  view_history: '[ES] View History',
  history: '[ES] History',
  custom: 'Personalizado',
  daily: 'Diario',
  dashboard: 'Tablero',
  dashboard_average_spend: 'Gasto Promedio',
  dashboard_bills: 'Cuentas',
  dashboard_customers: 'Clientes',
  dashboard_gratuities: 'Propinas',
  dashboard_net_sales: 'Las Ventas Netas',
  dashboard_parties: 'Fiestas',
  dashboard_per_seat: 'Por Asiento',
  dashboard_sales_total: 'Total de Ventas',
  dashboard_seats: 'Asientos',
  dashboard_tips: 'Consejos',
  date: 'Fecha',
  date_range: 'Rango de fechas',
  day_end_time: 'Fin del día',
  day_of_the_week: 'Dia de la Semana',
  day_part_create: 'Crear parte del día',
  day_part_save: 'Guardar parte del día',
  day_part_start_time: 'Hora de inicio de la parte del día',
  day_part_update: 'Actualizar parte del día',
  day_parts: 'Partes del día',
  day_start_time: 'Día comienzo',
  default_color_checkbox: 'Usar color predeterminado',
  default_colour: 'Color predeterminado',
  default_course_checkbox: 'Usar curso predeterminado',
  default_course: 'Plato predeterminado',
  default_forced_mod_count: 'Numero de Modificadores predeterminado',
  default_printer_checkbox: 'Usar impresora predeterminada',
  default_printer: 'Impresora predeterminada',
  default_tax_helper_text:
    'El ajuste del impuesto predeterminado solo afectará a los nuevos elementos del menu',
  default_taxes_checkbox: 'Usar Impuestos predeterminada',
  default_taxes: 'Impuestos predeterminados',
  delete_device: 'Eliminar el dispositivo',
  delete_device_confirm: 'Sí, eliminar el dispositivo',
  delete_device_prompt: '¿Estás seguro de que deseas eliminar este dispositivo?',
  delete_device_information: 'Esto hará que este dispositivo sea inutilizable inmediatamente',
  delete_discount_message: '{{discount}} ha sido eliminado de tu descuentos.',
  delete_menu_group_message: '{{menuGroup}} ha sido eliminado de tu categorias de menu',
  delete_menu_group_with_menu_items:
    'No se puede eliminar. Para eliminar una categoria de menu, primero los elementos de menu asociados a este menu de categoria tienen que ser eliminados o ser asignados a otra categoria de menu',
  delete_menu_item_message: '{{menuItem}} ha sido eliminado de tu elementos del menu.',
  delete_menu_message: '{{menu}} ha sido eliminado de sus páginas de menú.',
  delete_permission: 'eliminar',
  delete_role_message: '{{roleName}} ha sido eliminado de tus roles.',
  delete_scheduled_report_message: '{{report}}ha sido eliminado de tus reportes horarios.',
  delete_scheduled_report_error: 'Se produjo un error al eliminar su informe.',
  delete_void_message: "'{{void}}' ha sido eliminado de tus razones nulas.",
  delete_role: '[ES] Delete Role',
  delete: 'Eliminar',
  delivery_commissions: 'Comisiones de Entrega',
  details: 'Detalles',
  online_ordering_delivery: 'Entrega',
  online_ordering_delivery_method: 'Método de entrega',
  description: 'Descripción',
  devices: 'Dispositivos',
  devices_tab1: 'Dispositivos',
  devices_tab2: 'Ajustes de Dispositivos',
  devices_pos: '[ES] POS',
  devices_reservations: '[ES] Reservations',
  devices_last_update_at: 'Ultima Actualización:',
  devices_main_device: 'Dispositivo Principal',
  devices_satellite_device: 'Dispositivo Satélite',
  devices_connect_main_device: 'Conectar dispositivo principal',
  devices_connect_device: 'Conectar dispositivo',
  devices_unknown_model: 'Dispositivo desconocido',
  device_deletion_disabled_due_to_escalation:
    '[ES]Deleting the app is not possible right now, this venue has an ongoing customer escalation that is being investigated. Please contact the Pro Team for more information.',
  devices_empty_landing_text:
    'Tu Restaurante aún no está conectado a tu Dispositivo Principal. Una vez conectado, podrás terminar de configurar tu POS para comenzar a tomar pedidos.',
  devices_reservations_empty_landing_text:
    '[ES] This location is not yet connected to a device. Once connected you will be able to start managing your bookings from the Reservations App.',
  dial_code: 'País',
  digits_only: 'Please enter numbers only',
  dine_in: 'Para comer aquí',
  disable_rmm_confirmation_message:
    '¿Estas seguro de desactivar el administrador de menu? una vez desactivado, solo pordras habilitar el administrador de menu desde el iPad del local.',
  disable_rmm_success: 'Administracion de menu ha sido deshabilitado',
  disable_rmm: 'Desactivar Administrador de Menu Remoto',
  disabled: 'Deshabilitado',
  disable_pos_card_title:
    'Realiza el seguimiento de las funciones que pasan de su aplicación POS a la nube',
  disable_pos_card_description_1:
    'A lo largo de este año, haremos que varias funciones, como su menú, estén disponibles solo en la nube (',
  disable_pos_card_description_2: 'admin.touchbistro.com',
  disable_pos_card_description_3:
    ') y las removeremos de su aplicación POS. Estamos haciendo esto para simplificar sus operaciones al permitirle administrar su negocio en una sola plataforma. Para garantizar una transición sin problemas, lo alentamos a usted y a su personal a comenzar a administrar su restaurante en la nube en lugar de su aplicación POS siempre que sea posible. ',
  disable_pos_card_description_4:
    'Manténgase actualizado sobre las próximas funciones que se trasladarán de su aplicación POS a la nube y el estado de estos cambios haciendo clic en el botón a continuación o visitando ',
  disable_pos_card_description_5: 'www.touchbistro.com/feature-removal-status.',
  disable_pos_card_description_6:
    'Visite esta página de estado con regularidad para asegurarse de que está al tanto de todos los próximos cambios por adelantado. ',
  disable_pos_card_description_7:
    'También puede encontrar enlaces a guías de ayuda y preguntas frecuentes en esta página.',
  discount_details: 'Detalles de Descuento',
  discount_summary: 'Resumen de Descuento',
  discount_dollar_amount: 'Importe de Descuento',
  discount_percentage: 'Porcentaje de Descuento',
  discount_reason: 'Motivo de Descuento',
  discounts: 'Descuentos',
  discount_graph_header_2: 'Razones principales de {{name}}',
  top_staff_amount: 'Importe total de {{name}}',
  top_staff_quantity: 'Cantidad total de {{name}}',
  staff_with_most: 'Personal con la mayoría de {{name}}s',
  dont_print_printer_name: 'No Imprimir',
  download: 'Download',
  download_certificate: 'Download Certificate',
  drill_down: 'Detallado',
  drinks: 'Drinks',
  edit: 'Editar',
  edit_corporation_name: 'Editar nombre de la corporación',
  edit_discount: 'Edit Discount',
  edit_menu_group: 'Editar Categoria de Menu',
  edit_menu_item: 'Editar elemento de Menu',
  edit_menu: 'Editar Pagina de Menu',
  edit_printer: 'Editar Impresora',
  edit_profile: 'Editar perfil',
  edit_role: 'Editar Rol',
  edit_role_page_disabled:
    'This page is no longer available. Please instruct the customer to add members to their corporation using the staff page',
  edit_scheduled_report_success: '{{report}} Reporte ha sido actualizado',
  edit_scheduled_report: 'Editar Reporte Horario',
  edit_tax: 'Editar Impuestos',
  edit_venue_roles: 'Editar Roles de Local',
  edit_void: 'Editar Motivo para anular',
  email_sent_dialogue_content_email: '{{email}}',
  email_sent_dialogue_content_end:
    ' Por favor revisa tu bandeja de entrada para que puedas empezar..',
  email_sent_dialogue_content_start: 'Hemos mandado una nueva liga a ',
  email_sent_dialogue_title: 'Una nueva liga va en camino',
  email_verify_text: 'Tu correo electrónico ha sido verificado',
  email: 'Correo electrónico',
  email_addresses: 'Direcciones de email',
  email_frequency: 'Frecuencia de Correo Electrónico',
  empty_list:
    'Parece que no tienes ninguna {{type}} configurada. Preciona para "Crear" arriba para comenzar.',
  empty_menu_item_association:
    'No hay elementos del menu añadidos a este gropo de menu en este momento',
  empty_modifier_list:
    'Parece que no tienes ninguna {{type}} configurada. Preciona para {{actionName}} arriba para comenzar.',
  enable_menu_item: 'Habilitar Elemento de Menu',
  enable_menu: 'Habilitar Pagina de Menu',
  enabled: 'Habilitado',
  end_date: 'Ficha de finalización',
  end_of_the_bill: 'Fin de la factura',
  end_time: 'Hora de finalización',
  english_uk: 'Inglés (del Reino Unido)',
  english_us: 'Inglés (EE. UU.)',
  errored_at_utc: 'Errored At(UTC)',
  errored_payloads: 'Errored Payloads',
  error_max_taxes:
    'Has alcanzado el limite de tres impuestos. Para crear un nurvo impuesto, tendras que eliminar uno de los impuestos existentes',
  error_notification_message: 'Oops! Hubo un error interno en el servidor',
  error_update_legacy_user_email_already_exist:
    'Este correo electrónico ya está en uso. Si tiene varios lugares que le gustaría vincular a esta dirección de correo electrónico, comuníquese con soporte.',
  error_please_refresh: 'Algo salio mal, por favor actualize la pagina e intente denuevo',
  excluded_order_types: 'Tipo de Ordenes Excluidos',
  expired_error_button: 'Enviarme una nueva liga',
  expired_error_text_1: 'Lo sentimos, esta liga ya no es válida.',
  expired_error_text_2:
    'La liga expira después de {{timePeriod}} o si se solicita una liga nueva y ésta sólo puede ser utilizada una vez.',
  export_staff: '[ES] Export Staff',
  export_staff_failed: '[ES] Failed to export staff',
  email_verification_validity: '36 horas',
  signup_validity: '30 días',
  success_to_delete_device: '[ES] The device was deleted.',
  failed_to_delete_device: 'Sorry, the device could not be deleted.',
  failure_invalid_credentials:
    'Lo sentimos, tus credenciales no son válidas. Por favor revísalas e intente nuevamente.',
  failure_notification_avero_invalid_busid:
    'El Business ID que ingresaste de Avero no es válido. Por favor, asegúrate de ingresar el Business ID correcto o contacta a Avero para recibir asistencia.',
  failure_notification_busid: 'Este Avero Business ID está asignado a otro local',
  failure_notification_buyerguid:
    'El GUID de comprador de MarketMan es inválido. Por favor revisa que hayas ingresado el GUID correcto de comprador, o contacta a MarketMan para más ayuda.',
  failure_notification_message_common_password:
    "La contraseña no puede contener patrones comunes como '1234' o 'contraseña'",
  failure_notification_message: 'Tus cambios no fueron guardados',
  failure_publish: 'Error al publicar Inténtalo de nuevo.',
  failure_sales_category_duplicate_name: 'Este nombre ya ha sido tomado',
  failure_submission: 'Error al enviar Inténtalo de nuevo.',
  favorite: 'Favorito',
  finances_rent: 'Finanzas y Renta',
  first_name: 'Nombre de pila',
  following_timezone: 'En la siguiente zona horaria',
  font: '[ES] Font',
  food: 'Comida',
  forced_mod_count: 'Numero de Modificadores',
  format: 'Formato',
  frequency: 'Frecuencia',
  friday: 'Viernes',
  get_started: 'Comenzar',
  get_started_alt: 'Ilustración de un hombre moviendo bloques',
  gift_card: 'Este elemento es una tarjeta de regalo',
  go_back: 'Regresa',
  go_to: 'Ir a',
  go_to_cloud: 'Ir a la nube',
  gratuity_and_service_charges_summary: 'Resumen de Cargos de Servicio y Propina',
  greater_than_zero_error: 'El numero tiene que ser mayor que 0',
  gross_margin_by_day: 'Margen Bruto Por Día',
  group: 'Grupo',
  groups: 'Grupos',
  groups_limited_access_warning: '[ES] You have limited access to some restaurant groups',
  help: 'Ayuda',
  hide: 'Esconder',
  hourly_labor_cost: 'Costo Laboral Por Hora',
  hours_summary: 'Resumen de Horas',
  icon: 'Icono',
  view: 'Vista',
  view_staff: '[ES] View Staff',
  view_venues: '[ES] View Venues',
  view_details: '[ES] View Details',
  image: '[ES] Image',
  import_menu: 'Menú Importar/Exportar',
  important: '[ES] Important',
  included_item_price: 'Incluido en el precio del artículo',
  included_reports: 'Reports Incluidos',
  inclusive_tax_on_reports: 'Impuesto Inclusivo para Informes de iOS',
  inclusive_tax_settings_updated: 'Configuracion de impuestos inclusivos actualizados',
  inclusive_tax: 'Impuesto Incluido',
  integration_partners_card_description:
    'Estamos integrados con una amplia gama de aplicaciones, procesadores de pagos y socios de software para hacer de TouchBistro una solución todo en uno para su negocio.',
  integration_partners: 'Socios de integración',
  invalid_date: 'Fecha inválida',
  invalid_email_format: 'Formato de email inválido',
  invalid_email: 'Dirección de correo electrónico no válida',
  invalid_money_format: 'Introduzca numeros (maximo dos decimales sin el simbolo % )',
  invalid_phone_number: 'Formato de teléfono inválido',
  invalid_referral_form_phone_number: 'Número de teléfono inválido, expected format +1 2223334444',
  invalid_reduced_tax_percentage_format:
    'Ingrese un valor numérico entre 0 y 100 solamente, hasta dos decimales y sin símbolo%',
  invalid_tax_percentage_format:
    'Ingrese un valor numérico entre 0.01 y 100 solamente, hasta dos decimales y sin símbolo %',
  is_taxable: 'Es sujeto a impuesto',
  item_saved_success: '{{item}} ha sido guardado',
  items_saved_success: '{{item}} han sido guardados',
  labor_cost_analysis: 'Análisis de Costos Laborales',
  landscape: 'Paisaje',
  last_name: 'Apellido',
  last_week: 'La Semana Pasada',
  last_30_days: 'Ultimos 30 Días',
  last_month: 'El Mes Pasado',
  last_6_months: 'Ultimos 6 Meses',
  last_year: 'El Año Pasado',
  locationID: 'Location ID',
  safety_operations_basics: 'Conceptos Básicos de Seguridad y Operaciones',
  start_time_of_days_0: 'MIDNIGHT',
  start_time_of_days_1: '1 AM',
  start_time_of_days_2: '2 AM',
  start_time_of_days_3: '3 AM',
  start_time_of_days_4: '4 AM',
  start_time_of_days_5: '5 AM',
  start_time_of_days_6: '6 AM',
  start_time_of_days_7: '7 AM',
  start_time_of_days_8: '8 AM',
  start_time_of_days_9: '9 AM',
  start_time_of_days_10: '10 AM',
  start_time_of_days_11: '11 AM',
  start_time_of_days_12: 'NOON',
  start_time_of_days_13: '1 PM',
  start_time_of_days_14: '2 PM',
  start_time_of_days_15: '3 PM',
  start_time_of_days_16: '4 PM',
  start_time_of_days_17: '5 PM',
  start_time_of_days_18: '6 PM',
  start_time_of_days_19: '7 PM',
  start_time_of_days_20: '8 PM',
  start_time_of_days_21: '9 PM',
  start_time_of_days_22: '10 PM',
  start_time_of_days_23: '11 PM',
  end_time_of_days_0: '11:59 PM',
  end_time_of_days_1: '12:59 AM',
  end_time_of_days_2: '1:59 AM',
  end_time_of_days_3: '2:59 AM',
  end_time_of_days_4: '3:59 AM',
  end_time_of_days_5: '4:59 AM',
  end_time_of_days_6: '5:59 AM',
  end_time_of_days_7: '6:59 AM',
  end_time_of_days_8: '7:59 AM',
  end_time_of_days_9: '8:59 AM',
  end_time_of_days_10: '9:59 AM',
  end_time_of_days_11: '10:59 AM',
  end_time_of_days_12: '11:59 AM',
  end_time_of_days_13: '12:59 PM',
  end_time_of_days_14: '1:59 PM',
  end_time_of_days_15: '2:59 PM',
  end_time_of_days_16: '3:59 PM',
  end_time_of_days_17: '4:59 PM',
  end_time_of_days_18: '5:59 PM',
  end_time_of_days_19: '6:59 PM',
  end_time_of_days_20: '7:59 PM',
  end_time_of_days_21: '8:59 PM',
  end_time_of_days_22: '9:59 PM',
  end_time_of_days_23: '10:59 PM',
  entity_create_success: 'Se ha creado {{label}}',
  length_in_days: 'Longitud en días',
  length_of_days_field_validation_message: 'Debe ser un número entre 0 y 31',
  register: 'Registro',
  link_to_tb_cloud_reporting: 'Enlace al artículo de TouchBistro usando informes en la nube',
  link_to_tb_cloud_settings:
    'Enlace a la ayuda de configuración del informe de nube de artículos de TouchBistro',
  link_to_tb_partners: 'Enlace a los socios de TouchBistro',
  link_to_tb_online_orders: 'Enlace a Pedidos en Línea de TouchBistro',
  loading: 'Cargando...',
  locale: 'Lenguaje',
  locale_required: 'Se requiere idioma',
  logout: 'Salir',
  manage_name: 'Manage Name',
  manage_reports: 'Administrar Reportes',
  mandatory_modifier_description:
    'Incluido automáticamente en cada elemento de menu con este grupo de modificadores.',
  mandatory: 'Obligatorio',
  marketing: 'Marketing',
  member_information: 'Información de miembro',
  member_access_to: 'Este miembro tiene acceso a: ',
  menu_details: 'Detalles de la pagina del menu',
  menu_details_section: 'Detalles del menú',
  menu_analysis: 'Análisis de Menú',
  menu_group_cannot_be_deleted: 'No puede eliminar esta categoria de menu',
  menu_group_defaults_description:
    'Los predeterminados se establecen en las Categorías de menú y aparecerán cuando se seleccione una Categoría de menú arriba. Los predeterminados se pueden anular en un elemento del menú si lo desea.',
  menu_group_defaults: 'Predeterminados de Categoría de Menú',
  menu_group_details: 'Detalles del Categoria de Menu',
  menu_group: 'Categoria de Menu',
  menu_groups: 'Categorias de Menu',
  menu_inputting_service_in_progress:
    'Servicio de ingreso de menú en progreso, comuníquese con su agente de PS si tiene alguna pregunta.',
  menu_rmm3_migration_in_progress_title: 'Update in Progress',
  menu_rmm3_migration_in_progress_message:
    'Your Point of Sale (POS) app is currently undergoing an upgrade. As a result, the following functionality will be unavailable for editing until the update is completed.<1></1>The upgrade will take approximately 15-20 mins. It may be necessary to restart the app multiple times to ensure the update is fully implemented.',
  menu_ipad_orientation: '¿Cómo planeas sostener tu iPad?',
  menu_item_modifier: 'Modificador de elemento de menu',
  menu_item_name: 'Nombre elemento de menu',
  menu_item: 'Opción del menú',
  menu_items_details: ' Detalles de elemento de menu',
  menu_items: 'Elementos del Menu',
  menu_option_website: 'Utilice el menú de su sitio web',
  menu_management_info_text:
    'Una vez deshabilitado, solo vuelva a habilitarlo desde el iPad de su lugar.',
  menu_management: 'Gestión de menú',
  menu: 'Menu',
  menus: 'Paginas de Menu',
  menu_tax_apply: '¿A qué se aplicará el impuesto?',
  menu_taxes_applied: '¿Dónde le gustaría que se aplicaran los impuestos?',
  menu_tracking_inventory:
    '¿Planea realizar un seguimiento del inventario / costeo de sus artículos?',
  menu_url: 'URL del menú (opcional)',
  merchandise: 'Comerciar',
  midnight: 'medianoche',
  modifier_forced_mod_count_validation_message:
    'Forced Mod Count must be a number between 0 and 99',
  modifier_group_create_success: '{{modifierGroup}} ha sido añadido a tu grupo de modificadores',
  modifier_group_create: 'Crear Grupo de Modificador',
  modifier_group_details: 'Detalles del Grupo de Modificador',
  modifier_group_edit: 'Editar Grupo de Modificador',
  modifier_group_removed_success: '{{modifier_group}} ha sido removido del {{menu_item}}',
  modifier_groups: 'Grupos de Modificadores',
  modifier_preference_by_menu_item: 'Preferencia de Modificador por Elemento de Menú',
  modifier_removed_success: '{{modifier}} ha sido removido.',
  modifiers: 'Modificadores',
  monday: 'Lunes',
  money_value_too_high: 'El valor tiene que ser menor que 100,000,000',
  monthly: 'Mensual',
  multipliers_field_validation_message: 'Debe ser un número mayor que 1',
  multi_venue_reports: 'Informes de múltiples lugares',
  name: 'Nombre',
  new_touchbistro_user: '¿Es la primera vez que usas TouchBistro?',
  no: 'No',
  never: 'Nunca',
  nevermind: 'No importa',
  yes: 'Si',
  no_options_available: 'No hay opciones disponibles',
  no_report_data: 'Actualmente no tiene datos para el rango de fechas seleccionado.',
  no_report_data_alt: 'TouchBistro sin imagen de datos',
  no_results_found: 'No se encontraron resultados',
  non_zero_validation_message: 'Taxes cannot be 0 percent.',
  noon: 'mediodía',
  ok: 'OK',
  okay: 'Aceptar',
  select_a_venue: 'Seleccione un solo restaurant',
  select_role: 'Selecciona un Función',
  select_a_venue_or_corporation: 'Seleccione un solo restaurant/corporativa',
  select_a_group: '[ES] Select a group',
  select_venues: 'Seleccionar multiples restaurantes',
  select_all: 'Seleccionar todo',
  venue_settings_information_save_success: 'La configuración del lugar se guardó correctamente',
  venue_settings_information_server_error:
    'Se ha producido un error al guardar la información de tu lugar.',
  webinars: 'Seminarios Web',
  webinars_card_description:
    '¿Quiere obtener más información sobre cómo usar los diferentes productos con su cuenta TouchBistro Cloud?  ¡Regístrese para un seminario web o mire un seminario web anterior para aprender cómo usar Online Ordering, Lealtad, Marketing y más!',
  online_ordering: 'Pedidos en linea',
  online_ordering_on_maintenance: 'Estamos en mantenimiento. Vuelve a intentarlo más tarde.',
  online_ordering_save: 'Ahorrar',
  online_ordering_settings: 'Ajustes',
  online_ordering_advanced_settings: 'Ajustes avanzados',
  online_ordering_hours_and_closures: 'Horas de funcionamiento y cierres por vacaciones',
  online_ordering_hours: 'Horas y cierres',
  online_ordering_delivery_boundaries: 'Límites de entrega',
  online_ordering_payment_options: 'Opciones de pago',
  online_ordering_activation: 'Activación de página',
  online_ordering_description:
    'TouchBistro Online Ordering permite tomar ordenes en tu propia pagina de internet y facilita el paso de esa información directo a tu punto the venta. Haciendo pedidos mas conveniente para usted y sus clientes mientras se incrementan las ganancias para su bolsillo.',
  online_ordering_offer_delivery: 'Entrega de la oferta',
  online_ordering_offer_cutlery: 'Oferta cubiertos',
  online_ordering_offer_cutlery_tooltip:
    'Ofrezca a sus clientes cubiertos de plástico con sus comidas.',
  online_ordering_offer_tip: 'Sugerencia de oferta',
  online_ordering_offer_tip_tooltip:
    'Permita que sus clientes agreguen propina sobre pagos en línea',
  online_ordering_page_error:
    '¡Oh, no! Parece que hay un problema al cargar esta pantalla. Por favor, inténtelo de nuevo más tarde.',
  online_ordering_doordash_drive_delivery_payments: 'Pagos',
  online_ordering_doordash_drive_delivery_online_payment:
    'Para calificar para una entrega de DoorDash Drive, el pedido debe pagarse en línea.',
  online_ordering_doordash_drive_delivery_tbpayments:
    'Se requiere TouchBistro Online Payments para usar la integración con DoorDash Drive.',
  online_ordering_doordash_drive_delivery_driver_tip:
    'La propina está habilitada para todos los pedidos de entrega de DoorDash Drive. El repartidor recibirá el 100 % de la propina.',
  online_ordering_doordash_drive_delivery_radius_ca:
    'DoorDash Drive entrega hasta 8 kilómetros desde la dirección de su tienda. Vaya a Horas de funcionamiento > Tipo de orden y seleccione "Entrega" para completar la configuración de integración.',
  online_ordering_doordash_drive_delivery_radius_us:
    'DoorDash Drive entrega hasta 5 millas desde la dirección de su tienda. Vaya a Horas de funcionamiento > Tipo de orden y seleccione "Entrega" para completar la configuración de integración.',
  online_ordering_delivery_settings_food_prep_time_label:
    'Tiempo de preparación de alimentos (en minutos)',
  online_ordering_delivery_settings_food_prep_time_helper_text:
    'La cantidad estimada de tiempo que se tarda en preparar un pedido. No se programará la llegada de un repartidor hasta que haya transcurrido este tiempo.',
  online_ordering_delivery_settings_food_prep_time_validation_error:
    'El tiempo de preparación de alimentos en minutos debe estar entre 0 y 360',
  online_ordering_delivery_settings_food_prep_time_required:
    'Se requiere tiempo de preparación de alimentos.',
  online_ordering_delivery_food_prep_time_placeholder: 'Ingresa el tiempo de preparación',
  online_ordering_delivery_settings_delivery_fee_min_subtotal_label:
    'Gasto mínimo para ofrecer envío ($) (Opcional)',
  online_ordering_delivery_settings_delivery_fee_min_subtotal_helper_text:
    'Exige a los comensales que gasten un monto mínimo para ofrecerles envío.',
  online_ordering_delivery_settings_delivery_fee_min_subtotal_placeholder: '$30.00',
  online_ordering_delivery_settings_delivery_fee_min_subtotal_validation_error:
    'La cantidad máxima de gasto es de $1,000.',
  online_ordering_allow_scheduled_orders: 'Permitir pedidos programados',
  online_ordering_scheduling_days_in_advance: 'Programación con días de anticipación',
  online_ordering_scheduling_days_in_advance_placeholder: '(Requerida)',
  online_ordering_scheduling_days_in_advance_required_error: 'Ingrese un valor válido en días',
  online_ordering_scheduling_days_in_advance_validation_error:
    'La programación con días de anticipación debe ser mayor que 0 y menor que 31',
  online_ordering_scheduling_days_in_advance_tip:
    'Número de días (incluido el día de hoy) que puede programar un pedido por adelantado.',
  online_ordering_boundaries_instructions_drawing:
    'Comience a dibujar su zona límite haciendo clic con el cursor en el punto de partida seleccionado. Arrastre para crear la línea inicial de su límite deseado. Haga clic en el cursor cada vez que desee crear un nuevo punto desde el que arrastrar su línea. Asegúrese de conectar todas sus líneas haciendo clic en su punto inicial para cerrar su forma. Su zona de entrega ahora debería estar sombreada en rojo.',
  online_ordering_boundaries_title_content:
    'IMPORTANTE: Para poder agregar un límite de entrega, haga clic en el botón "Agregar límite" a continuación. Comience a dibujar su límite haciendo clic con el cursor en el mapa en su punto de partida. Haga clic por segunda vez para crear la línea de límite y repita hasta que tenga al menos 4 puntos seleccionados. Asegúrese de que su primer y último punto se conecten para cerrar el límite. Si el límite del mapa no se ve reflejado, por favor actualice la página.',
  online_ordering_boundaries_title_tip:
    'Propina: Para tener acceso a los costos de envío en los reportes, por favor actualice su punto de venta a la versión 10.1.0 o más.',
  online_ordering_boundaries_err_invalid_delivery_boundary:
    'Los límites no han podido salvar. Inténtalo de nuevo.',
  online_ordering_boundaries_err_line_delivery_boundary:
    'El límite no puede ser una línea recta. Inténtalo de nuevo.',
  online_ordering_boundaries_err_validation:
    'Los límites no han podido salvar. Inténtalo de nuevo.',
  online_ordering_boundaries_err_unknown_code:
    'Los límites no han podido salvar. Inténtalo de nuevo.',
  online_ordering_boundaries_err_invalid_min_subtotal: 'La cantidad máxima de gasto es de $ 1,000.',
  online_ordering_boundaries_err_duplicate_boundary_label:
    'Este nombre de límite ya está en uso. Elige un nombre nuevo.',
  online_ordering_boundaries_err_invalid_boundary_label_length:
    'El nombre del límite debe tener entre 1 y 60 caracteres',
  online_ordering_boundaries_err_invalid_wait_time:
    'El tiempo de espera límite debe ser inferior a 360 minutos.',
  online_ordering_boundaries_table_name: 'NOMBRE',
  online_ordering_boundaries_table_wait_time: 'TIEMPO DE ESPERA',
  online_ordering_boundaries_table_min_subtotal: 'GASTO MÍNIMO',
  online_ordering_boundaries_table_wait_time_min: '{{waitTime}} minutos',
  online_ordering_boundaries_table_delivery_fee: 'GASTOS DE ENVÍO',
  online_ordering_boundaries_table_add_delivery_fee: 'Agregar tarifa de envío',
  online_ordering_boundaries_table_no_boundaries:
    'No tiene límites establecidos. Comience a dibujar uno haciendo clic en el botón "Agregar límite".',
  online_ordering_boundaries_delete_boundary_modal_title:
    '¿Estás seguro de que deseas eliminar este límite para el envío?',
  online_ordering_boundaries_delete_boundary_modal_content: 'Esta acción no se puede deshacer.',
  online_ordering_boundaries_unsaved_boundary_modal_title: '¿Quieres guardar este límite?',
  online_ordering_boundaries_unsaved_boundary_modal_content:
    'Este límite aún no se ha guardado. Finaliza el límite haciendo clic en el primer punto que se creó.',
  online_ordering_boundaries_unsaved_boundary_modal_cancel: 'No guardar',
  online_ordering_boundaries_create_boundary_modal_title: 'Crear límite para el envío',
  online_ordering_boundaries_edit_boundary_modal_title: 'Editar límite para el envío',
  online_ordering_boundaries_create_boundary_modal_label_label: 'Nombre del límite',
  online_ordering_boundaries_create_boundary_modal_label_placeholder: 'Límite para el envío 1',
  online_ordering_boundaries_create_boundary_modal_label_required:
    ' El nombre del límite es obligatorio ',
  online_ordering_boundaries_create_boundary_modal_label_validation_length:
    'El nombre del límite debe tener entre 1 y 60 caracteres',
  online_ordering_boundaries_create_boundary_modal_label_validation:
    'Este nombre de límite ya está en uso. Elige un nombre nuevo.',
  online_ordering_boundaries_create_boundary_modal_wait_time_label: 'Tiempo de espera del límite',
  online_ordering_boundaries_create_boundary_modal_wait_time_required:
    'Se requiere el tiempo de espera límite',
  online_ordering_boundaries_create_boundary_modal_wait_time_placeholder: '35 minutos',
  online_ordering_boundaries_create_boundary_modal_wait_time_validation:
    'El tiempo de espera límite debe ser inferior a 360 minutos.',
  online_ordering_boundaries_create_boundary_modal_min_subtotal_label:
    'Gasto mínimo para ofrecer envío ($)',
  online_ordering_boundaries_create_boundary_modal_min_subtotal_placeholder: '$30.00',
  online_ordering_boundaries_create_boundary_modal_min_subtotal_helper_text:
    'Exige a los comensales que gasten un monto mínimo para ofrecerles envío.',
  online_ordering_boundaries_create_boundary_modal_min_subtotal_validation:
    'La cantidad máxima de gasto es de $1,000.',
  online_ordering_boundaries_create_boundary_modal_delivery_fee_label: 'Agregar un costo de envío',
  online_ordering_boundaries_create_boundary_modal_service_fee_label:
    '[ES] Attach a Delivery Service Fee',
  online_ordering_delivery_fees: 'Costos de envío',
  online_ordering_delivery_fees_table_name: 'NOMBRE',
  online_ordering_delivery_fees_table_fee: 'COSTO',
  online_ordering_delivery_fees_table_waive: 'TARIFA DE EXENCIÓN DESPUSES DE',
  online_ordering_delivery_fees_table_empty: 'No tienes costos de envío.',
  online_ordering_delivery_fees_create_new_fee: 'Crear un nuevo costo de envío',
  online_ordering_delivery_fee_form_title_create: 'Agregar un costo de envío',
  online_ordering_delivery_fee_form_title_edit: 'Editar tarifa de envío',
  online_ordering_delivery_fee_form_message_invenue:
    'Actualmente estás creando un nuevo costo de envío. Este costo de envío se agregará a tu límite para el envío y también se guardará para ser reutilizado en el futuro.',
  online_ordering_delivery_fee_form_message_doordash:
    'DoorDash te cobra una tarifa fija de {{currency}} {{label}} por pedido. Tu cargo total se reflejará en un estado de cuenta al final de cada mes. Elige cuánto te gustaría cobrar al comensal en comisiones de entrega para compensar tu tarifa de DoorDash.',
  online_ordering_delivery_fee_form_label: 'Nombre de la tarifa de envío',
  online_ordering_delivery_fee_form_label_validation:
    'El nombre de la tarifa de envío ya está tomado. Elige un nuevo nombre',
  online_ordering_delivery_fee_form_label_required: 'Se requiere el nombre de la tarifa de envío',
  online_ordering_delivery_fee_form_label_validation_length:
    'El nombre de la tarifa de envío  tener entre 1 y 60 caracteres',
  online_ordering_delivery_fee_form_amount_required: 'Se requiere el monto de la tarifa de envío',
  online_ordering_delivery_fee_form_amount: 'Monto del costo de envío',
  online_ordering_delivery_fee_form_amount_doordash: 'Monto del costo de envío',
  online_ordering_delivery_fee_form_amount_helper_text_dollar_amount_equal:
    'Sus clientes pagaran 100% del costo de envio de DoorDash.',
  online_ordering_delivery_fee_form_amount_helper_text_dollar_amount_less:
    'Usted acumulara ${{label}} del costo de envio de DoorDash',
  online_ordering_delivery_fee_form_amount_helper_text_dollar_amount_greater:
    'Sus clientes pagaran 100% del costo de envio de DoorDash. Usted ganara ${{label}} por cada envio',
  online_ordering_delivery_fee_form_amount_helper_text_percentage:
    'Cobrará un porcentaje del subtotal como tarifa de entrega de DoorDash.',
  online_ordering_delivery_fee_form_percent: 'Cambiar a porcentaje',
  online_ordering_delivery_fee_form_dollar: 'Cambiar moneda a dólar',
  online_ordering_delivery_fee_form_exemption_threshold:
    'Bonificar este cargo si se alcanzan o superan los ($)',
  online_ordering_delivery_fee_form_exemption_threshold_helper_text:
    'Los impuestos y las propinas no están incluidos en el monto de este pedido.',
  online_ordering_delivery_fee_form_taxable: '¿Este cargo está sujeto a impuestos?',
  online_ordering_delivery_fee_form_tax_dropdown: 'Tax (Obligatorio)',
  online_ordering_delivery_fee_form_tax_placeholder: 'Elija un Impuesto',
  online_ordering_delivery_fee_form_apply_fee_to: 'Aplicar tarifa a',
  online_ordering_delivery_fee_form_post_discount_total: 'Subtotal después del descuento',
  online_ordering_delivery_fee_form_post_discount_total_extra:
    'La tarifa de envío se calculará contra el subtotal después de que se apliquen los descuentos.',
  online_ordering_delivery_fee_form_pre_discount_total: 'Subtotal antes del descuento',
  online_ordering_delivery_fee_form_pre_discount_total_extra:
    'La tarifa de envío se calcularía contra el subtotal antes de que se apliquen los descuentos.',
  online_ordering_delivery_fee_form_tax_helper:
    'No hay impuestos establecidos con este restaurante. Configura los impuestos en la sección Información del lugar.',
  online_ordering_delivery_fee_form_has_disabled_taxes:
    'Uno o más de los impuestos adjuntos ya no están disponibles y se han eliminado. Por favor revise la selección.',
  online_ordering_delivery_fee_form_link_boundary: 'Asignar a un límite para el envío existente',
  online_ordering_delivery_fee_form_percentage_amount_validation:
    'El porcentaje de la tarifa de envío debe estar entre 0 y 100',
  online_ordering_delivery_fee_form_dollar_amount_validation:
    'La tarifa de envío tiene un límite de $1000',
  online_ordering_delivery_fee_form_exemption_threshold_validation:
    'El monto del cargo renunciable tiene un límite de $1000',
  online_ordering_delivery_fee_form_tax_validation: 'Se requiere configurar impuesto.',
  online_ordering_delivery_fee_form_create_success: 'Se ha creado {{label}}',
  online_ordering_delivery_fee_form_edit_success: '{{label}} se guardó correctamente.',
  online_ordering_delivery_fee_form_err_duplicate_servicecharge_label:
    'El nombre de la tarifa de envío ya está tomado. Elige un nuevo nombre',
  online_ordering_delivery_fee_form_err_missing_delivery_boundary:
    'The delivery boundary selected is not available. Please try again or try a different one.',
  online_ordering_delivery_fee_form_err_unknown_code:
    'This delivery fee has failed to save. Please try again.',
  online_ordering_delivery_fee_form_delete_failure:
    'This delivery fee has failed to delete. Please try again.',
  online_ordering_delivery_fees_delete_fee_modal_title:
    '¿Estás seguro de que deseas eliminar este costo de envío?',
  online_ordering_delivery_fees_delete_fee_modal_content:
    'Esta acción no se puede deshacer y el costo se eliminará de cualquier límite para el envío asociado.',
  online_ordering_delivery_fees_delete_fee_modal_success: 'Costo de envío ha sido eliminada.',
  online_ordering_payments_title: 'Pagos online',
  online_ordering_payments_title_tooltip: '*Requerido para pedidos programados',
  online_ordering_online_payments_enable: 'Habilitar pagos en línea',
  online_ordering_inperson_payments_enable: 'Habilitar pagos en persona',
  online_ordering_payments_cash: 'Dinero en efectivo',
  online_ordering_payments_credit_card: 'Tarjeta de crédito',
  online_ordering_payments_debit_card: 'Tarjeta de débito',
  online_ordering_scheduling_days_in_advance_tooltip:
    'Número de días (incluido el día de hoy) que puede programar un pedido por adelantado.',
  online_ordering_pickup_wait_time: 'Tiempo de Espera para Retirar (en minutos)',
  online_ordering_pickup_wait_time_placeholder: 'Ingrese el tiempo de espera',
  online_ordering_pickup_wait_time_required_error: 'Ingrese un valor válido en minutos',
  online_ordering_pickup_wait_time_validation_error:
    'El tiempo de espera de recogida en minutos debe estar entre 0 y 360',
  online_ordering_pickup_wait_time_tooltip:
    'Una estimación de cuánto tiempo toma que esté listo un pedido para retirar.',
  online_ordering_pickup_notice: 'Aviso de recogida',
  online_ordering_pickup_notice_255_chars_error:
    'El aviso de recogida no puede tener más de 255 caracteres',
  online_ordering_pickup_notice_500_chars_error:
    'El aviso de recogida no puede tener más de 500 caracteres',
  online_ordering_pickup_notice_placeholder:
    'Ejemplo: dónde ir en la tienda, si necesitan el número de pedido, etc.',
  online_ordering_pickup_notice_tooltip:
    'Este aviso informará a los comensales de cualquier cosa que necesiten saber sobre su pedido. Esto se mostrará en la página de pago.',
  online_ordering_pickup_enable: 'Habilitar recogida',
  online_ordering_pickup_enable_tooltip:
    'Los comensales recogerán sus pedidos en línea directamente en tu restaurante.',
  online_ordering_delivery_notice: 'Aviso de entrega',
  online_ordering_delivery_notice_placeholder:
    'Ejemplo: Qué esperar de la entrega, tipo de coche, etc.',
  online_ordering_delivery_notice_255_chars_error:
    'El aviso de entrega no puede tener más de 255 caracteres',
  online_ordering_delivery_notice_500_chars_error:
    'El aviso de entrega no puede tener más de 500 caracteres',
  online_ordering_delivery_notice_tooltip:
    'Utilice este campo para incluir detalles o avisos a sus clientes con respecto a sus servicios de entrega.',
  online_ordering_calorie_statement: 'Declaración contextual de calorías',
  online_ordering_calorie_statement_500_chars_error:
    'La declaración contextual de calorías no puede tener más de 500 caracteres',
  online_ordering_calorie_statement_tooltip: 'Aparecerá en la parte inferior de los menús.',
  online_ordering_order_throttling: '[ES] Order Throttling',
  online_ordering_order_throttling_tooltip:
    '[ES] Set a maximum number of orders allowed within a 15 minute window to ensure your kitchen is able to operate smoothly during busy times. Your guests will be shown the next available time if you reach full capacity. This only applies to orders received through TouchBistro Online Ordering.',
  online_ordering_order_throttling_max_orders_per_window: '[ES] Max Orders per Window (15mins)',
  online_ordering_order_throttling_error_message: '[ES] Value must be greater than or equal to 1',
  online_ordering_settings_success:
    'La configuración de pedidos en línea se ha guardado correctamente',
  online_ordering_settings_missing: 'Configure su lugar antes de continuar',
  online_ordering_payments_success:
    'Las opciones de pago de pedidos en línea se guardaron correctamente',
  online_ordering_payments_err_unknown_code:
    'Los pagos de pedidos en línea no se han podido guardar. Inténtalo de nuevo.',
  online_ordering_payments_err_validation:
    'Los pagos de pedidos en línea no se han podido guardar. Inténtalo de nuevo.',
  online_ordering_payments_err_cannot_update_tbpayments:
    'No se pudo actualizar la opción de pago de TBPayments',
  online_ordering_settings_err_ark_settings_unauthorized:
    'El lugar no tiene la marca de función requerida',
  online_ordering_settings_err_validation:
    'No se pudo guardar la configuración de pedidos en línea',
  online_ordering_settings_err_unknown_code:
    'No se pudo guardar la configuración de pedidos en línea',
  online_ordering_activation_goto_page_activation:
    'Su página de pedidos en línea aún no está activa. Cuando esté listo para comenzar a aceptar pedidos en línea, use el interruptor en <1>la página Activación</1> de página para comenzar.',
  online_ordering_activation_website_not_live:
    'Su página de pedidos en línea aún no está activa en order.tbdine.com. Use el botón debajo a continuación para activar y comenzar a recibir pedidos.',
  online_ordering_activation_website_live_info:
    'Su página de pedidos en línea está activa en order.tbdine.com. Asegúrese de que el botón Pedidos en línea esté en el sitio web de su restaurante.',
  online_ordering_activation_add_button: 'Agregue el botón de pedidos en línea a su sitio web',
  online_ordering_activation_add_button_title:
    'Su página de pedidos en línea aún no está activa en order.tbdine.com. Use el botón de alternar a continuación para activar y comenzar a recibir pedidos <1>order.tbdine.com</1>.',
  online_ordering_activation_order_online_button: 'Comprar online',
  online_ordering_activation_copy_link: 'Copiar',
  online_ordering_activation_share: 'Acceda a la URL de su página de pedidos en línea',
  online_ordering_activation_share_title:
    'Si usa su propio creador de sitios web, copie y pegue la siguiente URL e insértela como la URL de destino al crear el botón Pedidos en línea. Nota: Para una mayor exposición, comparta esta URL directamente con sus clientes en las redes sociales y en su ficha de empresa de Google.',
  online_ordering_activation_activate: 'Active su página de pedidos en línea en order.tbdine.com',
  online_ordering_activation_go_live: 'Ir a vivir',
  online_ordering_activation_go_live_footer:
    'Cuando haya completado todos los pasos y esté listo para comenzar, active el botón de activación del sitio web y comience a recibir pedidos.',
  online_ordering_activation_go_live_content:
    'Cuando esté listo para comenzar a recibir pedidos, active la opción “Publicar en vivo“. Para permitir que los comensales ordenen directamente desde su sitio web, siga los pasos a continuación.',
  online_ordering_activation_err_unknown_code:
    'No se pudo guardar la configuración de activación de pedidos en línea. Inténtalo de nuevo.',
  online_ordering_activation_success: 'Su configuración de pedidos en línea esta guardada',
  online_ordering_activation_preview_button: 'Ir a la página',
  online_ordering_closures_header: 'Cierres',
  online_ordering_closure_dates: 'Fecha(s)',
  online_ordering_closure_start_time: 'Hora de inicio',
  online_ordering_closure_end_time: 'Hora de finalización',
  online_ordering_closure_add: 'Agregar cierres',
  online_ordering_closure_save_success: 'Cierre de pedido en línea guardado con éxito',
  online_ordering_closure_save_err_unknown_code:
    'No se pudo guardar el cierre de pedidos en línea. Inténtalo de nuevo.',
  online_ordering_closure_save_err_bad_time_range:
    'La hora de finalización del cierre debe ser mayor que la hora de inicio.',
  online_ordering_closure_save_err_zero_closure_duration:
    'La duración del cierre debe ser de al menos 1 minuto.',
  online_ordering_closure_save_err_validation: 'Hubo un problema al validar una solicitud.',
  online_ordering_closure_delete_success: 'Cierre de pedido en línea eliminado con éxito',
  online_ordering_closure_delete_err_unknown_code:
    'No se pudo eliminar el cierre de pedidos en línea. Inténtalo de nuevo.',
  online_ordering_start_time_required_error: 'Seleccione una hora de inicio',
  online_ordering_start_time_validation_error:
    'La hora de inicio debe comenzar antes de la hora de finalización',
  online_ordering_end_time_required_error: 'Seleccione una hora de finalización',
  online_ordering_end_time_validation_error:
    'La hora de finalización debe comenzar después de la hora de inicio.',
  online_ordering_dates_required_error: 'Por favor seleccione la(s) fecha(s)',
  online_ordering_closure_delete_confirmation: '¿Estás seguro de que deseas eliminar este cierre?',
  online_ordering_schedule_header: 'Horas de operación',
  online_ordering_schedule_day_of_week: 'Día de la semana',
  online_ordering_schedule_day_of_week_required_error: 'Seleccione al menos un día de la semana',
  online_ordering_schedule_order_type: 'Tipo de orden',
  online_ordering_schedule_order_type_required_error:
    'Seleccione al menos un tipo de pedido para este programa',
  online_ordering_schedule_add: 'Agregar horas',
  online_ordering_schedule_save_success: 'Se han ahorrado las horas de pedido en línea.',
  online_ordering_schedule_save_err_unknown_code:
    'No se pudo guardar el programa de pedidos en línea. Inténtalo de nuevo.',
  online_ordering_schedule_save_err_bad_time_range:
    'La hora de finalización de la programación debe ser mayor que la hora de inicio.',
  online_ordering_schedule_start_time: 'Hora de inicio',
  online_ordering_schedule_end_time: 'Hora de finalización',
  online_ordering_schedule_delete_confirmation:
    '¿Está seguro de que desea eliminar esta programación de pedidos?',
  online_ordering_schedule_delete_success: 'Se han eliminado las horas de pedido en línea.',
  online_ordering_schedule_delete_err_unknown_code:
    'La programación de pedidos en línea no se pudo eliminar. Inténtalo de nuevo.',
  online_ordering_missing_timezone:
    'Para configurar sus horarios de pedido en línea, primero usted debe seleccionar una zona horaria en el parámetro "Tiempo de servicio".',
  online_ordering_weekday_mon: 'Lunes',
  online_ordering_weekday_tue: 'Martes',
  online_ordering_weekday_wed: 'Miércoles',
  online_ordering_weekday_thu: 'Jueves',
  online_ordering_weekday_fri: 'Viernes',
  online_ordering_weekday_sat: 'Sábado',
  online_ordering_weekday_sun: 'Domingo',
  online_ordering_weekdays_mon: 'Lunes',
  online_ordering_weekdays_tue: 'Martes',
  online_ordering_weekdays_wed: 'Miércoles',
  online_ordering_weekdays_thu: 'Jueves',
  online_ordering_weekdays_fri: 'Fridays',
  online_ordering_weekdays_sat: 'Sábados',
  online_ordering_weekdays_sun: 'Domingos',
  open_price: 'Precio de apertura',
  open_price_item: '[ES] Open Price Item',
  opening_hours: '[ES]Opening hours',
  operation: 'Acción',
  order_cancellation_details: 'Detalles de Cancelación de Pedido',
  order_fulfillment_status: 'Estado de Cumplimiento del Pedido',
  order_number: 'Número de Orden',
  online_ordering_discovery_start_button: 'Empezar ahora',
  online_ordering_discovery_learn_button: 'Aprende más',
  online_ordering_modal_loading_title: 'Cargando Pedidos en Línea TouchBistro',
  online_ordering_modal_loading_message: 'Esta acción puede tardar unos minutos.',
  online_ordering_provisioning_failed:
    'Los pedidos en línea no se pueden habilitar en este momento. Inténtalo de nuevo.',
  online_ordering_provisioning_failed_timezone:
    'Por favor configure la zona horaria para poder completar la configuración de pedidos en línea.',
  online_ordering_provisioning_failed_currency:
    'Por favor configure la moneda para completar la configuración de pedidos en línea',
  online_ordering_provisioned: 'Los pedidos en línea ahora están habilitados. Configure su lugar.',
  online_ordering_deprovisioned: 'Pedido en línea deshabilitado',
  online_ordering_venue_not_found:
    'No se pudieron cargar los detalles del lugar. Vuelve a intentarlo',
  online_ordering_boundary_map_primary_boundary_button: 'Límite primario',
  online_ordering_boundary_map_add_boundary_button: 'Agregar límite',
  online_ordering_boundary_map_save_success: '{{label}} se guardó correctamente.',
  online_ordering_boundary_map_delete_success:
    '{{label}} se ha eliminado de sus límites de entrega',
  online_ordering_boundary_map_boundary_deleted_primary:
    'El límite principal se ha eliminado de sus límites de entrega',
  online_ordering_boundary_map_not_enough_points_primary:
    'Este límite debe contener al menos 4 puntos antes de permitirle guardar.',
  online_ordering_boundary_map_not_enough_points:
    '[TEMP] Boundary has to contain minimum of 3 points',
  online_ordering_boundary_map_new_boundary_created: 'Límite creado con éxito',
  online_ordering_boundary_map_boundary_already_exists_primary: 'El límite primario ya existe',
  online_ordering_boundary_map_failed_save: 'Los límites no han podido salvar. Inténtalo de nuevo.',
  online_ordering_boundary_map_failed_delete:
    'Los límites no se pudieron eliminar. Inténtalo de nuevo.',
  online_ordering_delivery_settings_enable: 'Habilitar Entrega',
  online_ordering_delivery_settings_enable_helper_text:
    'Los comensales recibirán sus pedidos en línea a través de un servicio de envíos.',
  online_ordering_delivery_settings_fleet: 'Está utilizando su propia flota de delivery.',
  online_ordering_delivery_settings_fleet_helper_text:
    'Puede configurar sus límites de delivery y tarifas de delivery, pero deberá obtener su propia flota de delivery.',
  online_ordering_delivery_settings_doordash_start_title: 'Manage deliveries from DoorDash Drive',
  online_ordering_delivery_settings_doordash_start_information_us:
    'Regístrate para recibir nuestra integración de entrega gratuita y DoorDash te proporcionará un servicio de mensajería para tus órdenes de entrega en línea. Pagarás una tarifa fija de USD 7.00 por pedido y la entrega es hasta 5 millas de su restaurante.',
  online_ordering_delivery_settings_doordash_start_information_ca:
    'Regístrate para recibir nuestra integración de entrega gratuita y DoorDash te proporcionará un servicio de mensajería para tus órdenes de entrega en línea. Pagarás una tarifa fija de CAD 8.50 por pedido y la entrega es hasta 8 kilómetros de su restaurante.',
  online_ordering_delivery_settings_doordash_pending_title:
    'Tu envío de DoorDash Drive ha sido aprobado y ahora puedes configurar la integración dentro de TouchBistro Cloud. No dudes en comunicarte con tu punto de contacto de DoorDash si tienes alguna pregunta.',
  online_ordering_delivery_settings_doordash_pending_information:
    'Cuando estés listo para recibir órdenes de entrega de DoorDash Drive, configura tu integración con DoorDash Drive en la nube:',
  online_ordering_delivery_settings_doordash_enabled_title:
    '¡Tu solicitud de DoorDash Drive ha sido aprobada!',
  online_ordering_delivery_settings_doordash_enabled_information_us:
    'Haz clic en “Cambiar a DoorDash Drive” para administrar las entregas a través de la integración con DoorDash. DoorDash Drive se encargará de todas tus entregas por una tarifa fija de USD 7.00 por pedido hasta 5 millas.',
  online_ordering_delivery_settings_doordash_enabled_information_ca:
    'Haz clic en “Cambiar a DoorDash Drive” para administrar las entregas a través de la integración con DoorDash. DoorDash Drive se encargará de todas tus entregas por una tarifa fija de CAD 8.50 por pedido hasta 8 kilómetros .',
  online_ordering_delivery_settings_doordash_enabled_view_guide: 'Ver Guía de configuración',
  online_ordering_delivery_settings_fleet_invenue: 'Está utilizando su propia flota de delivery.',
  online_ordering_delivery_settings_fleet_doordash:
    'Uso de la integración con DoorDash Drive para la entrega.',
  online_ordering_delivery_settings_fleet_helper_text_invenue:
    'Puede configurar sus límites de delivery y tarifas de delivery, pero deberá obtener su propia flota de delivery.',
  online_ordering_delivery_settings_fleet_helper_text_doordash_us:
    'Con nuestra integración con DoorDash Drive,  todas las entregas de hasta 5 millas se gestionan por una tarifa fija de USD 7.00 por pedido. Los detalles de entrega y las comisiones se pueden configurar a continuación.',
  online_ordering_delivery_settings_fleet_helper_text_doordash_ca:
    'Con nuestra integración con DoorDash Drive,  todas las entregas de hasta 8 kilómetros se gestionan por una tarifa fija de CAD 8.50 por pedido. Los detalles de entrega y las comisiones se pueden configurar a continuación.',
  online_ordering_delivery_settings_fleet_helper_text_doordash_drive_delivery_learn_more:
    'Más información',
  online_ordering_doordash_drive_delivery_no_alcohol: 'Entrega de alcohol',
  online_ordering_doordash_drive_delivery_no_alcohol_title:
    'La entrega de alcohol no está permitida como parte de la integración con DoorDash Drive.',
  online_ordering_doordash_drive_delivery_no_alcohol_body:
    'Para cumplir con estas condiciones, elimina cualquier producto con alcohol de tu <1>menú de pedidos en línea</1>.',
  online_ordering_doordash_drive_delivery_alcohol_terms:
    'Consulta los <1>términos de servicio de entrega de alcohol de DoorDash</1>.',
  online_ordering_doordash_drive_delivery_support: 'Apoyo',
  online_ordering_doordash_drive_delivery_support_help_title:
    '¿Necesitas ayuda para configurar la integración con DoorDash Drive??',
  online_ordering_doordash_drive_delivery_support_help_body:
    'Ve nuestras guías de <1>ayuda de DoorDash Drive</1> o comunícate con el <2>soporte de TouchBistro</2>.',
  online_ordering_doordash_drive_delivery_support_orders_title:
    '¿Necesitas ayuda con los pedidos de DoorDash Drive?',
  online_ordering_doordash_drive_delivery_support_orders_body_live:
    'Para problemas de pedidos en vivo, visita <1>doordash.com/drive/portal/help</1>.',
  online_ordering_doordash_drive_delivery_support_orders_body_non_live:
    'Para pedidos que no sean en vivo, envía un correo electrónico a DoorDash a <1>drive-support@doordash.com</1>.',
  online_ordering_delivery_settings_notice: 'Aviso de Entrega',
  online_ordering_delivery_settings_notice_helper_text:
    'Este aviso informará a los comensales de cualquier cosa que necesiten saber sobre su pedido. Esto se mostrará en la página de pago.',
  online_ordering_delivery_settings_err_unknown_code:
    'No se pudo guardar la configuración de entrega de pedidos en línea',
  online_ordering_delivery_settings_err_no_boundaries_setup:
    'Cree un límite de envío para permitir la entrega en su lugar',
  online_ordering_delivery_settings_success:
    'La configuración de entrega de pedidos en línea se guardó correctamente',
  online_ordering_delivery_setting_doordash_start: 'Iniciar registro',
  online_ordering_modal_doordash_start_text:
    'Para comenzar a usar DoorDash Drive para administrar tus entregas, firma el siguiente contrato.',
  online_ordering_modal_doordash_step_1:
    'Serás dirigido al sitio web de DoorDash para completar y firmar un contrato de 4 pasos.',
  online_ordering_modal_doordash_step_2:
    'Una vez firmado, tu solicitud puede tardar hasta 24 horas en ser aprobada.',
  online_ordering_modal_doordash_step_3:
    'Una vez aprobada, recibirás un correo electrónico de DoorDash y podrás completar la configuración de DoorDash Drive dentro de TouchBistro Cloud.',
  online_ordering_modal_doordash_separation_note:
    'Ten en cuenta que este contrato es específico para la integración de TouchBistro con DoorDash Drive y es independiente de DoorDash Marketplace.',
  online_ordering_modal_doordash_sign_note:
    'Debes firmar el contrato de DoorDash Drive incluso si tienes un contrato existente con DoorDash Marketplace.',
  online_ordering_modal_doordash_terms_agreement:
    'Acepto esta integración de TouchBistro con DoorDash Drive.',
  online_ordering_modal_doordash_alcohol_agreement_title:
    'El alcohol no está permitido con DoorDash Drive',
  online_ordering_modal_doordash_alcohol_agreement:
    'Reconozco que el alcohol no está permitido como parte de la integración de TouchBistro con DoorDash Drive. <1>Consulta los términos de servicio de entrega de alcohol de DoorDash.</1>',
  online_ordering_modal_doordash_alcohol_agreement_error:
    'Acepta los términos de servicio de entrega de alcohol de DoorDash Drive.',
  online_ordering_modal_doordash_continue: 'Continuar a DoorDash Drive',
  online_ordering_modal_doordash_terms_agreement_error:
    'Por favor acepta los términos y condiciones.',
  online_ordering_modal_doordash_payments_required_title:
    'Se requiere TouchBistro Online Payments para la integración con DoorDash Drive.',
  online_ordering_modal_doordash_payments_required_subtitle:
    'Los pagos de pedidos de entrega de DoorDash Drive se procesan con TouchBistro Online Payments. Para agregar Pagos en línea TouchBistro a su suite, envíe un correo electrónico a <1>{{email}}</1> para obtener asistencia.',
  online_ordering_delivery_settings_doordash_payments:
    ' Se requiere <1>TouchBistro Online Payments</1> para la integración con DoorDash Drive.',
  online_ordering_pickup_settings_success:
    'La configuración de recolección de pedidos en línea se guardó correctamente',
  online_ordering_pickup_settings_err_unknown_code:
    'No se pudo guardar la configuración de recolección de pedidos en línea',
  online_ordering_pickup_settings_err_validation:
    'No se pudo guardar la configuración de recolección de pedidos en línea',
  online_ordering_guest_checkout: '[ES] Checkout as Guest',
  online_ordering_guest_checkout_tooltip:
    '[ES] Allow your guests to place online orders without requiring them to create a TouchBistro Dine account.',
  other: 'Otro',
  staff_settings: '[ES] Staff Settings',
  break_types: '[ES] Break Types',
  overtime_rules: 'Reglas de tiempo extra',
  partner_api_key: 'Clave {{partner}}',
  partner_api_password: 'Contraseña {{partner}}',
  partner_api_token: '{{partner}} Access Token',
  partner_avero_id: '{{partner}} ID de Negocio',
  partner_business_id: '{{partner}} Business ID',
  partner_back_to_marketplace_btn: '[ES] Back to App Marketplace',
  partner_buyer_guid: 'GUID de usuario {{partner}}',
  partner_configuration: '{{partner}}',
  partner_disable_text:
    '{{partner}} esta desabilitado. Por favor selecione habilitar en el menu de opciones en la parte superior de la esquina izquierda de la ventana para configurar {{partner}}.',
  partner_does_not_exist: 'Esta configuración no existe.',
  partner_enabled: '{{partner}} Habilitado',
  partner_get_a_quote: 'Obtener Cotización',
  partner_send_a_request: '[ES] Send a Request',
  partner_setup: 'Configurar',
  partner_venue_id: '{{partner}} ID de Negocio',
  partners: 'Socios',
  partner_group_kds: 'Sistema de Exhibición de Cocina',
  partner_group_accounting: 'Contabilidad',
  partner_group_business_insights_analytics: 'Información y Análisis del Negocio',
  partner_group_financial_services: 'Servicios financieros',
  partner_group_inventory_management: 'Administración de Inventario',
  partner_group_loyalty_marketing: 'Loyalty y Marketing',
  partner_group_my_apps: 'Mis Aplicaciones',
  partner_group_online_ordering: 'Pedidos en Línea',
  partner_group_staff_management: 'Administración de Personal',
  partner_group_self_access: 'Auto acceso',
  partner_name_7shifts: '7shifts',
  partner_name_api_access: 'Api Access',
  partner_name_avero: 'Avero',
  partner_name_barvision: 'BarVision',
  partner_name_bevchek: 'Bevchek',
  partner_name_chowly: 'Chowly',
  partner_name_coverdash: 'TouchBistro x Coverdash',
  partner_name_craftable: 'Craftable',
  partner_name_deliverect: 'Deliverect',
  partner_name_factura_electronica: 'Factura Electrónica',
  partner_name_fourth: 'Fourth',
  partner_name_freepour: 'Freepour',
  partner_name_marginedge: 'MarginEdge',
  partner_name_marginedge__internal: 'TouchBistro Profit Management Powered by MarginEdge',
  partner_name_marketman: 'MarketMan',
  partner_name_optimum_control: 'Optimum Control',
  partner_name_ordermark: 'UrbanPiper',
  partner_name_pour_controls: 'Pour Controls',
  partner_name_push_operations: 'Push Operations',
  partner_name_saltwater: 'COGS-Well',
  partner_name_shogo: 'Shogo',
  partner_name_tb_digital_gift_cards: 'Tarjetas de Regalo Digitales',
  partner_name_tb_eats: 'TBEats',
  partner_name_tb_loyalty: 'Loyalty',
  partner_name_tb_marketing: 'Marketing',
  partner_name_tb_online_ordering: 'Pedidos en LíneaTouchBistro',
  partner_name_tb_physical_gift_cards: 'Tarjetas de Regalo Físicas de eCard',
  partner_name_tb_reservations: 'Reservaciones',
  partner_name_tb_labor: '[ES] TouchBistro Labor Management',
  partner_name_tb_inventory: '[ES] TouchBistro Inventory Management',
  partner_name_restaurant365: 'Restaurant365',
  partner_name_wisk: 'Wisk',
  partner_name_fresh_kds: 'TouchBistro Kitchen Display System Powered by Fresh',
  partner_name_doordash_marketplace: '[ES] DoorDash Integration',
  partner_name_anyday: 'AnyDay Payments+',
  partner_name_davo: '[ES] DAVO by Avalara',
  partner_description_long_fresh_kds:
    'El sistema de visualización de cocina Touchbistro elaborado por Fresh mejora la eficiencia de la cocina al enviar pedidos a su cocina de manera instantánea y precisa. Tan pronto como se envía la orden desde el POS, aparece en el sistema de visualización de cocina, lo que brinda una línea de comunicación fluida entre los equipos de atención al público y la cocina.',
  partner_description_long_7shifts:
    '7shifts es la herramienta de programación, fácil de usar, creada para restaurantes y diseñada para ayudar a los dueños y operadores a ahorrar tiempo en la programación, reducir los costos laborales y comunicarse fácilmente con el personal a través de mensajes de texto, chat o correo electrónico.\n\nUna integración gratuita que utiliza tu información real para crear turnos eficientes de forma rápida, así como proyecciones de trabajo y ventas, fácilmente. Integrar 7shifts con TouchBistro también facilita las alertas de tiempo extra y uso de breaks.',
  partner_description_long_api_access:
    'Con una integración directa en la API de la Nube de TouchBistro, los datos de tus transacciones del POS fluirán directamente a tus sistemas de reportes y administración de datos. Simplifica tus reportes, ahorra tiempo y reduce los errores manuales.',
  partner_description_long_avero:
    'Avero es un software de gestión de restaurantes que se adapta a tu forma de trabajar. Ya sea que esté en las mesas, en la cocina o fuera del restaurante, su software de análisis de restaurantes líder en la industria, te brinda información valiosa para que puedas tomar mejores decisiones para tu negocio.',
  partner_description_long_barvision:
    'La tecnología innovadora de BarVision, transforma la forma en que los bares hacen negocios. Mediante el uso de picos de vertido gratuitos inalámbricos, capturamos los vertidos en tiempo real y los comparamos con los cargos del punto de venta para identificar problemas como el vertido abundante y el robo. Luego, trabajamos mano a mano contigo para motivar a tu personal a servir bebidas precisas y consistentes.',
  partner_description_long_bevchek:
    'Bevchek crea tecnología para brindar claridad y comprensión en programas de bebidas de barril. Los sistemas de Bevchek utilizan el flujo de fluidos, la temperatura, el inventario y los menús junto con las ventas, para maximizar los beneficios medidos en centésimas de onza, en tiempo real.',
  partner_description_long_cfd:
    'El Display de Vista al Cliente de TouchBistro permite a los clientes ver sus pedidos en tiempo real, incluyendo elementos del menú, modificadores y precios. Estas pantallas ayudan a evitar errores costosos, mantienen las filas en movimiento y garantizan la satisfacción de los clientes antes de enviar los pedidos a la cocina o al barista. Además, puedes personalizar la pantalla con tu marca y usarla para resaltar ofertas especiales, nuevos elementos de menú, programas de lealtad, identificadores de redes sociales y más.',
  partner_description_long_coverdash:
    'Coverdash se integra con TouchBistro para simplificar el proceso de adquirir y gestionar seguros comerciales para restaurantes y todo tipo de pequeñas empresas. La empresa ofrece una amplia gama de productos de seguros comerciales, incluyendo responsabilidad civil, propiedad, compensación laboral y ciberseguridad. La experiencia digital de seguros de Coverdash permite a los dueños de negocios obtener cotizaciones, contratar, pagar y gestionar activamente pólizas de seguros en cuestión de segundos. A través de relaciones con muchas de las aseguradoras más reconocidas del mundo, Coverdash proporciona las coberturas que las empresas en crecimiento necesitan a las tarifas más asequibles, con descuentos para todos los clientes de TouchBistro.',
  partner_description_long_craftable:
    'Craftable es una solución de back-office totalmente móvil, diseñada para maximizar las ganancias. Completa con inventario, facturas, costeo de recetas, contabilidad y análisis de ventas, la plataforma está diseñada por expertos de la industria de hospitalidad. Simple para el restaurante, potente para la oficina.',
  partner_description_long_deliverect:
    'Deliverect se ha asociado con TouchBistro para crear una integración bidireccional confiable, lo que le permite gestionar sus pedidos en línea desde un único punto de venta con facilidad. Conecte sus <4>canales de venta en línea, como Uber Eats, Doordash y Grubhub,</4> para controlar sus menús y centralizar los pedidos. Con los pedidos en línea que se envían automáticamente al punto de venta de TouchBistro, ya no es necesario volver a ingresar manualmente los recibos de los pedidos, lo que ahorra tiempo y reduce errores.<3></3><3></3><1>Beneficios clave</1><2>Gestión de pedidos:</2> Unificamos todos tus pedidos online y los sincronizamos en tu TPV existente, simplificando el flujo operativo de tu restaurante durante el proceso.<3></3><2>Menú & gestión de stock:</2> Te proporcionamos las herramientas necesarias para hacer un mejor seguimiento de tu stock, así como la capacidad de actualizar todos tus menús online ¡en un solo clic!<3></3><2>Insights:</2> Entiende tu negocio como nunca lo habías hecho gracias a las funciones de analítica e informes detallados de Deliverect. Obtén información y actúa en consecuencia.<3></3><3></3><2>Prueba gratuita de 14 días</2>',
  partner_description_long_digital_menu_board:
    'El Tablero de Menú Digital de TouchBistro proporciona una alternativa moderna a los menús estáticos, por un precio mucho menor que contratando a una agencia de diseño. Actualiza, configura y controla fácilmente tu menú digital, cambiando artículos, descripciones, precios y ofertas especiales, en cuestión de minutos.\n\nCon nuestro Tablero de Menú Digital, puedes exhibir tus alimentos, bebidas, complementos y promociones, con imágenes llamativas que reducen los tiempos de espera y alientan a los clientes a tomar una decisión más rápida. Los clientes llegan a la caja registradora sabiendo exactamente lo que quieren pedir, lo que ayuda a agilizar tus operaciones.',
  partner_description_long_factura_electronica:
    'Ahorra tiempo y costos de mano de obra al eliminar las entradas manuales; facilita la conciliación al generar reportes de facturas mensuales que se enviarán al SAT y se compartirán con tu contador para los reportes de impuestos.',
  partner_description_long_fourth:
    'Fourth ofrece un conjunto de soluciones de back office para satisfacer las necesidades de inventario, compras, gestión de menús e informes del mercado hospitalario.',
  partner_description_long_freepour:
    'Freepour ofrece a los restaurantes soluciones integradas de gestión de inventario que ofrecen eficiencias que te brindan beneficios en todas las disciplinas de la operación de un restaurante. Ayudan a los clientes a reducir los costos de vertido, los costos laborales asociados con el inventario y a recopilar datos más precisos para la planificación de adquisiciones y los reportes financieros.',
  partner_description_long_kds:
    'El Sistema Display de Cocina de TouchBistro (KDS) agiliza tus operaciones al hacer que sea más rápido y más fácil para tu personal front-of-house y back-of-house comunicarse. Un KDS también mejorará tus operaciones de BOH al proporcionar comentarios de rendimiento en tiempo real y la capacidad de optimizar los pedidos, todo para ayudarte a brindar una experiencia excepcional a tus clientes.',
  partner_description_long_kiosk:
    'El Kiosko de Auto-Servicio de TouchBistro ayuda a los restaurantes de servicio rápido a mover las filas más rápido, mejorando la experiencia general del comensal y procesando más pedidos en menos tiempo. No sólo eso, cuando permites que los clientes personalicen su pedido a su propio ritmo, les das oportunidades para aumentar el ticket promedio con notificaciones automáticas de modificadores. Además, ahorras en costos de trabajo al reasignar personal de FOH a tareas de mayor valor, como preparar más nuevos pedidos o en servicio al cliente.',
  partner_description_long_marginedge:
    'MarginEdge automatiza el flujo de tus datos de ventas de TouchBistro directamente a tu contabilidad. Ahorra tiempo y dinero con tus entradas de ventas transfiriéndose sin problemas todos los días, y contigo en control total de tus cuentas y formato de entrada de ventas. Actualiza y obtén la automatización de tus ventas, además del procesamiento automatizado de facturas, administración de inventario, costeo de alimentos/platos, recetas digitales, alertas de precios y más.',
  partner_description_long_marginedge__internal:
    'TouchBistro Profit Management Powered by MarginEdge se integra perfectamente con su POS TouchBistro para ayudarlo a simplificar toda su operación de oficina trasera. Administre fácilmente su inventario, rastree costos, automatice procesos contables clave y maximice la rentabilidad, todo en un solo lugar.',
  partner_description_long_marketman:
    'MarketMan es una solución de compras y administración de inventario basada en la nube que se enfoca en optimizar las compras, entrega, contabilidad y rentabilidad para restaurantes de todo tipo.',
  partner_description_long_optimum_control:
    'Optimum Control ofrece una solución completa de gestión de inventario de alimentos y bebidas que es fácil de usar, sin importar el concepto o tamaño del negocio. Las instrucciones detalladas de preparación de recetas, simplifican la receta exacta y el costo de los alimentos. Facilita los pedidos y compras con todos tus proveedores a la vez, con facturación electrónica precisa.',
  partner_description_long_ordermark:
    '[ES] UrbanPiper is a tech platform for restaurants to integrate their delivery channels with their POS. Our solution helps reduce order preparation time, eliminate errors, and improve efficiency. Trusted by 35k+ restaurants worldwide for seamless delivery management.',
  partner_description_long_pour_controls:
    'Tome el control de tu inventario con Pour Controls System Manager, nuestra plataforma de software basada en la web que te permite rastrear todos los vertidos en tiempo real, desde cualquier parte del mundo, en tu computadora de escritorio o dispositivo móvil. Mejora la experiencia de tus clientes con bebidas exclusivas que se sirven bien en todo momento y ahorra en costos de administración.',
  partner_description_long_push_operations:
    'Push Operations es una plataforma de administración de empleados, basada en la nube, que integra tus tareas de recursos humanos, nómina, seguimiento de turnos y programación, en una plataforma fácil de usar. Con miles de clientes en América del Norte, Push Operations ofrece una solución personalizable que se adapta a cada modelo de negocio y permite a los propietarios gestionar su negocio con decisiones mejor informadas.',
  partner_description_long_reports_to_go:
    'Los datos de tu restaurante pueden contener información valiosa que se puede utilizar para mejorar drásticamente tu negocio... pero tienes un restaurante que dirigir, comida que preparar, clientes que atender y lo último que quieres hacer es dedicar tu tiempo a estudiar detenidamente docenas de hojas de cálculo.\n\nPermítenos hacer el cálculo numérico por ti. Las herramientas de reportes y análisis de TouchBistro, te brindan toda la información crítica que necesitas, ya sea con tu iPad en el restaurante, en la computadora de la oficina o en la carretera desde tu celular. ',
  partner_description_long_shogo:
    'TouchBistro se integra con Shogo para facilitar tu gestión contable y financiera. Shogo conecta TouchBistro al software de contabilidad QuickBooks, Sage y Xero, para que puedas monitorear el desempeño de tu negocio, eliminar la contabilidad manual, ahorrar tiempo y reducir los errores de ingreso de datos.',
  partner_description_long_tb_digital_gift_cards:
    '¿Estás buscando una forma más sencilla de vender tarjetas de regalo para tu restaurante? Presentamos las Tarjetas de Regalo Digitales TouchBistro, una solución que permite a tus clientes comprar tarjetas de regalo directamente desde tu punto de venta o mediante tu propia aplicación web para clientes. Con las tarjetas de regalo digitales, los clientes pueden pagar con el escaneo rápido de un código QR mientras tú generas un flujo de efectivo inmediato, das seguimiento a tus gastos y les das más razones para regresar.',
  partner_description_long_tb_eats:
    'Únicamente para uso interno: herramienta de prueba de Pedidos en Línea TouchBistro para los equipos de Producto y Desarrollo.',
  partner_description_long_tb_loyalty:
    'Loyalty es una plataforma de gestión de relaciones con los clientes (CRM) y recompensas que permite a los restaurantes maximizar el valor de por vida del cliente al crear una experiencia perfecta para sus clientes con programas de lealtad personalizados, múltiples opciones de recompensas y una aplicación web de marca para su cliente.',
  partner_description_long_tb_marketing:
    'Marketing es una plataforma de marketing fácil de usar que conecta a los restaurantes con sus clientes y generando visitas recurrentes. Diseñada para integrarse a la perfección con TouchBistro POS, la solución le permite automatizar campañas y promociones de marketing personalizadas para que pueda atraer a los clientes existentes con el mensaje adecuado en el momento adecuado.',
  partner_description_long_tb_online_ordering:
    'Online Ordering es el sistema que te permite recibir pedidos directamente en tu sitio web y los integra directamente con tu POS de TouchBistro. Haz que los pedidos sean más convenientes para ti y para tus clientes, mientras mantienes más ganancias en tu bolsillo.',
  partner_description_long_tb_physical_gift_cards:
    '¿Buscas una forma sencilla de crear tarjetas de regalo con la marca de tu restaurante? Presentamos Tarjetas de Regalo TouchBistro, una solución que te permite crear y vender tarjetas físicas para tu negocio o grupo de sucursales, con plantillas fáciles de usar o diseños personalizados. No solo podrás distribuir tarjetas que ayuden a posicionar tu restaurante, sino que también generarás un flujo de efectivo inmediato, podrás dar seguimiento al consumo de tus clientes y les darás una razón más para regresar.',
  partner_description_long_tb_reservations:
    'Reservaciones es la plataforma completa de reserva y gestión de clientes para ayudar a los restaurantes de servicio completo a adaptar la experiencia de cada cliente, mientras administran sus operaciones con facilidad.',
  partner_description_long_tb_labor:
    '[ES] TouchBistro Labor Management integrates seamlessly with TouchBistro POS to simplify the staff scheduling process by leveraging real-time POS data. Taking the guesswork out of how you schedule and manage your team with a solution that helps you streamline labor forecasting, employee scheduling, task management, and more.',
  partner_description_long_tb_inventory:
    '[ES] TouchBistro Inventory Management integrates seamlessly with TouchBistro POS to give you the tools needed to track inventory costs and monitor recipe profitability. Improve food and beverage planning, and take control of the bottom line with a solution that helps you reduce waste, theft, spoilage, and overproduction.',
  partner_description_long_restaurant365:
    'Restaurant365 es la plataforma integral de gestión empresarial de restaurantes en la nube, líder de la industria, que integra y gestiona a las personas, los productos, las tecnologías, los proveedores y los espacios que contribuyen al producto final de un restaurante: experiencias inolvidables de los comensales que generan ganancias y crecimiento.',
  partner_description_long_wisk:
    'WISK.ai ofrece análisis avanzados de restaurantes/bares para ayudarte a predecir y planificar las ventas futuras. La plataforma utiliza inteligencia artificial para analizar datos de ventas anteriores. Con estos datos, podrás determinar cómo se verán tus ventas según el clima, los días festivos, los eventos y más. A partir de ahí, puedes solicitar la cantidad exacta de inventario necesaria para satisfacer la demanda y crear promociones en torno a alimentos/bebidas populares.',
  partner_description_long_doordash_marketplace:
    '[ES] Seamlessly integrate with DoorDash to receive online orders directly through your TouchBistro POS. Please note that you must have a DoorDash merchant account set up before proceeding with the POS integration. \n\n',
  partner_description_long_anyday:
    '[ES] AnyDay automates gratuity distribution and calculation with instant and daily digital payouts and distribution at scale. Compliant, secure and efficient, AnyDay saves operators thousands of dollars monthly and improves staff retention. Tip pool calculations are seamless with TouchBistro and Time & Attendance integrations, pulling sales and tips data from the POS and Time & Attendance shift data into the AnyDay platform to facilitate custom tip pool rules, calculations and disbursements. Built to scale, from single steakhouses to restaurant chains.',
  partner_description_long_davo:
    '[ES] DAVO by Avalara automatically sets aside the exact amount of sales tax you collect daily using sales data from your POS system, and then files and pays it to the State, on time and in full. With DAVO, your sales tax management is fully automated so you never need to worry about sales tax again.',
  partner_description_short_7shifts:
    'Programa y comunícate fácilmente con tu personal del restaurante.',
  partner_description_short_api_access:
    'El acceso directo a la API entrega los datos de tus transacciones directamente a tus sistemas de reportes y datos patentados.',
  partner_description_short_avero:
    'Te proporciona información valiosa para que tomes mejores decisiones.',
  partner_description_short_barvision:
    'La tecnología innovadora de BarVision transforma la forma en que las barras hacen negocios al administrar de cerca el inventario de barras.',
  partner_description_short_bevchek:
    'Sistema de inventario de bebidas en tiempo real para productos de barril que incluyen cerveza, vino, cócteles, refrescos y café.',
  partner_description_short_cfd:
    'Deje que tus clientes vean y confirmen los pedidos en tiempo real.',
  partner_description_short_coverdash:
    'Simplifica el proceso de adquirir y gestionar seguros comerciales para restaurantes y todo tipo de pequeñas empresas.',
  partner_description_short_craftable:
    'Solución completa de gestión de inventario de bares y restaurantes.',
  partner_description_short_deliverect: 'Conecta tu POS a plataformas de delivery con Deliverect.',
  partner_description_short_fresh_kds:
    '¡Recibe las comandas en tu cocina de manera inmediata y correcta!',
  partner_description_short_digital_menu_board:
    'Proporciona una alternativa moderna a los menús estáticos, a una fracción del costo.',
  partner_description_short_factura_electronica:
    'Los restaurantes en México pueden enviar facturas a los clientes que lo soliciten, así como generar facturas globales para los informes fiscales mensuales.',
  partner_description_short_fourth:
    'Soluciones de gestión de menús, inventarios, recetas y compras, totalmente integradas y compatibles con dispositivos móviles.',
  partner_description_short_freepour:
    'Análisis de datos precisos para sistemas de cerveza de barril que ayudan a reducir los costos.',
  partner_description_short_kds: 'Optimiza y mejora tus operaciones back-of-house.',
  partner_description_short_kiosk:
    'Ayuda a los QSR (Restaurantes de Servicio Rápido) a atender las filas más rápido, mejorar la rentabilidad y ahorrar en costos laborales.',
  partner_description_short_marginedge:
    'Automatiza los datos de ventas diarias con tu contabilidad. Actualiza para ingresar a un conjunto de herramientas de gestión de inventario.',
  partner_description_short_marginedge__internal:
    'Maximice los beneficios con una solución completa de oficina trasera.',
  partner_description_short_marketman:
    'Herramientas de gestión de inventario avanzadas para garantizar el costeo exacto de los alimentos y una gestión de pedidos optimizada con los proveedores.',
  partner_description_short_optimum_control:
    'Herramientas robustas de administración de inventario, para garantizar el costeo exacto de los alimentos y una gestión de pedidos optimizada con los proveedores.',
  partner_description_short_ordermark:
    '[ES] Manage all your food delivery channels in your TouchBistro POS.',
  partner_description_short_pour_controls:
    'Maximizar Ganancias. Minimizar la Contracción. Brindar consistencia en la bebida para una excelente experiencia del cliente.',
  partner_description_short_push_operations:
    'Solución completa de administración de personal y nómina, hecha a la medida de la industria restaurantera.',
  partner_description_short_reports_to_go:
    'Ingresa a tu información desde donde estés, a cualquier hora, a través de la aplicación Reports-To-Go en tus dispositivos iOS o Android. ',
  partner_description_short_saltwater: '',
  partner_description_short_shogo: 'Conecta tu POS a tu sistema contable.',
  partner_description_short_tb_digital_gift_cards:
    'Crea y vende tarjetas de regalo digitales directamente desde el punto de venta de tu restaurante o mediante tu propia aplicación web para clientes.',
  partner_description_short_tb_eats:
    'Uso Interno: herramienta de prueba de TouchBistro Online Ordering.',
  partner_description_short_tb_loyalty:
    'Una plataforma de gestión de relaciones y recompensas con los clientes que ayuda a los restaurantes a maximizar el valor para el cliente.',
  partner_description_short_tb_marketing:
    'Plataforma de marketing integrada a POS conecta a los restaurantes con sus clientes y genera visitas repetidas.',
  partner_description_short_tb_online_ordering:
    '¡Permita que los comensales ordenen para llevar y entregar directamente de usted mientras mantiene más ganancias en su bolsillo!',
  partner_description_short_tb_physical_gift_cards:
    'Crea y vende tarjetas físicas que ayuden a dar a conocer tu restaurante, generen un flujo de efectivo inmediato y brinden a los clientes una razón más para regresar.',
  partner_description_short_tb_reservations:
    'La plataforma completa de administración de reservaciones e invitados.',
  partner_description_short_tb_labor:
    '[ES] Streamline employee forecasting, staff scheduling, and more with our integrated labor management solution.',
  partner_description_short_tb_inventory:
    '[ES] Reduce waste, theft, spoilage, and over-production with our fully integrated inventory management solution.',
  partner_description_short_restaurant365:
    'R365 es el centro unificado que impulsa las experiencias de los comensales y el crecimiento.',
  partner_description_short_wisk:
    'Análisis de datos completo y avanzado para restaurantes y bares.',
  partner_description_short_doordash_marketplace:
    '[ES] Seamlessly integrate with DoorDash to receive online orders directly through your TouchBistro POS.',
  partner_description_short_anyday: '[ES] Automated & instant tip pool distribution & calculation ',
  partner_description_short_davo:
    '[ES] You take care of your business. We’ll take care of the sales tax.',
  partner_description_slogan_7shifts: 'Integración de Programación de Turnos, 7shifts',
  partner_description_slogan_api_access: 'Acceso API',
  partner_description_slogan_avero:
    'Información Clave y Análisis del Negocio para Mejorar la Información',
  partner_description_slogan_barvision: 'Integración de Gestión de Inventario de Barras, BarVision',
  partner_description_slogan_bevchek: 'Integración de Gestión de Inventario de Barras, Bevchek',
  partner_description_slogan_cfd: 'Display de Vista al Cliente',
  partner_description_slogan_coverdash: 'TouchBistro x Coverdash',
  partner_description_slogan_craftable: 'Integración de Gestión de Inventario, Craftable',
  partner_description_slogan_deliverect: 'Integración de Pedidos en Línea, Deliverect',
  partner_description_slogan_digital_menu_board: 'Tableros de Menú Digitales',
  partner_description_slogan_factura_electronica: 'Integración de Factura Electrónica, Pospac',
  partner_description_slogan_fourth: 'Integración de Gestión de Inventario, Fourth',
  partner_description_slogan_freepour: 'Integración de Gestión de iInventario de Barra, Freepour',
  partner_description_slogan_kds: 'Sistema Display de Cocina',
  partner_description_slogan_kiosk: 'Kiosko de Auto-servicio',
  partner_description_slogan_marginedge:
    'Integración de Contabilidad y Gestión de Inventario, MarginEdge',
  partner_description_slogan_marginedge__internal:
    'TouchBistro Profit Management Powered by MarginEdge',
  partner_description_slogan_marketman: 'Integración de Gestión de Inventario, MarketMan',
  partner_description_slogan_optimum_control:
    'Integración de Gestión de Inventario, Optimum Control',
  partner_description_slogan_ordermark: '[ES] UrbanPiper Online Ordering Integration',
  partner_description_slogan_pour_controls: 'Integración de Gestión de Inventario, Pour Controls',
  partner_description_slogan_push_operations:
    'Gestión de Personal e Integración de Nómina, Push Operations',
  partner_description_slogan_reports_to_go: 'Reports-to-Go',
  partner_description_slogan_shogo: 'Integración Contable, Shogo',
  partner_description_slogan_tb_digital_gift_cards: 'Tarjetas de Regalo Digitales',
  partner_description_slogan_tb_eats: 'Herramienta de Prueba de TouchBistro Online Ordering',
  partner_description_slogan_tb_loyalty: 'Loyalty',
  partner_description_slogan_tb_marketing: 'Marketing',
  partner_description_slogan_tb_online_ordering: 'Pedidos en Línea',
  partner_description_slogan_tb_physical_gift_cards: 'Tarjetas de Regalo TouchBistro',
  partner_description_slogan_tb_reservations: 'Reservaciones',
  partner_description_slogan_tb_labor: '[ES] TouchBistro Labor Management',
  partner_description_slogan_tb_inventory: '[ES] TouchBistro Inventory Management',
  partner_description_slogan_restaurant365: 'Restaurant365',
  partner_description_slogan_wisk: 'Solución de Administración de Inventario, Wisk Bar',
  partner_description_slogan_fresh_kds: 'TouchBistro Kitchen Display System Powered by Fresh',
  partner_description_slogan_doordash_marketplace: '[ES] DoorDash Integration',
  partner_description_slogan_anyday: 'AnyDay Payments+',
  partner_description_slogan_davo: '[ES] Davo by Avalara Integration Sales Tax Integration',
  partner_disable_text_short: '{{partner}} no está activado.',
  partner_tb_purchase_link: 'https://www.touchbistro.com/',
  partner_tb_support_link: 'https://www.touchbistro.com/contact-us/',
  partner_tb_support_text: 'soporte técnico de TouchBistro',
  partner_loyalty_disable_confirm_text:
    'Para habilitar este producto en el futuro, usted debe agregar este servicio a la suscripción de su restaurante.',
  partner_loyalty_disable_confirm_title:
    'Esta seguro que desea deshabilitar {{partner}} para su restaurante?',
  partner_loyalty_disable_confirm_button: 'Deshabilitar {{partner}}',
  partner_loyalty_disable_contact_text: 'Por favor contacte con',
  partner_loyalty_disable_switch_text: 'para desactivar {{partner}}.',
  partner_loyalty_purchase_text: '¿Desea obtener más información sobre {{partner}}?',
  partner_loyalty_purchase_call_text: 'Solicite una llamada.',
  partner_referral_additional_notes: '¿Qué te gustaría conocer acerca de {{partner}}?',
  partner_modify: 'Modificar',
  partner_learn_more: 'Conocer Más',
  partner_external_referral_form_subtitle:
    'Simplemente completa el formulario a continuación y un representante de ventas de TouchBistro se comunicará contigo.',
  partner_external_referral_form_title: '¡Estamos ansiosos por hablar contigo!',
  partner_external_referral_thank_you_message:
    '¡Gracias por tu interés en las soluciones integradas de los aliados de de TouchBistro!',
  partner_external_referral_thank_you_subtitle: 'Un representante de ventas estará en contacto.',
  partner_internal_referral_form_subtitle:
    'Simplemente complete el formulario a continuación y un administrador de cuentas de TouchBistro se pondrá en contacto.',
  partner_internal_referral_form_title: '¡Estamos ansiosos por hablar contigo!',
  partner_internal_referral_thank_you_message:
    '¡Gracias por tu interés en las soluciones complementarias de TouchBistro!',
  partner_internal_referral_thank_you_subtitle: 'Un representante de ventas se pondrá en contacto',
  partner_7shifts_connect: 'Conéctese a 7shifts',
  partner_7shifts_connect_failure: 'No se pudo conectar a 7shifts',
  partner_7shifts_failure_locationID: 'ID de ubicación no válido',
  partner_7shifts_locationID: '7shifts Location ID',
  partner_7shifts_locationID_placeholder: 'Ingrese el ID de ubicación de 7shifts',
  partner_7shifts_locationID_validation: 'Ingrese un {{field}} válido antes de guardar',
  partner_7shifts_success: 'Configuración de 7shifts actualizada',
  partner_7shifts_unlink_account: 'Desvincular cuenta',
  partner_7shifts_unlink_button: 'Desvincular cuenta de 7shifts',
  partner_7shifts_unlink_title:
    '¿Está seguro de que desea desvincular su cuenta de 7shifts de este lugar?',
  partner_7shifts_unlink_message:
    'Este lugar perderá el acceso a su cuenta de 7shifts y deberá iniciar sesión nuevamente para usar esta integración.',
  partners_7shifts_enforce_schedule_label: 'Hacer cumplir el horario',
  partners_7shifts_enforce_schedule:
    'Habilite esta configuración para evitar que los empleados no programados registren TouchBistro. El personal no podrá fichar a menos que haya una conexión válida a 7shifts.',
  partners_7shifts_enforce_role_label: 'Hacer cumplir el rol',
  partners_7shifts_enforce_role:
    'El personal será registrado bajo el Rol especificado en el horario de 7 turnos.',
  partners_7shifts_allow_shift_override_label: '[ES] Allow Shift Override',
  partners_7shifts_allow_shift_override:
    'Permite a los gerentes con el permiso de anulación del socio anular el sistema de programación de 7 turnos, permitiendo a los miembros del personal registrar su entrada.',
  partner_signin_7shift:
    'Inicie sesión y conecte su cuenta de 7shifts con Touchbistro para configurar sus ajustes.',
  partner_login_7shifts: 'Iniciar sesión en 7shifts',
  partner_no_account_7shifts: '¿No tienes una cuenta? Enviar una referencia',
  partner_doordash_marketplace_title: '[ES] DoorDash Integration',
  partner_doordash_marketplace_banner:
    "[ES] <1>Important</1>:  It looks like you’re using DoorDash Drive for delivery. If you would like to enable DoorDash Marketplace, please contact the DoorDash Team at <2>{{email}}</2>, so they can help create a new DoorDash Marketplace store to ensure you're able to use both integrations simultaneously.",
  partner_doordash_marketplace_subtitle:
    '[ES] To get started with DoorDash Marketplace, you must: ',
  partner_doordash_marketplace_desc_1:
    '[ES] Confirm your <1>Menus</1> are enabled for Online Ordering',
  partner_doordash_marketplace_desc_2: '[ES] Confirm your venue’s <1>Hours of Operation</1>',
  partner_doordash_marketplace_desc_3: '[ES] Configure your DoorDash Marketplace Delivery Pricing',
  partner_doordash_marketplace_desc_4: '[ES] Sign in or create a DoorDash Merchant account ',
  partner_doordash_marketplace_desc_5:
    '[ES] Connect your TouchBistro venue with your desired DoorDash Store ',
  partner_doordash_marketplace_multi_venue:
    '[ES] Want to get multiple venues using DoorDash Marketplace?',
  partner_doordash_marketplace_contact:
    '[ES] Contact <1>{{email}}</1> to help get you set up multiple venues at once.',
  partner_doordash_marketplace_onboarding_step_online_menu: '[ES] Online Menu',
  partner_doordash_marketplace_onboarding_step_hours_of_operation: '[ES] Hours of Operation',
  partner_doordash_marketplace_onboarding_step_delivery_pricing: '[ES] Delivery Pricing',
  partner_doordash_marketplace_onboarding_step_review: '[ES] Review',
  partner_doordash_marketplace_onboarding_step_visit_doordash: '[ES] Visit DoorDash',
  partner_doordash_marketplace_onboarding_step_connect_store: '[ES] Connect Store',
  partner_doordash_marketplace_onboarding_save_continue: '[ES] Save & Continue',
  partner_doordash_marketplace_onboarding_exit_dialog_title:
    '[ES] Are you sure you want to exit your DoorDash Marketplace setup for {{venueName}}?',
  partner_doordash_marketplace_onboarding_exit_dialog_content:
    "[ES] Don't worry! All steps you've already completed will be saved so you can pick up where you left off.",
  partner_doordash_marketplace_onboarding_exit_dialog_confirm: '[ES] Exit Setup',
  partner_doordash_marketplace_onboarding_step_online_menu_desc:
    '[ES] Confirm the “Online Ordering” menu(s) that will be used for DoorDash Marketplace. Menus enabled for “Online Ordering” will automatically be assigned to DoorDash Marketplace. Changes made will appear across all online ordering platforms. \n\n<1>Important:</1> Only your configured schedules for menus and menu items will appear in DoorDash Marketplace. Any configured schedules for menu groups will not appear in DoorDash Marketplace. All menus enabled for “Online Ordering” will automatically be assigned to DoorDash Marketplace.',
  partner_doordash_marketplace_onboarding_step_online_menu_title:
    '[ES] Menu(s) Enabled for Online Ordering Preview',
  partner_doordash_marketplace_onboarding_step_online_menu_edit_menus: '[ES] Edit Menus',
  partner_doordash_marketplace_onboarding_step_online_menu_menu_name: '[ES] Menu Name',
  partner_doordash_marketplace_onboarding_step_online_menu_available_on: '[ES] Available On',
  partner_doordash_marketplace_onboarding_step_online_menu_menu_groups: '[ES] Menu Groups',
  partner_doordash_marketplace_onboarding_step_online_menu_menu_items: '[ES] Menu Items',
  partner_doordash_marketplace_onboarding_step_online_menu_empty_title:
    '[ES] You don’t have any menus for Online Ordering',
  partner_doordash_marketplace_onboarding_step_online_menu_empty_subtitle:
    '[ES] Please configure your Menu so you can continue setting up your DoorDash Marketplace integration.',
  partner_doordash_marketplace_onboarding_step_online_menu_exit_subtitle:
    '[ES] By editing your menu, you will exit the DoorDash Marketplace setup experience.\n\n',
  partner_doordash_marketplace_onboarding_continue_doordash_setup: '[ES] Continue DoorDash Setup',
  partner_doordash_marketplace_onboarding_edit_menu_title:
    '[ES] Edit your Online Ordering Menus for DoorDash Marketplace',
  partner_doordash_marketplace_onboarding_edit_menu_subtitle:
    "[ES] Changes made will appear across all online ordering platforms. After you've finished editing your menu(s), please save your changes and continue with the DoorDash Marketplace setup.",
  partner_doordash_marketplace_onboarding_step_hours_of_operation_desc:
    '[ES] The below "Hours of Operation" will be applied to DoorDash Marketplace. \n\n<1>Important:</1> If you have specific schedules for your online ordering menu(s), these timeframes will be used for DoorDash Marketplace.',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_empty_title:
    '[ES] You haven’t set up your Hours of Operation',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_empty_subtitle:
    '[ES] Please configure your Hours of Operation so you can continue setting up your DoorDash Marketplace integration.',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_add:
    '[ES] Add Hours of Operation',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_edit:
    '[ES] Edit Hours of Operation',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_exit_subtitle:
    '[ES] By editing your Hours of Operation, you will exit the DoorDash Marketplace setup experience.\n\n',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_exit_subtitle_add:
    '[ES] By adding your Hours of Operation, you will exit the DoorDash Marketplace setup experience.\n\n',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_exit_subtitle_exceptions_closures:
    '[ES] By editing your Exceptions and Closures, you will exit the DoorDash Marketplace setup experience.\n\n',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_exit_subtitle_menu:
    '[ES] Any steps you’ve completed will be saved and you can continue from where you left in the future.',
  partner_doordash_marketplace_onboarding_edit_add_hours_of_operation_title:
    '[ES] Edit your Hours of Operation for DoorDash Marketplace',
  partner_doordash_marketplace_onboarding_edit_add_hours_of_operation_subtitle:
    '[ES] Configure your operating hours for your DoorDash Marketplace integration. After you’ve finished, please save your changes and continue the setup process.',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_day: '[ES] Day',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_start_end_time:
    '[ES] Start & End Time',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_preview:
    '[ES] {{schedule}} Preview',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_preview_menu:
    '[ES] Menu {{schedule}} Preview',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_edit_menu_schedule:
    '[ES] Edit Menu Schedule',
  partner_doordash_marketplace_onboarding_edit_menu_schedule_title:
    '[ES] Return to your DoorDash Marketplace Setup',
  partner_doordash_marketplace_onboarding_edit_menu_schedule_subtitle:
    '[ES] Configure your operating hours for your DoorDash Marketplace integration. After you’ve finished, please save your changes and return back to the setup process.',
  partner_doordash_marketplace_onboarding_edit_menu_schedule_return:
    '[ES] Return to DoorDash Setup',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_edit_exceptions_closure:
    '[ES] Edit Exceptions & Closures',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_empty_menu_schedule_title:
    '[ES] You don’t have a schedule for this Menu',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_empty_menu_schedule_subtitle:
    '[ES] Please configure your Menu Schedule in order to continue your setup',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_overlapping_title:
    '[ES] Menu Schedules are overlapping',
  partner_doordash_marketplace_onboarding_step_hours_of_operation_overlapping_subtitle:
    '[ES] Please configure your Menu Schedules so they don’t overlap in order to continue your setup.',
  partner_doordash_marketplace_onboarding_step_delivery_pricing_desc:
    '[ES] If you\'d like to increase menu item prices for customers ordering delivery through your DoorDash Marketplace integration, please do so below. \n\n<1>Note:</1> Orders placed for pickup through DoorDash Marketplace will be priced based on what you\'ve already set for your "Online Ordering" menu(s).',
  partner_doordash_marketplace_onboarding_step_delivery_input_label:
    '[ES] Increase DoorDash Marketplace menu(s) price by',
  partner_doordash_marketplace_onboarding_step_delivery_input_empty:
    '[ES] E.g. Your menu item is $10. This means your menu item will cost the same for pickup and delivery orders.',
  partner_doordash_marketplace_onboarding_step_delivery_input_increase:
    '[ES] E.g. Your menu item is $10. This means your item for pickup is $10 and for delivery is ${{value}}.',
  partner_doordash_marketplace_onboarding_step_delivery_input_dollar_error:
    '[ES] The delivery price increase in dollars must be between 0 and 1000',
  partner_doordash_marketplace_onboarding_step_delivery_input_percentage_error:
    '[ES] The delivery price increase as a percentage must be between 0 and 100',
  partner_doordash_marketplace_onboarding_step_review_desc:
    '[ES] Please ensure the information below is correct before clicking “Submit”',
  partner_doordash_marketplace_onboarding_step_review_online_menus_title: 'Online Menu(s)',
  partner_doordash_marketplace_onboarding_step_review_online_menus_subtitle:
    '[ES] Menu(s) Enabled for Online Ordering',
  partner_doordash_marketplace_onboarding_step_review_edit_delivery_pricing:
    '[ES] Edit Delivery Pricing',
  partner_doordash_marketplace_onboarding_step_review_edit_delivery_pricing_content:
    '[ES] Menu prices for delivery will be {{price}} extra',
  partner_doordash_marketplace_onboarding_step_review_accept_contract:
    'I agree and acknowledge to have my restaurant and account info sent to DoorDash Marketplace.',
  partner_doordash_marketplace_onboarding_step_visit_doordash_subtitle:
    '[ES] Thank you for providing your menu details!',
  partner_doordash_marketplace_onboarding_step_visit_doordash_body:
    "[ES] In order to proceed with next steps, you'll need to login to or create a DoorDash Merchant account. Once your DoorDash Merchant and TouchBistro accounts have been connected, you'll be redirected to this page to continue the final step of your DoorDash Marketplace setup. \n\n<1>Note:</1> Any other previous POS integration after signing in will be deactivated and will use TouchBistro’s integration. ",
  partner_doordash_marketplace_onboarding_step_visit_doordash_create_account: '[ES] Create Account',
  partner_doordash_marketplace_onboarding_step_visit_doordash_login_to_doordash:
    '[ES] Login to DoorDash',
  partner_doordash_marketplace_onboarding_step_connect_store_title: '[ES] Let’s connect your store',
  partner_doordash_marketplace_onboarding_step_connected_store_title: '[ES] Connect Store',
  partner_doordash_marketplace_onboarding_step_connected_store_subtitle:
    '[ES] Thanks for completing your application!',
  partner_doordash_marketplace_onboarding_step_connected_store_desc_1:
    '[ES] We’re still syncing your venue at this time. ',
  partner_doordash_marketplace_onboarding_step_connected_store_desc_2:
    '[ES] Please check back again later.',
  partner_doordash_marketplace_onboarding_step_connected_store_button: '[ES] Back to Cloud',
  partner_doordash_marketplace_onboarding_step_connect_store_desc:
    '[ES] Please select the DoorDash store you’d like to connect with TouchBistro to ensure the correct information is synced. \n\n<1>Note:</1> If you do not see the stores that you’d like to connect with, please contact <2>DoorDash support</2>. ',
  partner_doordash_marketplace_onboarding_step_connect_store_select_store_label:
    '[ES] Select store to to connect',
  partner_doordash_marketplace_onboarding_step_connect_store_select_store_placeholder:
    '[ES] Select Venue to sync with DoorDash',
  partner_doordash_marketplace_onboarding_step_connect_store_no_store_placeholder:
    '[ES] No store found',
  partner_doordash_marketplace_onboarding_step_connect_store_no_store_click_here: '[ES] click here',
  partner_doordash_marketplace_onboarding_step_connect_store_no_store_error:
    "[ES] It looks like you don't have any stores associated with your DoorDash account. \n\nPlease <1></1> to create a DoorDash Merchant account where you will be able to add your store. Once completed, refresh this page and try again.",
  partner_doordash_marketplace_onboarding_step_connect_store_not_business_admin_error:
    '[ES] You are not the DoorDash Business admin to the account and you do not have access to all stores. \n\nPlease login with the correct account that is the DoorDash Business Admin permissions. Please contact <1>DoorDash Support</1> for assistance. ',
  partner_doordash_marketplace_onboarded_title:
    '[ES] Start receiving orders on DoorDash Marketplace!',
  partner_doordash_marketplace_onboarded_desc:
    '[ES] All your information has been successfully synced and your DoorDash store is ready to receive orders. Enable your orders to go live!',
  partner_doordash_marketplace_onboarding_reject_title: '[ES] Sorry we couldn’t sync your venue',
  partner_doordash_marketplace_onboarding_reject_desc:
    '[ES] There was an issue that has occurred. The reason is : {{message}}. Please contact DoorDash support for further help by contacting at 855-431-0459.',
  partner_doordash_marketplace_onboarding_reject_store_not_active_desc:
    'Store could not be successfully activated, please verify details in DoorDash Merchant Portal or contact DoorDash Support.',
  partner_doordash_marketplace_onboarding_reject_retry_title:
    '[ES] Sorry we couldn’t connect your venue',
  partner_doordash_marketplace_onboarding_reject_retry_desc:
    '[ES] There was an issue that has occurred. The reason is: {{message}}. Please retry connecting your DoorDash store with TouchBistro after updating your menu.',
  partner_doordash_marketplace_onboarding_reject_reconnect_button: '[ES] Reconnect Store',
  partner_doordash_marketplace_onboarding_reject_reconnect_message:
    '[ES] We’re still reconnecting your venue at this time. Please check back again later.',
  partner_doordash_marketplace_onboarding_reject_redirecting_modal_title:
    '[ES] Oops! Your DoorDash login session has expired',
  partner_doordash_marketplace_onboarding_reject_redirecting_modal_desc:
    "[ES] You're about to leave this page to review your application again. We'll guide you back to the onboarding wizard to ensure a smooth return!",
  partner_doordash_marketplace_enable_orders: '[ES] Enable Orders',
  partner_doordash_marketplace_enable_orders_tip:
    '[ES] New DoorDash merchants will be activated during this step.',
  partner_doordash_marketplace_onboarding_disabled_desc:
    '[ES] DoorDash Marketplace is disabled and will no longer receive orders. Please enable DoorDash Marketplace to receive orders again.',
  partner_doordash_marketplace_certification_account_change_title:
    '[ES] Are you sure you want to apply these changes to {{venueName}}?',
  partner_doordash_marketplace_certification_account_change_desc:
    '[ES] These changes will be applied and updated on DoorDash Marketplace',
  partner_doordash_marketplace_certification_account_disable_title:
    '[ES] Are you sure you want to disable DoorDash on {{venueName}}?',
  partner_doordash_marketplace_certification_account_disable_desc:
    '[ES] You will no long receive new orders through DoorDash Marketplace',
  partner_doordash_marketplace_certification_account_deactivate_title:
    '[ES] Are you sure you want to deactivate DoorDash for {{venueName}}?',
  partner_doordash_marketplace_certification_account_deactivate_desc:
    '[ES] Deactivating DoorDash will remove it from this venue entirely. The venue will need to go through the purchase process to integrate again with DoorDash in the future.',
  partner_doordash_marketplace_disable: '[ES] Disable Doordash',
  partner_doordash_marketplace_deactivate: '[ES] Deactivate Doordash Marketplace',
  partner_doordash_marketplace_deactivate_integration: '[ES] Deactivate Doordash Integration',
  partner_doordash_marketplace_reset_integration: '[ES] Reset Doordash Integration',
  partner_doordash_marketplace_deactivate_integration_success_message:
    '[ES] The DoorDash Integration has been successfully deactivated! Please remind the customer to deactivate their account with DoorDash.',
  partner_doordash_marketplace_reset_integration_success_message:
    '[ES] The DoorDash Integration has been successfully reset!',
  partner_doordash_marketplace_enabled_online_menus_subtitle:
    '[ES] To change your DoorDash Marketplace Menus you will need to visit your Menus on Cloud and configure your changes there.',
  partner_doordash_marketplace_enabled_hours_of_operation_subtitle:
    '[ES] To change your Hours of Operation you will need to visit your Hours & Scheduling on Cloud and configure your changes there.',
  partner_doordash_marketplace_enabled_menu_schedule_subtitle:
    '[ES] To change your DoorDash Marketplace Menu Schedule you will need to visit your Hours & Scheduling on Cloud and configure your changes there.',
  partner_doordash_marketplace_menu_sync_failure_title: '[ES] Oops, something went wrong',
  partner_doordash_marketplace_menu_sync_failure_DD_job_failed:
    '[ES] Menu update failed to update to DoorDash. Please try publishing your menu again',
  partner_doordash_marketplace_menu_sync_failure_DD_job_running:
    '\n[ES] Synching of the DoorDash integration is currently in progress. Please wait 15 minutes before trying again.',
  partner_doordash_marketplace_menu_sync_failure_retry: '[ES] Retry Publishing',
  partner_doordash_marketplace_menu_sync_failure_content:
    "[ES] The menu has been published to the POS but wasn't successfully transferred to the DoorDash store. Please see the following issues below: \n\n<1></1>",
  partner_doordash_marketplace_menu_sync_failure_request_failed:
    '[ES] Failed to retrieve DoorDash menu sync status, please contact support.',
  partner_referrence_url_deliverect: 'https://www.deliverect.com/es-mx/integrations/touchbistro',
  partner_davo_model_benefits_and_features_title: '[ES] Benefits & Features:',
  partner_davo_model_benefits_and_features_1: '[ES] Automatically sets aside sales tax daily',
  partner_davo_model_benefits_and_features_2: '[ES] Files and pays, on time and in full',
  partner_davo_model_benefits_and_features_3: '[ES] First monthly filing free',
  partner_davo_model_benefits_and_features_4: '[ES] One flat monthly fee – no hidden charges',
  partner_davo_model_benefits_and_features_5: '[ES] Easy set-up through POS with live support',
  partner_davo_model_benefits_and_features_6:
    '[ES] Ensures you receive on-time filing discounts where available',
  partner_davo_model_benefits_and_features_7: '[ES] Storage of tax documents for future reference',
  partner_davo_model_benefits_and_features_8:
    '[ES] For pricing and more details, visit the Davo website',
  partner_davo_get_started_title: '[ES] Get Started with DAVO by Avalara',
  partner_davo_get_started_desc:
    '[ES] Start automating your sales taxes and make sure your taxes are paid on time.',
  partner_davo_connect_with_davo: '[ES] Connect with DAVO by Avalara',
  partner_davo_connect_with_davo_desc:
    '[ES] To get started, you must agree to have your TouchBistro restaurant and account information sent to DAVO by Avalara',
  partner_davo_connect_with_davo_I_agree: '[ES] I Agree',
  partner_davo_manage_title: '[ES] Manage your DAVO by Avalara account',
  partner_davo_manage_desc:
    '[ES] Visit your account on DAVO by Avalara to manage all your sales taxes',
  partner_davo_manage_go_to_davo: '[ES] Go to DAVO by Avalara',
  password_confirm_new: 'Repite tu nueva contraseña',
  password_confirm: 'Repite tu contraseña',
  password_current: 'Contraseña actual',
  password_description:
    'Elige una contraseña segura que contenga una combinación de letras, números y símbolos. Tu contraseña debería ser difícil de adivinar. Puede asemejarse a una palabra. Esta contraseña debería ser utilizada únicamente para tu cuenta de TouchBistro.',
  password_must_match: 'Las contraseñas deben coincidir',
  password_new: 'Nueva contraseña',
  password_rule_chars: 'No contiene caracteres especiales fuera de @ $ % ^ & * ! #',
  password_rule_length: 'Contiene al menos 8 caracteres',
  password_rule_name: 'No contiene tu nombre ni tu apellido',
  password_rule_number: 'Contiene un número',
  password_rule_uppercase: 'Contiene una mayúscula',
  // deepcode ignore NoHardcodedPasswords: Translation key, not hardcoded password
  password: 'Contraseña',
  payload: 'Payload',
  payment_details: 'Detalles del Pago',
  payments: 'Pagos',
  payments_and_tips: 'Pagos y Propinas',
  payout_period: 'Periodo de pago',
  payout_reason_name: 'Payout Reason Name',
  percent: 'Percent',
  percentage: 'porcentaje',
  personal_details: 'Detalles Personales',
  phone: 'Número telefónico',
  country_code: 'Código de país',
  pos: 'POS',
  premium_pay_multiplier: 'Multiplicadores de pago premium',
  preview: 'Avance',
  price: 'Precio',
  printers_create_success: '{{printerName}} ha sido creado exitosamente',
  printers_create: 'Crear Impresora',
  printers_edit: 'Editar Impresora',
  printers_must_be_configured_on_ios: 'Impresoras deben ser configuradas en el iOS',
  printers: 'Impresoras',
  privacy_notice: 'aviso de privacidad.',
  promotions: 'Promociones',
  policies: 'Normas',
  portrait: 'Retrato',
  publish_loading: 'Publicando cambios...',
  publish_success: 'Sus cambios se han publicado con éxito.',
  publish: 'Publicar',
  read_and_agree_to_terms: 'He leído y acepto los ',
  read_permission: 'leer',
  reason_for_void: 'Motivo para anular',
  reason_for_void_is_required: '[ES] Reason for void is required',
  reduced_tax_percentage: 'porcentage de impuesto reducido',
  refresh: 'Actualizar',
  remove: 'Retirar',
  rename: '[ES] Rename',
  redeem: '[ES] Redeem',
  remove_row: 'Remove Row',
  report_name: 'Título de reporte',
  report_frequency: 'Frecuencia del reporte',
  report_name_placeholder: 'Ingrese el título de su reporte',
  restore_role: '[ES] Restore to default',
  define_report: 'Definición del reporte',
  scheduled_report_name_desc:
    'Por favor ponga nombre a su informe, seleccione su formato preferido y agregue al menos un correo electrónico. También puede incluir una nota para detallar el contenido de su reporte.',
  scheduled_report_note: 'Agregue una nota (opcional)',
  write_description: 'Escriba una descripción',
  send_to: 'Enviar a',
  select_venues_schedule_reports:
    'Usted puede programar el informe para uno o más establecimientos.',
  next_step: 'Siguiente Paso',
  replacement_sales_category: '[ES]Replacement Sales Category',
  report_format: 'Formato del Reporte',
  report: 'Reporte',
  reporting_analytics_card_description:
    'Las herramientas de informes y análisis de TouchBistro le brindan información crítica sobre su restaurante a la que puede acceder desde cualquier lugar, desde un iPad en el sitio, en la oficina en una computadora, en la carretera con su teléfono inteligente.',
  reporting_and_analytics: 'Informes y análisis',
  reports_spanning_days: 'Los informes reflejarán días que abarcan desde',
  report_upload_detail: 'Detalle de subida del informe',
  report_upload_summary: 'Resumen de subida del informe',
  reports_menu_items_sales_banner_title:
    'El elemento del menú Sales (Ventas) se ha combinado con un nuevo informe llamado "Sales by Table & Server" (Ventas por mesa y mozo).',
  reports_menu_items_sales_banner_subtitle:
    'El informe del elemento del menú Sales (Ventas) se ha combinado con un nuevo informe llamado Sales by Table & Server (By Server) (Ventas por mesa y mozo [por mozo]) y se eliminará de "Staff" (Personal) en las próximas semanas. Aún podrás descargar este informe en tu formato de preferencia y continuar recibiéndolo como parte de tus informes programados con el nombre Sales by Server (Ventas por mozo).',
  required: 'obligatorio',
  requires_manager_approval: 'Requiere aprobación del gerente',
  reset: '[ES] Reset',
  reset_db: 'Resetear Contraseña',
  restaurant_groups: 'Restaurant Groups',
  resource: 'Recurso',
  reset_rmm_confirmation_message:
    'Esto eliminará todos los datos de la nube que no se hayan sincronizado con el TPV. ¿Estás seguro?',
  reset_rmm_success_message:
    'Se han eliminado los datos del menú en la nube para este lugar. Reconstrucción desde la última sincronización POS.',
  reset_rmm: 'Restablecer Administrador de menú',
  restaurant_revitalization_title: 'Restaurant Revitalization Fund (RRF) Application',
  restaurant_revitalization_description:
    'The U.S. Small Business Administration (SBA) has created the RRF to provide emergency assistance for eligible restaurants, bars and other hospitality businesses impacted by COVID-19. You can find more information <a href=\'https://www.sba.gov/sites/default/files/2021-04/2021.04.20%20-%20Restaurant%20Revitalization%20Funding%20Program%20Guide%20Finalshare-508.pdf\' target="_blank">here</a>. Click on the link below to submit your application.',
  return_to_dashboard: 'Regresar a la pagina de inicio',
  returns_inventory: 'Regresar al inventario',
  request_manager_approval: 'Se ha enviado tu solicitud a tu gerente',
  request_manager_approval_paragraph:
    'Espera un correo electrónico de confirmación de que tu cuenta en Cloud ha sido aprobada. Ponte en contacto con tu gerente si hay algún problema.',
  rmm: 'Administrador de Menu Remoto',
  role_details: 'Detalles del Rol',
  roles: 'Funciones',
  rolling_period: 'Reportes recurrentes',
  route_not_found: 'Algo salió mal. Pagina no encontrada.',
  sales_title: 'Ventas',
  sales_by_menu_group: 'Ventas por Grupo de Menú',
  sales_by_menu_item: 'Ventas por Elemento de Menú',
  sales_by_online_order_provider: 'Ventas Por Proveedor de Pedidos en Línea',
  sales_by_order_type: 'Ventas por Tipo de Orden',
  sales_by_sales_category: 'Ventas por Categoría de Ventas',
  sales_by_section: 'Ventas por Sección',
  sales_categories: 'Categoria de Ventas',
  sales_category: 'Categoría de ventas',
  sales_total: 'Total de Ventas',
  sales_summary: 'Resumen de ventas',
  menu_summary: 'Resumen del menú',
  saturday: 'Sabado',
  save_overtime_rules: 'Guardar reglas de tiempo extra',
  save: 'Guardar',
  save_changes_confirmation: '¿Estás seguro/a de que quieres guardar estos cambios?',
  see_more_details: 'See More Details',
  schedule_a_report: 'Programar un Reporte',
  schedule_report: 'Programar Informe',
  schedule: 'Horario',
  scheduled_report_name: 'Nombre del Reporte Programado',
  scheduled_report_time_of_day:
    'Su informe {{selectedFrequency}} reflejará la hora establecida para su lugar .',
  search_to_add_existing_menu_groups: 'Buscar categorias de menus',
  search_to_add_existing_modifier_groups: 'Buscar grupos de modificadores existentes',
  search_items: 'Buscar {{itemName}}...',
  search: 'buscar',
  search_field_validation_error: 'Search criteria should not be blank.',
  search_field_not_found_error: 'This {{field}} cannot be found in the system.',
  corporation_not_found_error:
    'Corporation for venue {{venueXRefID}} cannot be found in the system.',
  no_username_but_found_signups_warning:
    'Found signup records for this email, but no usernames found in the system.',
  signup_records: 'Signup Records',
  no_venue_user_roles: "We couldn't find any active venue roles for this user.",
  section: 'Sección',
  select_multi_unit_venue: 'Seleccionar Lugar',
  select_color: '[ES] Select a color',
  security_question_1_label: 'Pregunta de Seguridad 1',
  security_question_1: '¿Cuál era tu apodo de niño?',
  security_question_10: '¿Cuál era el apellido de tu maestra de tercer grado?',
  security_question_11: '¿En dónde vive tu pariente favorito?',
  security_question_12: '¿A qué hora nació tu primer hijo?',
  security_question_13: '¿Cuál era tu dirección cuando eras niño?',
  security_question_14: '¿Cuáles eran los últimos 4 dígitos de tu teléfono de niño?',
  security_question_15: '¿Cuáles son los 5 últimos dígitos de tu licencia de conducir?',
  security_question_16: '¿Quién era tu héroe cuando eras niño?',
  security_question_2_label: 'Pregunta de Seguridad 2',
  security_question_2: '¿En qué ciudad conociste a tu pareja/otra mitad?',
  security_question_3: '¿Cuál es el nombre de tu mejor amigo de la infancia',
  security_question_4: '¿En qué calle vivías en tercer grado?',
  security_question_5: '¿Cuál es el segundo nombre de tu hijo más chico?',
  security_question_6: '¿Cuál es el segundo nombre de tu hermano mayor?',
  security_question_7: '¿Cómo se llama tu primo más grande?',
  security_question_8: '¿Cuál era el nombre de tu primer peluche?',
  security_question_9: '¿En qué ciudad se conocieron tus papás?',
  security_questions_description:
    'Las preguntas de seguridad nos ayudan a verificar tu identidad y a asegurar que tu cuenta se encuentra protegida.',
  security_questions: 'Preguntas de seguridad',
  service_time_info_text:
    'Para ayudarnos a proporcionar informes más precisos, configure cuándo abre para recibir servicio todos los días.',
  service_time: 'Tiempo de servicio',
  service_week: 'Su semana de servicio comienza el:',
  session_logged_out:
    'Has sido desconectado de TouchBistro Cloud. Por favor inicia tu sesion denuevo, si deseas continuar.',
  set_up_your_report_schedule: 'Configura Tu Programa de Reportes',
  settings_card_description:
    'TouchBistro Cloud le permite controlar la configuración de su restaurante desde cualquier lugar. También puede ver nuestra sección de ayuda o llamar a nuestra línea directa 24/7/365.',
  settings_change_service_time:
    'Cambiar su tiempo de servicio cambiará el rango de tiempo para sus informes programados.',
  settings_error: 'Su configuración no se pudo actualizar.',
  settings_success: 'Sus ajustes se han guardado correctamente.',
  settings: 'Configuración',
  shift_details: 'Detalles de Turno',
  shift_part_1: 'Shift Parte 1',
  shift_part_2: 'Shift Parte 2',
  short_name_helper_text:
    'Sera mostrado en el ticket de cocina, recibo y/o reportes dependiendo de su configuracion avanzada de impresora.',
  short_name: 'Nombre corto',
  show: 'Mostrar',
  sign_in: 'Sign in',
  sign_up_success_text_header: '¡Felicitaciones! Su cuenta TouchBistro está lista para usarse.',
  sign_up_success_text_message_cloud:
    'Con esta cuenta de usuario, puede acceder a la información y administrar las operaciones de su corporación en la Nube. Por favor, haga clic en el botón de abajo para iniciar sesión. Deberá iniciar sesión con la dirección de correo electrónico que utilizó para crear esta cuenta como su nombre de usuario.',
  sign_up_success_text_message_pos:
    "Con esta cuenta de usuario, usted puede acceder a la información y administrar las operaciones de sus restaurantes en el sistema POS.<br /><br />Su nuevo número de identificación personal (NIP) es: <b style='color: #000; font-size: 24px'>{{pin}}</b><br /><br />Por favor ingrese su NIP en el POS para iniciar sesión y realizar sus tareas diarias.",
  sign_up_success_text_message_cloud_and_pos:
    "Con esta cuenta de usuario podrá acceder a la información y gestionar las operaciones de su Negocio en la Nube y en el POS.<br /><br />Su nuevo número de identificación personal (NIP) es: <b style='color: #000; font-size: 24px'>{{pin}}</b><br /><br />Por favor ingrese su NIP en el POS para iniciar sesión y realizar sus tareas diarias.<br /><br />Para ver Cloud, deberá iniciar sesión con la dirección de correo electrónico que utilizó para crear esta cuenta como su nombre de usuario.",
  sign_up_instruction:
    'Por favor regístrate para abrir tu cuenta de TouchBistro. Tu cuenta de TouchBistro te da acceso a tus productos TouchBistro.',
  sign_up_success_button: 'Acceso',
  sign_up_success_text_1: '¡Felicidades! Tu cuenta de TouchBistro está lista.',
  sign_up_success_text_2: 'Ahora puedes ingresar a tus productos de TouchBistro con esta cuenta.',
  sign_up_text: 'Regístrate',
  sign_up: 'Registrar tu Cuenta',
  size: '[ES] Size',
  sort_type: 'Orden {{type}}',
  sort_by: 'Orden',
  spanish_mexico: 'Español (Mexico)',
  specific_date_range: 'Rango de fechas',
  spread_of_hours_info_text:
    'Cuando está habilitado, si la jornada laboral de un empleado excede las 10 horas, recibirá una hora de pago a la tarifa de Spread of Hours. Tenga en cuenta que un día laboral es desde el comienzo del primer turno de un empleado hasta el final de su último turno.',
  spread_of_hours_pay: 'Extensión de horas de pago',
  spread_of_hours_rate: 'Tasa de propagación de horas',
  staff_performance: 'Rendimiento del Personal',
  start_date: 'Fecha de inicio',
  start_of_week: 'Comienzo de Semana',
  start_time: 'Hora de inicio',
  status: 'Estado',
  stay_on_page: 'Quedarse en esta pagina',
  step_1_info: 'Step 1 info',
  step_1_title: 'step_1_title',
  step_2_title: 'step_2_title',
  stock_availability: '[ES] Stock Availability',
  subdomain: 'Subdominio',
  submission_success: 'Envío exitoso',
  submit: 'Envíar',
  success_notification_message: 'Tus cambios fueron guardados',
  sunday: 'Domingo',
  sure_to_remove: 'Estás seguro de que desea eliminar',
  takeout: 'Para llevar',
  tax_2_on_tax_1_updated: 'Impuesto 2 sobre Impuesto 1 actualizados',
  tax_2_on_tax_1: 'Impuesto 2 sobre Impuesto 1',
  tax_details: 'detalles de impuesto',
  tax_name: 'Nombre fiscal',
  tax_number: 'numero de impuesto',
  tax_rates: 'Las tasas de impuestos',
  tax_rate: 'Tasa de impuesto',
  tax_rules_excluded_order_type: 'Regla de impuesto: Tipo de Ordenes Excluidos',
  tax_rules_reduced_tax_rate: 'Regla de Impuesto: Tasa de Impuesto Reducido',
  tax: 'Impuesto',
  taxable_amount_helper:
    'Tu tasa de impuesto reducido tendra efecto cuando la cuenta sea menor que la cantidad sujeto a impuesto',
  taxable_amount: 'Cantidad sujeto a impuesto',
  taxes: 'Impuestos',
  tax_summary: 'Resumen de Impuestos',
  tb_landing_text: 'TouchBistro landing image',
  tb_logo_text: 'logo de TouchBistro',
  tbdine_feature_unavailable:
    'Lo sentimos, esta función no está disponible actualmente en su ubicación.',
  tbdine_help_link_message:
    'Encuentre guías detalladas paso a paso para configurar su Online Ordering.',
  tbdine_help_link_text: 'Ayuda',
  tbdine_help_link_url: 'https://www.touchbistro.com/help/articles/touchbistro-online-ordering/',
  tbdine_integration_description_blocked_dates:
    'Haga clic en " Agregar cierre / hora bloqueada "para agregar fechas y horas bloqueadas. Esto evitará que los clientes realicen Online Ordering durante la ventana especificada. Por ejemplo, es posible que desee bloquear pedidos durante Navidad, eventos privados y otros días festivos. \n \nUna vez completado, haga clic en "Límites de entrega " en la barra de navegación del lado izquierdo para pasar al siguiente paso ',
  tbdine_integration_description_delivery_boundaries:
    'Si está ofreciendo entrega, establezca sus límites de entrega siguiendo los pasos a continuación. \n \n1) Navegue a la región de su restaurante haciendo zoom (+/- en la esquina inferior izquierda) y arrastrando el mapa. \n \n2) Haga clic en el pequeño icono de polígono gris en la parte inferior del mapa. \n \n3) Haga clic en el botón "Límite primario (rojo) ". \n \n4) Comience a dibujar su Zona límite principal haciendo clic en su cursor en el punto de partida seleccionado. Arrastre para crear la línea inicial de su límite deseado. Haga clic en su cursor cada vez que desee crear un nuevo punto desde el cual arrastrar su línea. Asegúrese de conectar todas sus líneas haciendo clic hacia atrás en su punto inicial para cerrar su forma. Su zona de entrega primaria ahora debería estar sombreada en rojo. \n \n5) Si ofrece una zona de entrega secundaria, haga clic en el botón "Límite secundario (gris) " y repita los pasos 2 y 4 anterior para definir su límite. \n \nCuando finalice, o si no necesita definir bo de entrega undaries, haga clic en "Tipos de pago" en la barra de navegación del lado izquierdo para pasar al siguiente paso ',
  tbdine_integration_description_introduction:
    'Necesitamos que nos proporciones información sobre tu configuración, servicios y menú antes de que puedas comenzar a aceptar Online Ordering. ¡Te guiaremos a través de los sencillos pasos para configurarlo!',
  tbdine_integration_description_oo_menu: 'Menú de Online Ordering',
  tbdine_integration_description_order_hours:
    'Ingrese sus horas de operación para Online Ordering. Marque o desmarque las casillas "Recogida" y "Entrega" para reflejar qué servicios se ofrecen en ese momento y fecha. \n \nCuando haya terminado , haga clic en "Guardar" en la parte inferior de la página. \n \n Luego haga clic en "Fechas bloqueadas" en la barra de navegación del lado izquierdo para avanzar al siguiente paso.',
  tbdine_integration_description_order_settings:
    'Complete los campos a continuación para reflejar los detalles de sus operaciones y servicios de Online Ordering. \n \nSolo complete los campos que le sean aplicables. Por ejemplo, si no planea ofrecer servicios de entrega, usted puede ignorar los campos relacionados con la entrega. \n \nCuando haya terminado, haga clic en "Guardar" en la parte inferior de la página. \n \n Luego haga clic en "Hora de pedido" en la barra de navegación del lado izquierdo para moverse a el siguiente paso.',
  tbdine_integration_description_payment_online_1:
    'Marque qué métodos de pago aceptará y si el tipo de pago está disponible para los servicios de Recolección y/o Entrega. Si desea aceptar pagos en línea, primero debe solicitar Pagos TouchBistro a continuación. \n \nCuando listo, haga clic en "Guardar" en la parte inferior de la página. \n \nUna vez completado, haga clic en "Menú de pedidos en línea" en la barra de navegación del lado izquierdo para avanzar al siguiente paso.',
  tbdine_integration_description_payment_online_2:
    'Ofrezca a los comensales la posibilidad de pagar en línea usando su tarjeta de crédito a través de TouchBistro Payments. Debe hacer clic en el botón a continuación para iniciar el proceso de solicitud para usar TouchBistro Payments para facilitar los pagos integrados de pedidos en línea. Un representante de TouchBistro se pondrá en contacto con usted próximos pasos.',
  tbdine_integration_description_payment_online_3:
    'Tenga en cuenta: puede continuar con cualquiera de los métodos de pago "sin conexión" a continuación mientras procesa su aprobación o si no desea aceptar pagos en línea',
  tbdine_integration_description_payment_online_button: 'Comience la aplicación',
  tbdine_integration_description_payment_online_modal_cancel: 'Cancelar',
  tbdine_integration_description_payment_online_modal_confirm: 'Confirmar',
  tbdine_integration_description_payment_online_modal_message:
    'TouchBistro lo contactará con respecto a la aprobación de su solicitud dentro de una semana.',
  tbdine_integration_description_payment_online_modal_title:
    'Confirme que desea solicitar pagos de TouchBistro',
  tbdine_integration_description_payment_online_sf_description:
    'Estoy interesado en los pagos de Touchbistro para pedidos en línea de TouchBistro. Comuníquese conmigo lo antes posible {{phone}}',
  tbdine_integration_description_payment_online_sf_subject:
    '{{name}} - Pagos de TouchBistro para pedidos en línea de TouchBistro',
  tbdine_integration_description_payment_online_success:
    '¡Éxito! Hemos recibido con éxito su solicitud de pagos de TouchBistro. Recibirá un correo electrónico una vez que su solicitud haya sido revisada',
  tbdine_integration_description_payment_types_1:
    'IMPORTANTE: Tenga en cuenta que el pedido de TouchBistro NO permite a los clientes pagar en línea en este momento. Todas las transacciones de pago deben realizarse en persona o por teléfono.\n\nMarque qué métodos de pago aceptará y si el tipo de pago está disponible para los servicios de Recogida y / o Entrega. \n \nCuando haya terminado, haga clic en "Guardar" en la parte inferior de la página. \n \nUna vez completado, haga clic en "Menú de Online Ordering" en la barra de navegación del lado izquierdo para pasar al siguiente paso.\n\nSi planea ponerse en contacto con sus comensales para realizar el pago por teléfono con tarjeta de crédito y necesita ayuda con las transacciones con tarjeta no presente, visite ',
  tbdine_integration_description_payment_types_2:
    '\n\nSi desea asesoramiento sobre cómo aprovechar un terminal de tarjeta de largo alcance para pedidos de entrega, comuníquese con el soporte de TouchBistro.',
  tbdine_integration_description_share_to_customer:
    'Si desea incluir un botón "Ordenar en línea" en su sitio web, copie el fragmento de código debajo de su botón preferido. Sus consumidores también pueden encontrarlo en tbdine.com.',
  tbdine_integration_description_start_taking_orders:
    'Si ha completado con éxito todos los pasos anteriores, marque la casilla " Activo "a continuación y haga clic en "guardar". \n¡Ahora está listo para aceptar Online Ordering! \n \nPuede ahora hacer clic en "Salida".',
  tbdine_integration_description: 'Introducción',
  tbdine_integration_disabled_paragraph_1:
    '¡Comience a ofrecer comida para llevar y entrega en línea hoy! Haga clic en el menú desplegable de arriba y seleccione "Habilitar" para comenzar.',
  tbdine_integration_disabled_paragraph_2: 'Accede a comensales hambrientos cerca de ti',
  tbdine_integration_disabled_paragraph_3:
    'Lo Online Ordering fluyen directamente desde su sitio web a su punto de venta en tiempo real para cumplir los pedidos sin problemas',
  tbdine_integration_disabled_subtitle_1:
    'Introducir o elevar los ingresos de comida para llevar y entrega',
  tbdine_integration_disabled_subtitle_2: 'Ofrezca una experiencia digital perfecta',
  tbdine_integration_link_payment_types: 'www.touchbistro.com/manualpaymentsguide/',
  tbdine_integration_modal_exit_cancel: 'Permanecer en la página',
  tbdine_integration_modal_exit_confirm: 'Continuar para salir',
  tbdine_integration_modal_exit_title:
    '¿Está seguro de que desea abandonar TouchBistro Online Ordering?',
  tbdine_integration_modal_load_message: 'Esta acción puede tardar unos minutos',
  tbdine_integration_modal_load_title: 'Cargando TouchBistro Online Ordering',
  tbdine_integration_slide_non_rmm_0:
    'Vaya a Configuración de administrador> Menú> Menús. Seleccione el menú que desea que esté disponible para Online Ordering. En la página "Editar menú", cambie "Online Ordering" a azul oscuro para habilitar el menú para Online Ordering ',
  tbdine_integration_slide_non_rmm_1:
    'Agregue a su menú tocando "Agregar categoría de menú" en la parte inferior de la pantalla',
  tbdine_integration_slide_non_rmm_2:
    'Si hay un elemento en el menú que no desea que esté disponible para Online Ordering, vaya a Configuración de administrador> Menú> Elementos de menú. Seleccione la categoría y el elemento de menú relevantes. Desactive "Mostrar en Online Ordering Menú" y haga clic en "Listo".',
  tbdine_integration_slide_non_rmm_3:
    'Cuando todos los cambios se hayan completado, toque "Subir menú". Puede tomar algunos minutos para que su menú se cargue correctamente.',
  tbdine_integration_slide_non_rmm_4:
    'Una vez completado, haga clic en "Compartir con el cliente" en la barra de navegación del lado izquierdo para avanzar al siguiente paso',
  tbdine_integration_slide_rmm_0:
    'Vaya a Menú> Menús. Seleccione el menú que desea que esté disponible para Online Ordering. Cambie "Usar para Online Ordering" a azul oscuro para habilitar el menú para Online Ordering',
  tbdine_integration_slide_rmm_1:
    'Agregue a su menú tocando el botón + en la sección Categoría de menú. Puede agregar una categoría de menú existente o crear una nueva categoría de menú y agregarle elementos de menú',
  tbdine_integration_slide_rmm_2:
    'Si hay un elemento en el menú que no desea que esté disponible para Online Ordering, navegue a la categoría de menú relevante y al elemento del menú. Desactive "Mostrar en menú público"',
  tbdine_integration_slide_rmm_3: 'Cuando se hayan completado todos los cambios, toque "Publicar".',
  tbdine_integration_slide_rmm_4:
    'Acepte los cambios en el TPV. Vaya a Admin> Verificar cambios en el menú y acepte los cambios',
  tbdine_integration_slide_rmm_5:
    'Cuando se hayan completado todos los cambios, toque "Subir menú". Para futuras actualizaciones del menú, asegúrese de tocar siempre "Subir menú" para finalizar los cambios',
  tbdine_integration_slide_rmm_6:
    'Una vez completado, haga clic en "Compartir con el cliente" en la barra de navegación del lado izquierdo para avanzar al siguiente paso',
  tbdine_integration_slide_title_non_rmm:
    '¡Use su aplicación para iPad TouchBistro POS para configurar su menú de Online Ordering en cuatro simples pasos!',
  tbdine_integration_slide_title_rmm:
    '¡Use su TouchBistro Cloud para configurar su menú de Online Ordering en seis simples pasos!',
  tbdine_integration_title_blocked_dates: 'Fechas bloqueadas',
  tbdine_integration_title_delivery_boundaries: 'Límites de entrega',
  tbdine_integration_title_introduction_long: 'Bienvenido a TouchBistro Online Ordering',
  tbdine_integration_title_introduction: 'Introducción',
  tbdine_integration_title_oo_menu: 'Menu de Online Ordering',
  tbdine_integration_title_order_hours: 'Horas de pedido',
  tbdine_integration_title_order_settings: 'Configuración de pedido',
  tbdine_integration_title_payment_types_1: 'Configuración de pago',
  tbdine_integration_title_payment_types_2: 'Cómo ofrecer pagos en línea',
  tbdine_integration_title_payment_types: 'Tipos de pago',
  tbdine_integration_title_share_to_customer: 'Compartir con el cliente',
  tbdine_integration_title_start_taking_orders: 'Comenzar a tomar pedidos',
  tbdine_integration_title: 'Introducción',
  tbdine_terms_modal_error: 'Acepte los términos de servicio para continuar.',
  tbdine_terms_modal_field_accepted: 'Acepto',
  tbdine_terms_modal_terms:
    'La provisión de TouchBistro Online Ordering se realiza y está sujeta a los <a href="{{link}}" target="_blank"> Términos de servicio de TouchBistro </a> y constituye "Servicios de TouchBistro "como se define en el mismo. Los TouchBistro Online Ordering se le proporcionan de forma gratuita durante un período de doce (12) meses (el "Período libre de tarifas "). TouchBistro se reserva el derecho de cobrarle tarifas con respecto a los TouchBistro Online Ordering después de la expiración del Período de tarifas gratuitas; siempre que: i) TouchBistro le proporcione un aviso con un mínimo de treinta (30) días de anticipación de cualquier tarifa que deba cobrar por el pedido en línea de TouchBistro y ii) no se le cobrarán tarifas con respecto al pedido en línea de TouchBistro sin su previo consentimiento por escrito. Si no desea pagar ninguna tarifa propuesta, su uso de los TouchBistro Online Ordering cesará al expirar el Período libre de tarifas.',
  tbdine_terms_modal_title: 'Términos de Servicio',
  tbdine_update_modal_field_address: 'Dirección del Restaurante',
  tbdine_update_modal_field_city: 'Ciudad',
  tbdine_update_modal_field_code: 'Código Postal',
  tbdine_update_modal_field_country: 'País',
  tbdine_update_modal_field_name: 'Nombre del Restaurante',
  tbdine_update_modal_field_phone: 'Número de Teléfono del Restaurante',
  tbdine_update_modal_field_state: 'Provincia/Estado',
  tbdine_update_modal_subtitle: 'Confirme la información de su restaurante a continuación.',
  tbdine_update_modal_title: 'Su Restaurante',
  tbdine_validation_error:
    'No pudimos habilitar los pedidos en línea. Compruebe que la configuración de su zona horaria sea válida.',
  tbdine_venue_info_validation_error:
    'Parte de su información no es válida. Verifique que su código postal y número de teléfono sean correctos.',
  technical_error_message:
    'Hay algun problema con tu menu. Ponte en contacto con el soporte técnico si el problema continua.',
  terms_of_service: 'términos del servicio',
  text_modifier: 'Modificador de texto',
  text_modifier_report: 'Informe Modificador de Texto',
  thursday: 'Jueves',
  this_month: 'Este Mes',
  this_report_has_moved_body: 'Este informe se fusionó con el informe {{redirect_report_title}}.',
  this_report_has_moved_title: 'El reporte de {{report_title}} se ha movido',
  time_frame: 'Marco de tiempo',
  time_of_day: 'Hora del Día',
  time_rounded: 'El tiempo se redondeará a los próximos 30 minutos',
  timesheet_details: 'Detalles del Parte de Horas',
  timesheet_summary: 'Resumen del Parte de Horas',
  timezone: 'Zona horaria',
  no_options: 'Sin opciones',
  to_end: 'a las {{endTime}} ',
  today: 'hoje',
  tips_by_payment_method: 'Consejos Por Método de Pago',
  tips_by_payment_method_moved: 'Propinas por método de pago',
  labor: 'Laboral',
  go_to_labor: 'Trabajo',
  delivery_fees: 'Cargo por envío',
  toggle_rmm_helpertext:
    'Desactivar Administrador de Menu habilitara todas las functiones de menu en el POS. Para activarlo denuevo, se necesitara hacerlo desde el POS.',
  toggle_rmm: 'Alternar Administracion de Menu',
  toggle_time_dropdown: 'Desplazarse en la lista de horarios',
  tooltip_info: 'Tooltip Info',
  touchbistro_account_prompt: '¿Ya tienes una cuenta de TouchBistro?',
  tuesday: 'Martes',
  turn_on_rmm: 'Para activar el Administrador de Menu Remoto, hay que activarlo desde el POS',
  unauthorized: 'No autorizado. El usuario no tiene suficiente permiso para {{permission_type}}',
  under_construction_message: 'Esta característica estará disponible pronto!',
  under_construction_title: 'Sólo un poco de paciencia...',
  undo: 'Deshacer',
  unitID: 'Numero de ID',
  unlink_device: 'Unlink Device',
  unsubscribed_from_scheduled_reports: 'Ahora estás desuscrito de Reportes Programados',
  unsubscribe_from_scheduled_reports: 'Cancelar Suscripción a Reportes Programados',
  upc: 'UPC',
  update_menu_order_success: 'El orden de las Paginas de Menu han sido actualizados',
  update_password: 'Actualiza tu contraseña',
  change_preferred_name: '[ES] Change Preferred Name',
  change_preferred_name_dialog_title: '[ES] Request to Change Preferred Name',
  change_preferred_name_dialog_description:
    "[ES] Enter the preferred name you'd like to have and a request will be sent to your admin. Once it has been approved your preferred name will be updated.",
  change_preferred_name_dialog_input: '[ES] New Preferred Name',
  change_preferred_name_dialog_send_button: '[ES] Send Request',
  change_preferred_name_dialog_already_initiated_title: '[ES] You have already submitted a request',
  change_preferred_name_dialog_already_initiated_description:
    '[ES] Once it has been approved your preferred name will be updated.',
  change_preferred_name_dialog_success_message: '[ES] Your Request has been sent',
  update_permission: 'actualizar',
  update_security_questions: 'Actualizar preguntas de seguridad',
  update: 'Actualizar',
  upload: 'Upload',
  upload_type: 'Tipo de carga:',
  username_already_existed: 'Lo sentimos, este nombre de usuario ya está en uso.',
  username_invalid_characters:
    'La dirección de correo electrónico es inválida. La dirección de correo electrónico solo puede contener letras, números y los siguientes caracteres especiales: ., _, -., +, %.',
  invalid_characters:
    '{{field}} solo puede contener letras, números y los siguientes caracteres especiales: @, ., _, -.',
  username_invalid_length: 'El nombre de usuario debe contener de 5 a 100 caracteres.',
  username: 'Nombre de Usuario',
  email_address_username: 'Dirección de correo electrónico (nombre de usuario)',
  email_username: 'Dirección de correo electrónico/Nombre de Usuario',
  venue_username: 'Nombre de Usuario',
  username_signup:
    'El nombre de usuario es requerido para ingresar a tu cuenta. Recomendamos incluir el nombre de su lugar en el nombre de usuario para que sea fácil de recordar. Una vez que se establezca, no puede ser modificado.',
  user_id: 'Identificacion del usuario',
  validation_error_matches: '{{field}} no es válido',
  validation_error_oneof: '{{field}} es requerido',
  validation_error_required: '{{field}} es requerido',
  validation_error_number_required: '{{field}} no es un número',
  validation_multiple_choices_required: 'Se debe seleccionar al menos un {{field}}',
  validation_positive: 'Debe ser un número positivo',
  validation_read_and_agree_to_terms:
    'Por favor acepta los términos del servicio y el aviso de privacidad para ingresar a tu cuenta.',
  validation_required_min: 'Por favor seleccione al menos una sección',
  validation_required: 'Este campo es requerido',
  validation_error_minimum_size_required: '{{field}} debe tener al menos {{size}} caracteres',
  validation_error_maximum_size_required:
    '[ES]{{field}} cannot be more than {{limit}} characters (currently {{size}})',
  validation_error_start_date_before_end_date: '[ES] {{field}} must be before {{endDate}}',
  validation_error_end_date_after_start_date: '[ES] {{field}} must be after {{startDate}}',
  validation_error_min_spend_less_max_spend: '[ES] {{field}} must be less then {{maxSpend}}',
  validation_error_max_spend_more_min_spend: '[ES] {{field}} must be more then {{minSpend}}',
  validation_number_max: '[ES] Enter number less or equal to {{max}}',
  base_id: 'Identificacion del Local',
  base_roles: 'Roles de Local',
  base_details: 'Detalles de Local',
  agent_roles: 'Roles de Agente',
  agent_role_details: '[ES] Agent Role Details',
  venue_id: 'Identificacion del Local',
  venue_roles: 'Roles de Local',
  venue_details: 'Detalles de Local',
  venue_name: 'Nombre del Local',
  support_patches: 'Parches de soporte',
  licences: 'Licencias',
  rmm2_venue_sync: 'RMM2 Venue Sync',
  pos_ingestion: 'Ingestión de POS',
  venue_group_demo: '[ES] Venue Group Demo',
  unknown: 'Unknown',
  created_at: 'Created At',
  updated_at: 'Updated At',
  venue_sync: 'Sincronización del Lugar',
  venue_shard_assignment: 'Venue Shard Assignment',
  permissions: 'Permisos',
  view_dashboard: 'Ver panel de control',
  view_more: 'Ver más',
  voids: 'Anulados',
  void_details: 'Detalles nulos',
  void_reason: 'Razón para anular',
  void_reasons: 'Razones para anular',
  void_summary: 'Resumen nulo',
  want_to_learn_more: '¿Quieres saber más?',
  waiter_gratuity: 'Propina de Camarero',
  wednesday: 'Miercoles',
  weekly: 'Semanal',
  weekly_targets: 'Metas Semanales',
  weekly_targets_dining_duration_title: 'Duración Promedio de la Cena',
  weekly_targets_dining_duration_subtitle: 'Por Servidor',
  weekly_targets_discount_void_title: 'Descuentos y Vacíos',
  weekly_targets_discount_void_subtitle: 'Por Servidor',
  weekly_targets_key_targets_title: 'Objetivos Clave',
  weekly_targets_key_targets_subtitle:
    'Para alcanzar los objetivos de su restaurante, todas las siguientes medidas deben alcanzar o superar el 100%.',
  weekly_targets_key_targets_percent_to_sales_target: '% al Objetivo de Ventas',
  weekly_targets_key_targets_sales_target:
    'Su lugar ha ganado {{sales}} esta semana. Debe ganar {{salesToTarget}} más para alcanzar su objetivo de ventas.',
  weekly_targets_key_targets_sales_target_input: '¿Cuál es su objetivo de ventas semanales?',
  weekly_targets_key_targets_percent_to_customer_target: '% al Objetivo del Cliente',
  weekly_targets_key_targets_customer_target:
    'Has tenido {{customers}} clientes visitados esta semana. Necesita {{customersToTarget}} más para visitar para alcanzar su objetivo de cliente.',
  weekly_targets_key_targets_customer_target_input:
    '¿Cuál es su objetivo de conteo semanal de clientes?',
  weekly_targets_key_targets_percent_to_spend_target: '% para Gastar Objetivo',
  weekly_targets_key_targets_spend_target:
    'Su cliente promedio gasta {{spendPerSeat}}. Debe llegar a {{spendPerSeatTarget}} en función de sus objetivos.',
  weekly_targets_key_targets_spend_target_input:
    'Calcularemos esto para usted una vez que haya ingresado sus objetivos.',
  weekly_targets_labor_cost_title: 'Costo de Producción',
  weekly_targets_labor_cost_subtitle: 'por Día',
  weekly_targets_sales_by_category_title: 'Ventas',
  weekly_targets_sales_by_category_subtitle: 'por Categoria',
  weekly_targets_sales_by_section_title: 'Ventas',
  weekly_targets_sales_by_section_subtitle: 'por Sección',
  weekly_targets_spend_by_server_title: 'Gasto Promedio',
  weekly_targets_spend_by_server_subtitle: 'Por Servidor',
  weekly_targets_spend_by_time_title: 'Gasto Promedio',
  weekly_targets_spend_by_time_subtitle: 'por Hora del Día',
  weekly_targets_tip_by_server_title: 'Consejos',
  weekly_targets_tip_by_server_subtitle: 'Por Servidor',
  weekly_targets_upsells_title: 'Upsells',
  weekly_targets_upsells_subtitle: 'Por Modificador',
  whole_numbers_only:
    'Forced modifier count requires you to input whole numbers that are less than 100',
  pos_linking_title: 'Vincula tus cuentas de TPV y de Cloud para {{venueName}}',
  pos_linking_subtitle: '¿Por qué vincular cuentas?',
  pos_linking_p1:
    'Vincular tus cuentas te permitirá acceder a Cloud directamente a través del TPV y permite a TouchBistro mantener tus datos seguros y protegidos. Sin una cuenta en Cloud, no puedes acceder a cierta información fuera del TPV.',
  pos_linking_p2_text_1: 'Para vincular las cuentas,',
  pos_linking_p2_POS_username: ' {{posUserName}},',
  pos_linking_p2_text_2:
    ' introduce tu correo electrónico existente de Cloud. Si no tienes una cuenta en Cloud, introduce un correo electrónico que desees utilizar para crear una cuenta.',
  pos_linking_email_sent_title: 'Se ha enviado un correo electrónico!',
  pos_linking_email_sent_msg:
    'Hemos enviado un correo electrónico con los próximos pasos a <b>{{email}}</b>. Si la dirección de correo electrónico que has proporcionado tiene una cuenta de Cloud existente, confirma las dos cuentas. Si la dirección de correo electrónico que has proporcionado no tiene una cuenta de Cloud asociada, se te indicará que crees una.',
  pos_linking_account_title: 'Crear una cuenta en Cloud',
  pos_linking_account_subtitle: 'Información personal',
  pos_linking_account_description:
    'Proporciona tu información personal para crear tu cuenta en Cloud. Tendrás que ser aprobado por un administrador antes de poder acceder a Cloud. Se enviará un correo electrónico cuando se haya aprobado tu solicitud.',
  pos_linking_account_submit: 'Enviar solicitud',
  pos_linking_account_sent_title: 'Se ha enviado tu solicitud a tu gerente',
  pos_linking_account_sent_msg:
    'Espera un correo electrónico de confirmación de que tu cuenta en Cloud ha sido aprobada. Ponte en contacto con tu gerente si hay algún problema.',
  pos_linking_err_link_request_already_linked_title: 'Esta cuenta ya está vinculada',
  pos_linking_err_link_request_already_linked_msg: '',
  pos_linking_err_link_request_expired_title: 'La sesión a expirado',
  pos_linking_err_link_request_expired_msg:
    'Por favor inicie de nuevo y vincule sus cuentas desde el Punto de Venta de nuevo.',
  pos_linking_err_link_request_general_title: 'Lo sentimos, hemos detectado un error inesperado',
  pos_linking_err_link_request_general_msg:
    'Vuelva a intentarlo y, si el problema persiste, póngase en contacto con el servicio de asistencia.',
  pos_access_request_pending_title: 'Acceso Pendiente',
  pos_access_request_pending_paragraph:
    'Comuníquese con el administrador del lugar para aceptar su nueva solicitud de acceso al lugar.',
  pos_access_request_rejected_title: 'Acceso Rechazado',
  pos_access_request_rejected_paragraph:
    'Su acceso a este lugar ha sido rechazado. Envíe una nueva solicitud y comuníquese con el administrador del lugar para obtener permisos para este lugar.',
  pos_access_request_submit: 'Enviar Nueva Solicitud',
  pos_access_request_sent_title: 'Solicitud Enviada',
  pos_access_request_sent_paragraph:
    'Su solicitud de acceso a este lugar ha sido enviada. Espere a que el administrador del lugar acepte su solicitud.',
  pos_access_request_submit_failure:
    'Ocurrió un error inesperado. Espere unos minutos y vuelva a intentarlo',
  wrong_time_format: 'Formato incorrecto, la hora debe ser horas: minutos AM/PM',
  yesterday: 'Ayer',
  access_venue: 'Lugar de acceso',
  access_venue_success_message: 'Se le ha otorgado acceso al lugar {{venueXRefID}}',
  access_user_success_message: '[ES] You are now viewing as user {{email}}',
  access_venue_session_error: 'No se puede inicializar la sesión',
  access_user_session_error: '[ES] Unable to mimic the user.',
  access_venue_error_message: 'ID del lugar: {{venueXRefID}} no existe',
  test_venue: 'Restaurante de Prueba',
  bulk_create_test_venue: '[ES] Bulk Create Test Venues',
  'bulk_create_test_venue.num': '[ES] Number of venues',
  'bulk_create_test_venue.num_create': '[ES] Number of venues to create',
  'bulk_create_test_venue.created': '[ES] Created Test Venues',
  'bulk_create_test_venue.created.copy_json': '[ES] Copy JSON',
  'bulk_create_test_venue.only_integers': '[ES] Only integers are permitted',
  'bulk_create_test_venue.min_error': '[ES] Cannot input a number below 1',
  'bulk_create_test_venue.max_error': '[ES] Cannot input a number over 20',
  'bulk_create_test_venue.range': '[ES] Input a number from 1-20.',
  'bulk_create_test_venue.dba_name.helper':
    '[ES] Select a dba name to identify your bulk created venues. E.g., dba name = Mockdonalds: Mockdonalds - 1, Mockdonalds - 2, etc.',
  create_test_venue: 'Crear Restaurante de Prueba',
  'create_test_venue.copy_venue_values':
    'Copie los valores de la sección Restaurante del formulario',
  'create_test_venue.venue': 'Restaurante',
  'edit_test_group.group': '[ES] Group',
  'create_test_venue.dbaName': 'Nombre',
  'create_test_venue.dbaName.required': 'Nombre es requerido',
  'create_test_venue.dbaName.min': '[ES] Venue name should contains at least 1 character',
  'create_test_venue.concept': 'Concepto',
  'create_test_venue.concept.required': 'Concepto es requerido',
  'create_test_venue.type': 'Tipo de restaurante',
  'create_test_venue.type.required': 'Tipo de restaurante es requerido',
  'create_test_venue.user': 'Usuario Principal',
  'create_test_venue.products': 'Productos',
  'create_test_venue.entitlements': 'Derechos / Servicios',
  edit_test_venue: '[ES]Edit Test Venue',
  edit_test_group: '[ES] Edit Test Group',
  'edit_test_venue.search': '[ES]Search for Test Venue By Venue ID',
  'edit_test_group.search': '[ES]Search for Test Groups By Group ID',
  'edit_test_venue.search.required': '[ES]VenueXRefID is required',
  'edit_test_group.search.required': '[ES]BaseXRefID is required',
  'edit_test_venue.search.error':
    '[ES]An error occured while fetching your test venue information: {{errorMsg}}',
  'edit_test_group.search.error':
    '[ES] An error occured while fetching your test base information: {{errorMsg}}',
  'edit_test_venue.search.failure': '[ES]Venue {{venueXRefID}} could not be found.',
  'edit_test_group.search.failure': '[ES] Group {{baseXRefID}} could not be found.',
  'edit_test_venue.submit.error':
    '[ES]An error occured while updating your test venue: {{errorMsg}}',
  'edit_test_group.submit.error':
    '[ES]An error occured while updating your test group: {{errorMsg}}',
  'edit_test_venue.submit.success': '[ES]You have successfully updated your test venue',
  'edit_test_group.submit.success': '[ES]You have successfully updated your test group',
  'edit_test_group.not_a_group.error':
    '[ES] Either the group does not exist or its a venue and not a group. If its a venue please use the Edit Venue Page',
  'edit_test_group.not_a_test_group.error':
    '[ES] Only Groups with test venues are allowed to be updated on Agent Access',
  create_test_venue_success_message: 'Ha creado con éxito su restaurante de prueba',
  create_test_venue_error_message: 'Ocurrió un error al crear tu restaurante de prueba',
  create_test_venue_error_corporation_not_found:
    '[ES]An error occured when creating your test venue: unable to find corporation',
  agent_text: 'Agente',
  agent_user_roles_update_success: '[ES] Roles have been successfully updated',
  device_type: 'Tipo de dispositivo',
  tb_lite: 'TB Lite',
  tb_pos: 'TB POS',
  enter_code_on_device: 'Ingresa el código siguiente en tu dispositivo:',
  code_expiration_notice: 'Este código es válido por 15 minutos.',
  label: 'Etiqueta',
  done: 'Listo',
  next: 'Próximo',
  failed_to_add_device: 'Error al agregar el dispositivo.',
  failed_to_edit_device: 'Error al editar dispositivo.',
  step_a_of_b: 'Paso {{step}} de {{totalSteps}}',
  discounts_and_voids: 'Descuentos y Cancelaciones',
  gratuity_service_charges: 'Propina y Cargos por Servicio',
  server_gratuity: 'Server Gratuity',
  gratuity_summary: 'Resumen de Propina',
  generate_report: 'Generar Reporte',
  heatmap_time_of_day: 'Mapa de Calor - Tiempo de día',
  bill_start_time: 'Inicio de la Cuenta',
  sales_dashboard: 'Tablero de Ventas',
  scheduled_reports: 'Reportes Programados',
  cash_deposits: '[ES] Cash Deposits',
  app_marketplace: 'Aplicaciones',
  layout_tools: 'HERRAMIENTAS DE DISEÑO',
  add_circ_tbl: 'Agregar tabla circular',
  add_sq_tbl: 'Agregar mesa cuadrada',
  add_rect_tbl: 'Agregar mesa rectangular',
  add_oval_tbl: '[ES]Add Oval Table',
  add_cash_register: 'Agregar caja registradora',
  add_bar_stool: 'Agregar taburete de bar',
  add_wall: 'Agregar pared',
  floorplan_item_properties: 'PROPIEDADES',
  floorplan_actions: 'ACCIONES',
  width: 'Ancho',
  height: 'Alto',
  length: 'Largo',
  x_coord: 'X',
  y_coord: 'Y',
  duplicate: 'Duplicar',
  copy: '[ES]copy',
  floorplan: 'Distribución',
  floorplan_editor: 'Editor de planos de planta',
  add_floor: 'Agregar planta',
  default_floor: 'Planta principal',
  dialog_title_add_floorplan: 'Agregar planta',
  dialog_floorplan_enter_name: '[ES]Enter a name for the new floor plan',
  dialog_floorplan_textfield: 'Nombre de planta',
  dialog_save_action: 'Crear planta',
  dialog_cancel_action: 'Cancelar',
  floorplan_err_floorplan_unique_constraint_error: 'Ya existe una distribución con este nombre',
  floorplan_err_section_unique_constraint_error: 'Ya existe una sección con este nombre',
  min_seats: 'Cantidad mínima de asientos',
  max_seats: 'Cantidad máxima de asientos',
  seats: '[ES]Seats',
  seat: '[ES]Seat',
  no_seats: '[ES]No Seats',
  floorplan_landing_empty_description:
    'Parece que no tienes una distribución configurada. Presiona la opción Agregar planta arriba para comenzar.',
  floorplan_landing_empty_alternative_image_text: 'personas sentadas en un restaurante',
  floorplan_landing_header_name: 'Nombre',
  floorplan_dialog_section_name: 'Nombre de la sección',
  floorplan_dialog_title_add_section: 'Crear sección',
  floorplan_dialog_create_section: 'Crear sección',
  floorplan_create_section_item: 'Secciones',
  floorplan_no_section: 'Sin sección',
  table: 'Mesa',
  table_color: 'Color de la Mesa',
  table_color_helper_text: 'A este miembro se le asignará este color de tabla en el POS',
  unnamed: '[ES]Unnamed',
  table_deleted: 'Se ha eliminado la mesa {{tableName}}.',
  wall_deleted: 'Se ha eliminado la pared {{wallName}}.',
  item_deleted: 'Se ha eliminado el elemento {{itemName}}.',
  floorplan_save_failed: '{{floorplanName}} no se pudo guardar.',
  floorplan_save_failed_retry: '{{floorplanName}} no se pudo guardar. Intenta nuevamente.',
  retry: 'Intentar nuevamente',
  floorplan_table_names_unique_error: 'Todos los nombres de las mesas deben ser únicos.',
  cash_register: 'CAJA REGISTRADORA',
  rotation: 'Rotación',
  floorplan_dialog_title_edit_section: 'Editar sección',
  floorplan_delete_floorplan_prompt: '¿Estás seguro de que quieres eliminar {{floorplanName}}?',
  floorplan_failed_to_delete_floorplan: 'Se produjo un error al eliminar la distribución.',
  floorplan_failed_undo_delete_table_error:
    'No se pudo deshacer la eliminación de la mesa {{tableName}}. Lo más probable es que hayas presionado Guardar, lo que finalizó la eliminación.',
  floorplan_failed_undo_delete_wall_error:
    'No se pudo deshacer la eliminación de la pared {{wallName}}. Lo más probable es que hayas presionado Guardar, lo que finalizó la eliminación.',
  floorplan_failed_undo_delete_item_error:
    'No se pudo deshacer la eliminación del elemento {{itemName}}. Lo más probable es que hayas presionado Guardar, lo que finalizó la eliminación.',
  bar_stool: '[ES]Bar Stool',
  floorplan_saved: '{{floorplanName}} se guardó con éxito.',
  floorplan_dialog_delete_section: 'Eliminar sección',
  floorplan_section_floorplan_prompt: '¿Estás seguro de que quieres eliminar {{sectionName}}?',
  floorplan_delete_section_information:
    'Todas las mesas asignadas a esta sección quedarán sin asignar.',
  floorplan_api_call_failed: 'Ocurrió un error inesperado.',
  floorplan_minimum_dimension: 'Mínimo {{min}}',
  floorplan_dialog_title_edit_floorplan_name: 'Editar nombre de planta',
  floorplan_table_align: 'ALINEAR',
  floorplan_is_not_migrated_description_1: '[ES]Action Required to Manage your Floor Plan in Cloud',
  floorplan_is_not_migrated_description_2:
    "[ES]Streamline your operations by making it easier and faster to edit floor plans. Cloud Floor Plan gives you the flexibility to build and manage your venue's floor plans from Cloud, while automatically pushing changes to the POS.",
  floorplan_is_not_migrated_description_3:
    '[ES]To access Cloud Floor Plan, you must complete these required steps:',
  floorplan_is_not_migrated_description_4: '[ES]Update to the latest version of TouchBistro POS',
  floorplan_is_not_migrated_description_5: '[ES]Perform end of day operations under Admin Options',
  floorplan_is_not_migrated_description_6: '[ES]Learn more about Cloud Floor Plan',
  onboarding_landing_title: 'Bienvenido a TouchBistro!',
  onboarding_landing_subtitle: 'Siga estos pasos para empezar a trabajar.',
  error_table_name_empty: '',
  error_table_name_duplicate: 'Ya existe una mesa con este nombre',
  error_max_seats: 'Límites máximos de asientos (24)',
  floorplan_dialog_title_table_name_duplicate:
    'Ya existe una mesa con este nombre para este lugar.',
  floorplan_dialog_content_name_duplicate: 'Ingresa un nombre de mesa único',
  floorplan_dialog_table_name_cancel: 'Cancel',
  floorplan_dialog_table_name_continue: 'Continuar',
  floorplan_discard_changes_prompt:
    'Tienes cambios sin guardar. ¿Estás seguro de que quieres continuar?',
  floorplan_discard_changes_continue: 'Descartar los cambios',
  floorplan_dialog_title_max_seats: 'Límites máximos de asientos',
  floorplan_dialog_content_max_seats: 'Por favor ingrese menos o igual a 24 asientos',
  floorplan_dialog_max_seats_continue: 'Continuar',
  floorplan_discard_changes_cancel: 'Permanecer en esta página',
  floorplan_dialog_swap_floorplan_title:
    'Tiene cambios sin guardar en el  {{ floorname }}. ¿Quieres guardar tus cambios antes de cambiar de piso?',
  floorplan_dialog_swap_floorplan_cancel_btn: 'Descartar cambios y continuar',
  floorplan_dialog_swap_floorplan_confirm_btn: 'Guardar y continuar',
  complete_payment_application: 'Complete su solicitud de pago.',
  set_up_your_menu: 'Configura tu menú.',
  set_up_online_ordering: 'Configure su pedido en línea.',
  set_up_floor_plan: 'Configura tu plano de planta.',
  fill_out_reservations: 'Complete su cuestionario de reservas.',
  link_devices: 'Vincula tus dispositivos.',
  lets_go: 'Vamos',
  floorplan_align_left: 'Alinear elementos a la izquierda',
  floorplan_align_right: 'Alinear elementos a la derecha',
  floorplan_align_top: 'Alinear elementos en la parte superior',
  floorplan_align_bottom: 'Alinear elementos en la parte inferior',
  floorplan_distribute_horizontal: 'Distribuir horizontalmente',
  floorplan_distribute_vertical: 'Distribuir verticalmente',
  floorplan_table_position_error:
    'Una o más mesas están fuera del plano del restaurante. Por favor mueva todas las mesas dentro del plano del restaurante antes de guardar.',
  floorplan_wall_position_error:
    'Una o más paredes están fuera del plano del restaurante. Por favor mueva todas las paredes dentro del plano del restaurante antes de guardar.',
  floorplan_table_wall_position_error:
    'Una o más mesas/paredes están fuera del plano del restaurante. Por favor mueva todas las mesas/paredes dentro del plano del restaurante antes de guardar.',
  staff: 'Personal',
  members: 'Miembros',
  number_of_users_allowed: 'Número de usuarios permitidos en su organización.',
  max_amount_of_users_reached:
    'Se alcanzó el número máximo de usuarios. Elimina usuarios para agregar más.',
  add_member: 'Agregar Miembro',
  add_staff_member: 'Agregar Miembro del Personal',
  add_staff_pos_button_text: 'Solo puede gestionar sus tareas diarias a través del TPV',
  add_staff_pos_and_cloud_button_text:
    'Puede ver/administrar sus tareas tanto en el POS como en la nube.',
  add_staff_pos: 'POS',
  add_staff_pos_and_cloud: 'POS + Cloud',
  add_staff_preferred_name: 'Nombre Preferido',
  add_staff_staff_discount: 'Descuento de empleados',
  add_staff_pincode: 'Código PIN',
  add_staff_pincode_notice:
    'Utilice este PIN generado para iniciar sesión en el POS. Genere un nuevo PIN si olvidó o perdió el anterior.',
  add_staff_pincode_button_text: 'Generar PIN',
  add_staff_show_pincode_button_text: 'Mostrar PIN',
  staff_generate_qr_code_title: '[ES]QR Code',
  staff_generate_qr_code_button: '[ES]Generate QR Code',
  staff_generate_qr_code_subtitle:
    '[ES]Generating a new QR code will automatically replace the previous one. A lost QR code cannot be reprinted.',
  staff_generate_qr_code_dialog_text:
    '[ES]This QR Code can be scanned by the POS. It grants access based on the staff members current permissions, and works similar to a PIN Code.',
  staff_qr_code_warning:
    "[ES]This QR Code will be lost once this modal is closed. Make sure you've finished any desired actions prior to closing the modal.",
  staff_print_qr_code: '[ES]Print QR Code',
  add_staff_text_validation_50: 'Solo se permiten 50 caracteres',
  staff_discount_value_range: 'Solo se permiten descuentos entre el 0% y el 100%.',
  edit_staff_member: 'Editar miembro del personal',
  staff_update_success: 'El usuario fue actualizado con éxito',
  staff_update_unchanged: 'No hay cambios para el usuario.',
  invenue_staff_email_disclaimer: 'Once saved, this email cannot be changed later',
  approve_access_request: 'Aprobar miembro del personal',
  approve_nickname_change_request: '[ES] Approve Nickname Change',
  user_request_save: 'Guardar y aprobar',
  access_request_basic_info:
    '{{requester}} está solicitando acceso a {{venueName}} en la nube. Asigne un rol a su solicitud.',
  decline_user_request: 'Rechazar solicitud',
  sure_decline_user_request: '¿Está seguro de que desea rechazar esta solicitud?',
  sure_decline_user_request_message:
    'Rechazar esta solicitud no impide que los miembros presenten nuevas solicitudes en el futuro.',
  access_request_has_been_rejected:
    'Solicitud de acceso para el usuario {{firstName}} {{lastName}} ({{email}}) ha sido rechazada',
  access_request_has_been_approved:
    'Solicitud de acceso para el usuario {{firstName}} {{lastName}} ({{email}}) ha sido aprovado',
  nickname_change_request_has_been_approved:
    '[ES] Nickname change Request for user {{firstName}} {{lastName}} ({{email}}) has been approved',
  nickname_change_request_has_been_rejected:
    '[ES] Nickname change Request for user {{firstName}} {{lastName}} ({{email}}) has been rejected',
  role: 'Función',
  unknown_role: 'Unknown Role',
  'staff.roles': 'Posiciones',
  'staff.roles.add_role': '[ES] Add Role',
  'staff.roles.role_information': '[ES] Role Information',
  'staff.roles.field.name': '[ES] Role Name',
  'staff.roles.field.name.blurb.create': '[ES] The name that will be displayed for your role.',
  'staff.roles.field.name.blurb.edit':
    '[ES] The name that will be displayed for your role. Note: Changes may impact 3rd party integrations.',
  'staff.roles.field.description': '[ES] Role Description',
  'staff.roles.field.description.blurb':
    '[ES] Your description will be displayed during the role selection process',
  'staff.roles.field.code': '[ES] Role Code',
  'staff.roles.field.assign_venues': '[ES] Assign Venues',
  'staff.roles.field.pay_type': '[ES] Pay Type',
  'staff.roles.field.default_hourly_wage': '[ES] Default Hourly Wage',
  'staff.roles.text_validation_50': '[ES] Only 50 characters allowed',
  'staff.roles.duplicate_role_name_title': '[ES] The role name you entered is already in use',
  'staff.roles.duplicate_role_name_message':
    '[ES] The role name you entered is already in use by another role. You can either continue with this name or choose a unique one to avoid potential confusion.',
  'staff.roles.duplicate_role_name_gotit': '[ES] Got It',
  'staff.roles.duplicate_permissions_title':
    '[ES] Permissions for this role are already included in an existing role. Are you sure you want to create a new one?',
  'staff.roles.duplicate_permissions_message':
    "[ES] The permissions you've enabled exactly match the {{existingRoleName}} role. Consider modifying the permissions to avoid creating duplicates or continue creating the role.",
  'staff.roles.duplicate_permissions_create': '[ES] Create new role',
  'staff.roles.create.success_message': '[ES] {{roleName}} role created',
  'staff.roles.delete.success_message': '[ES] {{roleName}} role deleted',
  'staff.roles.restore.success_message': '[ES] {{roleName}} role has been restored to default',
  'staff.roles.update.success_message': '[ES] {{roleName}} role updated',
  'staff.roles.delete.confirm.title': '[ES] Are you sure you want to delete the {{roleName}} role?',
  'staff.roles.delete.confirm.message':
    '[ES] You will no longer be able to assign this role and its permissions to existing staff members.',
  'staff.roles.restore.confirm.message':
    '[ES] This role will be restored to its original name and permissions.',
  'staff.roles.delete.error.inUse.title':
    '[ES] Unable to delete the {{roleName}} role because it`s currently in use',
  'staff.roles.delete.error.inUse.message':
    '[ES] To delete {{roleName}}, you must remove it from your existing staff members. These members include: {{users}} and more.',
  'staff.roles.restore.confirm.title': '[ES] Are you sure you want to restore to default?',
  entity_type: 'Tipo de entidad',
  root_roles_warning: '[ES] Only displaying roles at the root group level',
  legacy_account_warning: '[ES] Members assigned this symbol are currently using a legacy account',
  base_xref_id: 'BaseXRefID',
  policy_xref_id: 'PolicyXRefID',
  role_xref_id: 'RoleXRefID',
  venue: 'Lugar',
  organization: 'Organización',
  'organization.legal_business_name': 'Nombre Comercial Legal',
  'organization.business_phone': 'Teléfono de la Organización',
  team: 'Equipo',
  last_updated: 'Last Updated',
  'staff.first_name': 'Nombre',
  'staff.last_name': 'Apellido',
  permission_type: '[ES] Permission Type',
  phone_number: 'Número de Teléfono',
  email_address: 'Dirección de correo electrónico',
  staff_discount: 'Descuento de empleados',
  confirm_email_address: 'Confirma la dirección de correo electrónico',
  emails_dont_match: 'Los correos electrónicos no coinciden',
  receipt_nickname: 'Apodo del recibo (Opcional)',
  receipt_nickname_tooltip: 'Este apodo se mostrará en el recibo.',
  remove_role: 'Quitar función',
  pending: 'Pendiente',
  completed: '[ES] Completed',
  failed: '[ES] Failed',
  in_venues: '[ES] In Venues',
  online: '[ES] Online',
  verified: 'Verificado',
  awaiting_approval: 'Esperando Aprobacion',
  inactive: 'Inactivo',
  active: 'Activo',
  selected: 'Seleccionado',
  selected_numbers: 'Seleccionado ({{ num }})',
  select_a_location: 'Select a location',
  others: 'Otros',
  others_numbers: 'Otros ({{ num }})',
  roles_and_venues_title: 'Funciones y lugares',
  roles_and_venues_step1_label: '[ES]Step 1',
  roles_and_venues_step2_label: '[ES]Step 2',
  roles_and_venues_step3_label: '[ES]Step 3 (Optional)',
  roles_and_venues_step1_text:
    '[ES]Select a role that best describes the members day-to-day work. Roles control their level of access to the POS and Cloud.',
  roles_and_venues_step2_text:
    '[ES]Select the venues where the staff member should be assigned this role. Certain roles may be limited to specific venues.',
  roles_and_venues_step3_text:
    '[ES]Edit pay if you’d like to include it in your labour report. You can assign the same pay for all the venues or edit pay on a venue level.',
  roles_and_venues_pay_type_label: '[ES]Pay Type',
  roles_and_venues_pay_type_hourly: '[ES]Hourly',
  roles_and_venues_select_pay_type: '[ES]Select a pay type',
  roles_and_venues_pay_amount_label: '[ES]Hourly Rate',
  roles_and_venues_apply_to_all_venues: '[ES]Apply the same pay across the selected venues',
  select_venue: 'Seleccione un lugar',
  select_user_role: 'Selecciona la función de este usuario y a qué lugares pueden acceder.',
  assign_a_role_for_venue:
    'Para asignar una función a un lugar, selecciona la casilla de verificación para un lugar. Para eliminar la asignación, desmarca la casilla de un lugar.',
  admin: 'Administrador(a)',
  admin_role:
    'La función del administrador ofrece permisos completos para todos los lugares dentro de la organización.',
  admin_role_desc: 'Permisos completos para todos los lugares actuales y futuross',
  manager: 'Gerente',
  manager_role_desc: 'Permisos completos para los lugares a los que el usuario tiene acceso',
  kitchen_staff: 'Kitchen Staff',
  kitchen_staff_role_desc: 'Back of house',
  controller: 'Controlador(a)',
  controller_role_desc: 'Permisos completos solo para el portal de pago',
  current_controller: 'Current Controller',
  controller_permissions: 'Permisos de controlador',
  controller_permissions_info:
    'Este usuario tiene permisos de controlador. No podrá eliminar a este usuario hasta que se haya transferido el permiso.',
  controller_permissions_title: 'Asignar permisos de controlador a un miembro',
  controller_permissions_description:
    'Solo el controlador tendrá acceso al Portal de Pagos de TouchBistro y podrá realizar actividades como cambiar la cuenta bancaria asociada a TouchBistro Payments, ver los informes de pago de tu establecimiento y realizar cambios en tu configuración de pago. El controlador debe completar el proceso de adjudicación.',
  controller_permissions_minimum_info: 'Cada establecimiento debe tener asignado un controlador.',
  controller_permissions_current: 'Controlador actual',
  controller_permissions_select_member: 'Selecciona un miembro',
  controller_permissions_manager_warning_title:
    'Esta página solo está disponible para administradores y controladores',
  controller_permissions_manager_warning_text:
    'Por este momento, la asignación de permisos de controlador es exclusiva para administradores y controladores.',
  controller_permissions_venue_warning_title:
    'Este local no tiene acceso al Portal de Pagos de TouchBistro.',
  controller_permissions_venue_warning_text:
    'No se pueden asignar permisos de controlador a este lugar ya que no tiene acceso al Portal de Pagos de TouchBistro',
  controller_reassigned: 'Se han actualizado los permisos de controlador',
  controller_transfer_modal_title:
    '¿Estás seguro de que deseas asignar a este miembro controlador permissions?',
  controller_transfer_modal_text:
    'Un establecimiento solo puede tener un controlador. El nuevo miembro asignado será el único que podrá acceder al Portal de Pagos del establecimiento asignado. El controlador actual perderá el acceso y habrá que readjudicar al nuevo controlador. Se enviará un correo electrónico de confirmación con instrucciones al nuevo controlador.',
  controller_transfer_modal_cancel_btn: 'Ahora no',
  controller_transfer_modal_assign_btn: 'Asignar como controlador',
  controller_role_info:
    'Controllers will be responsible for payment related tasks for the business and only those assigned to a controller role will be allowed in the payment portal. Ensure you assign this role to a trusted member as they will be responsible for the financial decisions for the business. Each venue must have at least 1 controller role assigned.',
  assistant_manager: 'Asistente del Gerente',
  assistant_manager_role_desc: 'Permisos parciales a POS',
  guest_engagement: 'Interacción con los clientes',
  guest_engagement_role_desc: 'Permisos completos para las páginas de Guest Engagement',
  analyst: 'Analista',
  analyst_role_desc: 'Permisos completos solo para la página de informes',
  menu_editor: 'Editor(a) de menús',
  menu_editor_role_desc: 'Permisos completos solo para la página del menú',
  cashier: 'Cajero(a)',
  cashier_role_desc: 'Permisos parciales a POS',
  server: 'Servidor',
  server_role_desc: 'Permisos parciales a POS',
  venues: 'Sedes',
  users: 'Usuarios y usuarias',
  add: 'Agregar',
  add_another_role: 'Agregar otra función',
  add_member_info:
    'Puedes asignar un rol a un miembro y agregarlo a un establecimiento o eliminarlo de allí simplemente marcando y desmarcando la casilla de verificación de cada establecimiento.<br /><br />No olvides guardar tus cambios.',
  deactivated: 'Desactivado',
  staff_member: 'Miembro del personal',
  staff_members: 'Miembros del personal',
  changes_saved: 'Se guardaron los cambios a {{firstName}} {{lastName}} ({{email}})',
  invite_sent: 'Se envió la invitación a {{email}}',
  invite_resent: 'Se reenvió la invitación a {{email}}',
  invite_removed: 'Se ha eliminado la invitación enviada a {{email}}',
  remove_member: 'Quitar Miembro',
  delete_member: 'Eliminar miembro',
  are_you_sure: '¿Estás seguro?',
  remove_member_error: 'El miembro no puede ser eliminado.',
  remove_member_prompt_title: '¿Estás seguro de que deseas eliminar el miembro?',
  remove_member_prompt_body:
    'Please note that the user will be removed. You can always add the member back.',
  sure_remove_member_from_org:
    '¿Estás seguro de que deseas eliminar el miembro de tu organización?',
  sure_remove_member_from_venues:
    '¿Estás seguro de que deseas quitar al miembro de todos los lugares?',
  sure_save_invenue_email_title: 'Are you sure you want to save these changes?',
  sure_save_invenue_email_message:
    'Please check and ensure you have the correct email address for the staff member. You cannot change the email address for this member later.',
  requires_cloud_access:
    'Since this role requires Cloud access, the access type will be changed to "POS + Cloud" (Email address will be mandatory). Are you okay with the change?',
  strip_member_of_roles:
    'Esta acción removerá al miembro de todos los roles de los lugares vinculados.',
  strip_member_of_roles_delete_from_org:
    'Esta acción removerá al miembro de todas las funciones de los lugares vinculados y eliminará al miembro de tu organización.',
  invite_already_sent: '{{email}} ya ha sido invitado/a',
  user_has_been_deleted: 'Se eliminó el nombre de usuario {{firstName}} {{lastName}} ({{email}})',
  user_management_available: '¡Ya está disponible la gestión del usuario!',
  create_cloud_logins_for_staff:
    'Crea inicios de sesión individuales en la nube de TouchBistro para tu personal y establece distintos niveles de permisos para cada usuario. Haz clic en Staff (Personal) para comenzar o leer nuestra guía de ayuda.',
  dismiss: 'Descartar',
  email_resent: 'Email has been resent.',
  'staff.unexpected_error': 'Ocurrió un error inesperado. Intentanuevamente.',
  'staff.admin_role_confirmed': 'Tu cuenta se ha confirmado correctamente.',
  min_controller_role_title: 'Asignar un rol de controlador',
  min_controller_role_body:
    'Tendrás que asignar un rol de controlador para este establecimiento para acceder al Portal de Pagos y realizar cambios en cualquier configuración de pago. Asigna un miembro existente en la página de permisos de controlador.',
  multi_unit_modal_title:
    'Confirma que posees la primera cuenta de Administrador para {{organization}}',
  multi_unit_modal_text:
    '<2>Antes de que puedas comenzar a usar User Management (Gestión de usuarios) e invitar a los miembros del equipo a configurarsus propias cuentas en la nube, necesitamos que confirmes que tienes el derecho y permiso necesariospara gestionar la organization primera cuenta de Administrador para {{organization}}.<1></1> Si no tienes permiso para gestionar la primera cuenta de Administrador para {{organization}}, NO continúes.<1></1> Al marcar la casilla a continuación, declaras ante TouchBistro que tienes el derecho y permiso para poseer la primera cuenta de Administrador para {{organization}}.</2>',
  you: 'Tú',
  archived: 'Archivado',
  legacy: 'Legado',
  leave_page: '[ES] Leave Page',
  your_organization: 'tu organización',
  user_will_have_full_permissions:
    'El usuario tendrá permisos como administrador para todos las funcionalidades.',
  pagination_info: 'Mostrando de {{from}} a {{to}} de {{total}} entradas',
  legacy_modal_title:
    '¡La gestión de usuarios ya está disponible! Crea una cuenta de Administrador.',
  legacy_modal_text_step_1:
    'Crea cuentas individuales de TouchBistro en la nube para tu personal y establece distintos niveles de permisos para cada usuario. <br /><br /> Para comenzar a usar esta herramienta, debes crear la primera cuenta de Administrador para {{corporationName}}. El Administrador será quien tenga la mayor autoridad en {{corporationName}}, lo cual incluye la capacidad para invitar a otros usuarios a configurar sus propias cuentas en la nube y establecer niveles de acceso individualmente. El Administrador también puede otorgar a otros usuarios acceso como Administrador. <br /><br /> Antes de continuar, debemos confirmar que tienes los derechos y el permiso necesarios para crear la primera cuenta de Administrador para {{corporationName}}. <br /><br /> Si no tienes permiso para determinar quién debe ser Administrador de {{corporationName}}, ignora este mensaje y NO configures ningún usuario como Administrador. <br /><br /> Al marcar el casillero que aparece a continuación, declaras a TouchBistro que tienes derecho a crear la primera cuenta de Administrador para {{corporationName}}.',
  legacy_modal_text_step_2:
    'Confirmaste que tienes permiso para crear la primera cuenta de Administrador para {{corporationName}}.',
  legacy_modal_text_step_3:
    'Ingresa la dirección de correo electrónico del Administrador designado a continuación. Al proporcionar esta información, el Administrador recibirá un correo electrónico de bienvenida en breve con instrucciones sobre cómo configurar la cuenta de Administrador.',
  legacy_modal_terms_conditions:
    '<h4>Confirmación del Acceso como Administrador</h4><p>De conformidad con nuestra comunicación anterior en relación con la funcionalidad de gestión de usuarios de TouchBistro para la Cuenta, aceptas que se te otorgue acceso a la Cuenta como Administrador (en adelante, el “<b>Acceso como Administrador</b>”). El Acceso como Administrador es una herramienta poderosa que permite al beneficiario utilizar funciones en relación con todos los aspectos de la nube de TouchBistro asociados con la Cuenta, lo cual incluye, a mero título enunciativo, lo siguiente:</p><ul><li>Acceder a información de todos los lugares asociados con la Cuenta, visualizarla y editarla.</li><li>Otorgar al personal y a otros miembros del equipo acceso a la Cuenta.</li><li>Asignar los roles, junto con sus correspondientes niveles de acceso, a los miembros del equipo en uno o más lugares de la Cuenta.</li><li>Realizar cualquier cambio en la Cuenta, lo cual incluye la incorporación de personas adicionales con Acceso como Administrador y la modificación de personas con Acceso como Administrador a la Cuenta.</li></ul><p>Al aceptar este Acuerdo, realizas las siguientes declaraciones ante TouchBistro y comprendes que TouchBistro se basará en ellas:</p><ul><li>Tienes el derecho, la facultad y la autoridad necesarios para gestionar la Cuenta, lo cual incluye otorgar el Acceso como Administrador como se contempla en este documento, y para celebrar este Acuerdo.</li><li>Toda la información establecida en este Acuerdo es correcta y completa a la fecha de tu aceptación.</li><li>Indemnizarás y mantendrás indemne a TouchBistro de todo reclamo que indique que no tenías autoridad para otorgar el Acceso como Administrador como se contempla en este Acuerdo.</li></ul>',
  legacy_modal_checkbox_label: 'Confirmo que la declaración anterior es correcta.',
  legacy_modal_disclaimer:
    'Si no tienes permiso para determinar quién debe ser Administrador de {{corporationName}}, ignora este mensaje y NO configures ningún usuario como Administrador.',
  legacy_modal_success_title: '[ES] {{email}} is Now an Admin at {{venueName}}',
  legacy_modal_success_text:
    '[ES] <1>{{email}}</1> now has admin access for <1>{{corporationName}}</1> including <1>{{venueName}}</1>. Please log out and sign in using <1>{{email}}</1>.',
  signup_reminder_modal_submits_single_email_title: 'An Email Has Been Sent To {{email}}',
  signup_reminder_modal_submits_multiple_email_title: 'An Email Has Been Sent',
  signup_reminder_modal_submits_single_email_text:
    "We've sent you an email to complete your AppRegistration. Once completed, please log in with your new account.",
  signup_reminder_modal_submits_multiple_email_text:
    "We've sent an email to <1>{{email}}</1> so they can complete their registration. Once completed, please have them log in with their new account.",
  role_select_instruction:
    'Puedes asignarle una función diferente y agregarlos o eliminarlos del lugar con solo seleccionar y deseleccionar la casilla deverificación para cada lugar. No olvides guardar tus cambios.',
  corporations: 'Empresas Corporativas',
  select_corporation: 'Seleccione una Empresas Corporativas',
  diagnostics: 'Diagnóstico',
  info_user_actions:
    'To resend or cancel an invite for a user, please click the three dots beside their name and select your desired action.',
  cancel_invite: 'Cancel Invite',
  cancel_invite_prompt_title: 'Are you sure you want to cancel the invite?',
  cancel_invite_prompt_body:
    'Please note that the invite will be cancelled. You can always re-send an invite to this user.',
  resend_invitation: 'Reenviar invitación',
  basic_information: 'Información básica',
  manage_account_subtitle: 'Volver a vincular dispositivo',
  manage_account_details: 'Gestionar detalles de la cuenta',
  manage_account_details_description:
    'Usa este gestionador de detalles de la cuenta para volver a vincular tus dispositivos cuando ocurran interrupciones entre tu POS y la Nube',
  manage_account_details_username_label: 'Gestionar nombre de usuario',
  manage_account_details_password_label: 'Gestionar contraseña',
  manage_account_details_password_helper_text:
    'Generar una nueva contraseña si olvidaste tu contraseña',
  manage_account_agent_helper_text:
    'Los agentes no pueden ver esta contraseña, solo los administradores y gerentes de esta cuenta pueden generar una nueva contraseña',
  manage_account_details_password_generate_button: 'Generar contraseña',
  manage_retrieve_account_details_error: 'No se pudieron recuperar los ajustes del dispositivo',
  wall_color: 'Color de la pared',
  floorplan_properties_multiple: 'Múltiple',
  floorplan_orientation_portrait: 'Retrato',
  floorplan_orientation_landscape: 'Paisaje',
  venue_settings: '[ES]Venue Settings',
  venue_list: 'Venue List',
  group_management_tool: '[ES]Group Management Tool',
  group_management: 'Administración de Grupo',
  add_group: 'Agregar Grupo',
  format_currency: '{{value, formatCurrency}}',
  infinite_scroll_loading_error: '[ES]Next page of data could not be fetched while scrolling',
  floorplan_add_color: 'Agregar color',
  cloud_menu_payload: '[ES] Cloud Menu Payload',
  'lite.unassigned': '[ES]Unassigned',
  'lite.signup.h1': '[ES]Welcome!',
  'lite.signup.h2': '[ES]Let’s sign up and get to know you.',
  'lite.signup.input.first_name.label': '[ES]First Name',
  'lite.signup.input.first_name.error.required': '[ES]First Name is required',
  'lite.signup.input.last_name.label': '[ES]Last Name',
  'lite.signup.input.last_name.error.required': '[ES]Last Name is required',
  'lite.signup.input.email.label': '[ES]Email',
  'lite.signup.input.email.error.required': '[ES]Email is required',
  'lite.signup.input.password.label': '[ES]Password',
  'lite.signup.input.password.error.required': '[ES]Password is required',
  'lite.signup.input.password.error.requirements': '[ES]Missing password requirements below',
  'lite.signup.input.password.error.lowercase': '[ES]Contains an lowercase letter',
  'lite.signup.input.password.error.uppercase': '[ES]Contains an uppercase letter',
  'lite.signup.input.password.error.number': '[ES]Contains a number',
  'lite.signup.input.password.error.length': '[ES]Contains at least 6 characters',
  'lite.signup.input.password.error.matches': '[ES]Passwords must match',
  'lite.signup.input.confirm_password.label': '[ES]Confirm password',
  'lite.signup.terms_agree': '[ES]I agree to the',
  'lite.signup.terms_and': '[ES] and',
  'lite.signup.terms_link': '#',
  'lite.signup.terms_text': '[ES] Terms and Conditions',
  'lite.signup.terms_contract':
    "Missing context! Example: 't('translation key', { context: '20210511' })'",
  'lite.signup.terms_contract_20210511': ['[ES]'],
  'lite.signup.policy_link': '#',
  'lite.signup.policy_text': '[ES] Privacy Policy',
  'lite.signup.policy_contract':
    "Missing context! Example: 't('translation key', { context: '20210511' })'",
  'lite.signup.policy_contract_20210511': ['[ES]'],
  'lite.signup.submit_button': 'Next',
  'lite.recaptcha_error': '[ES]Invalid captcha',
  'lite.unexpected_error': '[ES]Server error',
  'lite.signup.success.title': '[ES]Please verify your email',
  'lite.signup.success.text_1':
    "[ES]Thanks for signing up! We've sent an email to {{email}}, where you'll need to follow the instructions to confirm your email address. Please complete this step within [x hours] of receiving the email.",
  'lite.signup.success.text_2':
    '[ES]If you do not receive it after [x minutes], please check your spam folder or',
  'lite.signup.success.send_email': '[ES]click here to resend the email.',
  'lite.menus': '[ES]Menus',
  'lite.menus.more': '[ES]more',
  'lite.menus.no_results_found': '[ES]No results found for',
  'lite.menus.heading.text': '[ES]Menus',
  'lite.menu.no-menues-available': '[ES]No Menus Available',
  'lite.menu.add.new': '[ES]Add menu',
  'lite.menu.edit.heading.text': '[ES]Edit Menu',
  'lite.menu.create.name.error.required': '[ES]Name is required',
  'lite.menu.create_your_menus': '[ES]Create Your Menu',
  'lite.menu.create_your_menus_description':
    '[ES]Enjoy the flexibility of creating one or multiple menus to best serve your guests. Think breakfast, lunch and dinner. Or, just one for all! Create your choice. \n\n Click “Add Menu” at the top right when you’re ready to start creating your menu.',
  'lite.menu.louisa_cafe': "[ES]louisa's cafe",
  'lite.menu.dinner_menu': '[ES]DINNER MENU',
  'lite.menu.you_are_here': '[ES]YOU ARE HERE',
  'lite.menu.salads_option': '[ES]salads',
  'lite.menu.shortened_protein_choice': '[ES]+ protein choice',
  'lite.menu.kale_option': '[ES]KALE $13',
  'lite.menu.kale_option_description':
    '[ES]Massaged kale, olive oil, pine nuts, grapes, lemon, topped with parmesan cheese.',
  'lite.menu.taco_salad_option': '[ES]TACO SALAD $13',
  'lite.menu.taco_salad_option_description':
    '[ES]Pico de gallo, beans, peppers, onions, romaine lettuce, tortilla chips, and guacamole.',
  'lite.menu.barley_berries_option': '[ES]BARLEY + BERRIES $15',
  'lite.menu.barley_berries_option_description':
    '[ES]Spring mix, barley, strawberries, blueberries, blackberries, goat cheese, walnuts, balsamic dressing.',
  'lite.menu.protein_choice_header': '[ES]protein choices',
  'lite.menu.alternate_protein_choice': '[ES]Egg, tofu or beef',
  'lite.menu.uppercase_m_modifier': '[ES]Modifiers',
  'lite.menu.delete.confirmation_question': '[ES]Do you want to delete',
  'lite.menu.back_button_cta': '[ES]Back to Menus',
  'lite.menu.add_to_menu_group': '[ES]Add to Menu Group',
  'lite.menu.search_placeholder': '[ES]Search menus',
  'lite.menu.menu_group': '[ES]Menu Group',
  'lite.menu.menu_groups': '[ES]Menu Groups',
  'lite.menu.menu_groups.back': '[ES]Back to Menu Groups',
  'lite.menu.menu_group.delete_group': '[ES]Delete Group',
  'lite.menu.menu_group.add': '[ES]Add a Menu Group',
  'lite.menu.menu_group.add2': '[ES]Add Menu Group',
  'lite.menu.menu_group.edit': '[ES]Edit Menu Group',
  'lite.menu.menu_group.create.name_label': '[ES]Menu Group Name',
  'lite.menu.menu_group.create.name_helper':
    '[ES]Your menu group name can be displayed to your staff and customers.',
  'lite.menu.menu_group.create.name_required': '[ES]Menu group name is required',
  'lite.menu.menu_group.create.description_label': '[ES]Menu Group Description',
  'lite.menu.menu_group.info':
    '[ES]A menu group is used to organize your menu items. Example: Pizzas is a menu group for cheese pizza (menu item) and pepperoni pizza (menu item).',
  'lite.menu.menu_group.create_your_menu_group_header': '[ES]Create your Menu Groups',
  'lite.menu.menu_group.create_your_menu_group':
    '[ES]Make your menu easy to read by organizing menu items into categories such as appetizers, mains, and desserts. \n\n Click “Add Menu Group” at the top when you’re ready to start creating your menu groups.',
  'lite.menu.menu_group_details': '[ES]Menu Group Details',
  'lite.menu.menu_group_menu.subheader':
    '[ES] Attach this menu group to your menu. For example, add Salads to Dinner Menu.',
  'lite.menu.menu_group_section.input.helper': '[ES]Search existing menu groups',
  'lite.menu.menu_group_section.create_button_cta': '[ES]Create New Menu Group',
  'lite.menu.menu_item.add': '[ES]Add Menu Item',
  'lite.menu.menu_item.add_description':
    '[ES]Add as many items as you would like. When ready, you can click save to save all newly created menu items. ',
  'lite.menu.menu_item.edit': '[ES]Edit Menu Item',
  'lite.menu.menu_item.delete': '[ES]Delete Menu Item',
  'lite.menu.menu_item.back': '[ES]Back to Menu Items',
  'lite.menu.menu_item.details': '[ES]Menu Item Details',
  'lite.menu.menu_item.details.cost.validation-error': "[ES]Cost can't be negative!",
  'lite.menu.menu_item.delete.success': "[ES]'{{label}}' has been deleted from your menu items",
  'lite.menu.menu_item.input.cost_label': '[ES]Menu Item cost',
  'lite.menu.menu_item.create_your_menu_items': '[ES]Create your Menu Items',
  'lite.menu.menu_item.create_your_menu_items_description':
    "[ES]What's a menu without its items? This is where you add the dishes that make your restaurant special. \n\n Click “Add Menu Item” at the top when you’re ready to start creating your menu items.",
  'lite.menu.menu_item.input.name_required': '[ES]Menu item name is required!',
  'lite.menu.menu_item.input.sales-category_required': '[ES]Sales category is required!',
  'lite.menu.menu_items_section.subheader':
    '[ES] Attach menu items to your menu group. For example, add Kale Salad to Salads.',
  'lite.menu.menu_items_section.input.helper': '[ES]Search existing menu items',
  'lite.menu.menu_item.attach': '[ES]Attach Menu Item To',
  'lite.menu.schedule_section.label.always': '[ES] Opening Hours',
  'lite.menu.schedule_section.label.weekly': '[ES] Custom Hours',
  'lite.menu.schedule_section.days.error_helper':
    '[ES]Please select the day(s) your menu is available.',
  'lite.menu.delete_menu': '[ES]Delete Menu',
  'lite.menu.delete_modal.title': "[ES]Are you sure you want to delete the menu named '{{label}}'?",
  'lite.hide.menu': '[ES]Hide Menu',
  'lite.menu.add_sales_category': '[ES]Add Sales Category',
  'lite.menu.replacement_sales_category_is_required': '[ES]Replacement Sales category is required',
  'lite.menu.sales_category_is_required': '[ES]Sales category is required',
  'lite.menu.no-sales-categories-available': '[ES]No Sales Categories Available',
  'lite.menu.sales_category.delete.success':
    "[ES]'{{label}}' has been deleted from your sales categories",
  'lite.menu.sales_category.err_delete_last':
    '[ES]Cannot Delete. Your Menu must have at least one Sales Category',
  'lite.menu.sales_category.err_get_list':
    '[ES]There has been an error while getting sales categories',
  'lite.menu_group.menu_groups': '[ES]Menu Groups',
  'lite.menu_group.info':
    '[ES]A menu group is used to organize your menu items.<br />Example: <b>Pizzas</b> is a menu group for cheese pizza (menu item) and pepperoni pizza (menu item).',
  'lite.menu_group.add_button_cta': '[ES]Add Menu Group',
  'lite.menu_group.search.placeholder': '[ES]Search menu groups',
  'lite.menu_group.not_available': '[ES]No menu groups available',
  'lite.menu_group.menu_input_helper': '[ES]Search existing menus',
  'lite.menus.not_available': '[ES]No menus available',
  'lite.menu_group.delete_modal.title':
    "[ES]Are you sure you want to delete the menu group named '{{label}}'?",
  'lite.menu.delete_menu_group': '[ES]Delete Menu Group',
  'lite.menu_items': 'Menu Items',
  'lite.menu_items.not_available': '[ES]No menu items available',
  'lite.menu_items.search.placeholder': '[ES]Search menu items',
  'lite.menu_items.delete_modal.title':
    "[ES]Are you sure you want to delete the menu item named '{{label}}'?",
  'lite.menu.delete_menu_item': '[ES]Delete Menu Item',
  'lite.menu.daily': 'Daily',
  'lite.menu.weekends': 'Weekends',
  'lite.menu.weekdays': 'Weekdays',
  'lite.menu.days': 'Days',
  'lite.menu.hours': 'Hours',
  'lite.menu.starting_from': 'Starting from',
  'lite.menu.up_until': 'Up until',
  'lite.menu.modifier_group': '[ES]Modifier Group',
  'lite.menu.modifier_group.add': '[ES]Add Modifier Group',
  'lite.menu.modifier_group.edit': '[ES]Edit Modifier Group',
  'lite.menu.modifier_group.back': '[ES]Back to Modifier Groups',
  'lite.menu.modifier_group.details': '[ES]Modifier Group Details',
  'lite.menu.modifier_group.create_your_modifiers': '[ES]Create your Modifiers',
  'lite.menu.modifier_group.create_your_modifiers_description_1':
    '[ES]\nMake things simple by organizing your modifiers in groups like sides, upgrades, and toppings.\n\n',
  'lite.menu.modifier_group.create_your_modifiers_description_2':
    '[ES]\nGive your guests the flexibility to personalize their orders with modifiers, while potentially getting them to spend more for the night. \n\n Click “Add Modifier” at the top when you’re ready to start creating your modifier groups or modifiers.',
  'lite.menu.modifier_group.info': '[ES]Modifier Group Info',
  'lite.menu.modifier_group.name': '[ES]Modifier Group Name',
  'lite.menu.modifier_group.name.input_helper':
    '[ES]Your modifier group name can be displayed to your staff and customers.',
  'lite.menu.modifier_group.search_placeholder': '[ES] Search modifier groups',
  'lite.menu.modifier_group.search.input_helper':
    '[ES]Choose the menu items this modifier group will be assigned to',
  'lite.menu.modifier_group.name.required': '[ES]Modifier group name is required',
  'lite.menu.modifier_group.minimum.input': '[ES]Minimum Modifiers',
  'lite.menu.modifier_group.minimum.input_helper':
    '[ES]This is the minimum amount of modifier selections your customers can choose.',
  'lite.menu.modifier_group.minimum.min_error': "[ES]Minimum modifiers can't be less than zero",
  'lite.menu.modifier_group.minimum.max_error':
    "[ES]Minimum modifiers can't be more than maximum modifiers",
  'lite.menu.modifier_group.maximum.input': '[ES]Maximum Modifiers',
  'lite.menu.modifier_group.maximum.input_helper':
    '[ES]This is the maximum amount of modifier selections your customers can choose.',
  'lite.menu.modifier_group.maximum.min_error':
    "[ES]Maximum modifiers can't be less than minimum modifiers",
  'lite.menu.modifier_group.quantity_error': '[ES] Modifier selection should be between 0-99',
  'lite.menu.modifier_group.minmax_selection_decimal_error':
    '[ES] Modifier selection cannot be a decimal number',
  'lite.menu.modifier_group.min': '[ES]Min',
  'lite.menu.modifier_group.max': '[ES]Max',
  'lite.menu.modifier_group_section.input.helper': '[ES]Search existing modifier groups',
  'lite.menu.modifier_group.not_available': '[ES]No modifier groups available',
  'lite.menu.modifier_group.delete_modal.title':
    "[ES]Are you sure you want to delete the modifier group named '{{label}}'?",
  'lite.menu.modifier_group.delete.success':
    "[ES]'{{label}}' has been deleted from your modifier groups",
  'lite.menu.delete_modifier_group': '[ES]Delete Modifier Group',
  'lite.menu.modifier_group.search_for': '[ES]Search For',
  'lite.menu.modifier_group.search_menu_items_helper':
    '[ES]Use the search bar below to find menu items',
  'lite.menu.modifier_group.search_instructions_helper':
    'Use the search bar below to find kitchen instructions',
  'lite.menu.modifier_group.type_description':
    '[ES]Kitchen modifiers are things like “no ice” while item modifiers are “side salads”.',
  'lite.menu.modifier_group.menu_item_autocomplete':
    '[ES]Search for menu items to add as a modifier',
  'lite.menu.modifier_group.kitchen_instruction_autocomplete':
    '[ES]Search for existing kitchen instructions to add as a modifier or create a new one',
  'lite.menu.modifier_group.select_all_menu_item': '[ES]Select All Menu Items',
  'lite.menu.modifier_group.select_all_kitchen_instruction': '[ES]Select All Kitchen Instructions',
  'lite.menu.modifier_group.instruction_not_available': '[ES]No kitchen instructions available',
  'lite.menu.modifier.create-new': '[ES]Add Modifiers',
  'lite.menu.add.modifier.helper':
    '[ES]Step two is creating your modifier. Example would be pepperoni or pineapple for your pizza topping modifier group.',
  'lite.menu.modifier.create-form': '[ES]Create From',
  'lite.menu.modifier.modifier-type': '[ES]Modifier Type',
  'lite.menu.modifier.type.item.helper':
    '[ES]You can create multiple modifier items within one workflow, all you need to do is toggle between the different selections below and select <b style="font-family: GraphikMedium">"Add Modifier"</b>.',
  'lite.menu.modifier.add': '[ES]Add Modifier',
  'lite.menu.modifier.new.price.input_helper':
    '[ES]This will be the price of your modifier and menu item.',
  'lite.menu.modifier.new.name.input_helper':
    "[ES]Creating a new modifier will also create a new menu item. Your modifier name will be displayed on your order channels and will be the menu item's name.",
  'lite.menu.modifier.add-new': 'Add New Modifier',
  'lite.menu.ModifierCreateType.label.NEW_MENU_ITEM': '[ES]New Modifier',
  'lite.menu.ModifierCreateType.label.MODIFIER_FROM_MENU_ITEM':
    '[ES]Create from Existing Menu Item',
  'lite.menu.ModifierTypes.label.MENU_ITEM': '[ES]Menu Items',
  'lite.menu.ModifierTypes.label.INSTRUCTION': '[ES]Kitchen Instruction',
  'lite.menu.ModifierCreateType.label.KITCHEN_INSTRUCTION': '[ES]Create a Kitchen Instruction',
  'lite.menu.modifier.name.required': 'Modifier name is required',
  'lite.menu.modifier.price.input_helper':
    '[ES]Change the price of all selected items. If you would like to change the price individally, you can do so after you add your modifiers.',
  'lite.menu.modifier.is-mandatory': '[ES]Is this a mandatory modifier?',
  'lite.menu.modifier.is-mandatory.helper':
    '[ES]If yes is selected, then this modifier will be included on every menu item with this modifier group.',
  'lite.menu.modifier.selected.price': '[ES]Update Price',
  'lite.menu.modifier.selected.price.helper':
    '[ES]Would you like to change the price of the selected modifiers?',
  'lite.menu.modifier.added-modifier': '[ES]Added Modifier Items',
  'lite.menu.edit_sales_category': '[ES]Edit Sales Category',
  'lite.menu.sales_category.delete_modal.title':
    "[ES]Are you sure you want to delete the sales category named '{{label}}'?",
  'lite.menu.delete_sales_category': '[ES]Delete Sales Category',
  'lite.menu.sales_category_empty_header': '[ES]Organize your sales',
  'lite.menu.sales_category_empty_body':
    '[ES]Sales Categories can be created and used to break down your total sales into broader groups. For example, common categories include food, alcohol, and merchandise. Once your sales categories have been created and you have assigned items under their respective groups, the Sales Categories report will provide you a breakdown of the sales for each group.',
  'lite.action_irreversible': '[ES]This action is irreversible.',
  'lite.items': '[ES]{{numItem}} items',
  'lite.success': '[ES]Success!',
  'lite.edit.save.modslimit':
    '[ES]A value of 0 means there is no limit to the number of minimum or maximum modifier selections. ',
  'lite.onboarding.h1': '[ES]Hi {{name}},',
  'lite.onboarding.h2': '[ES]Tell us about your restaurant.',
  'lite.onboarding.button.submit': '[ES]Submit',
  'lite.onboarding.input.restaurant_name.label': "[ES]Restaurant's Name",
  'lite.onboarding.input.restaurant_type.label': '[ES]What kind of restaurant?',
  'lite.onboarding.options.restaurant_type.fast_food': '[ES]Quick Service/Fast Food',
  'lite.onboarding.options.restaurant_type.bar': '[ES]Bar',
  'lite.onboarding.options.restaurant_type.casual': '[ES]Casual/Family',
  'lite.onboarding.options.restaurant_type.fine_dining': '[ES]Fine Dining',
  'lite.onboarding.options.restaurant_type.ghost_kitchen': '[ES]Ghost Kitchen',
  'lite.onboarding.options.restaurant_type.other': '[ES]Other',
  'lite.onboarding.input.restaurant_other_type.error.required':
    '[ES]Please specify the type of your restaurant.',
  'lite.onboarding.input.restaurant_name.error.required': '[ES]Restaurant name is required',
  'lite.onboarding.input.restaurant_type.error.required': '[ES]Restaurant type is required',
  'lite.onboarding.input.restaurant_name.placeholder': "[ES]i.e. Emily's Cafe",
  'lite.payments.void.delete_modal.title':
    "[ES]Are you sure you want to delete the void reason named '{{label}}'?",
  'lite.payments.void.delete': '[ES]Delete Void Reason',
  'lite_report.actual_payments': 'Pagos reales',
  'lite.menu.sync.success': '[ES] Menu synced successfully.',
  'lite.menu.publishing': '[ES] Menu Publishing',
  'lite.menu.publish.success':
    "[ES] Menu configuration published successfully. Your data is about to be re-published based on your recent changes. Please note, it may take time before your changes are reflected. Don't worry - this is totally normal!",
  'lite.menu.publish_history.subtitle': '[ES]Below is a log of the last 50 publishes made.',
  'lite.publishing': '[ES] Publishing',
  'lite.publish': '[ES] Publish',
  'lite.publish_all': '[ES] Publish All',
  'lite.publish_now': '[ES] Publish Now',
  'lite.publish_now_count': '[ES] Publish Now{{c}}',
  'lite.publish_count': '[ES] Publish{{c}}',
  'lite.publish_history': '[ES] Publish History',
  'lite.publishing_history': '[ES] Publishing History',
  'lite.menu.publish.multi.failed':
    '[ES] Publishing has failed for {{failedVenuesCount}} venue(s): {{failedVenues}}',
  'lite.publish.modal.title': '[ES] Are you sure you want to publish?',
  'lite.publish.modal.copy': '[ES] Publishing will make these changes live.',
  sort: 'Orden {{type}}',
  tax_rules: 'reglas de impuesto',
  update_menu_success: '{{menuName}} ha sido actualizado',
  update_menu: 'Actualizar Menu',
  member_already_exists_title: 'El miembro ya existe',
  member_already_exists_message:
    'Ya existe un miembro con esa dirección de correo electrónico como parte de su organización.',
  go_to_profile: 'Ir al perfil',
  'lite.onboarding.success.title': '[ES]All done!',
  'lite.onboarding.success.text': '[ES]Building your dashboard...',
  error_maintenance_title: 'Estaremos de vuelta pronto',
  error_maintenance_message:
    'Estamos realizando un mantenimiento programado. Esperamos volver a estar en línea en breve. Gracias por tu paciencia.',
  error_no_bills: 'Por favor, sube una cuenta para acceder a esta función',
  error_unauthorized_report: 'No está autorizado a ver datos de este lugar.',
  'lite.verifications.success.title': '[ES] Your email was successfully verified!',
  'lite.verifications.success.subtitle': '[ES] Redirecting...',
  'lite.verifications.error.title': "[ES] We couldn't verify your email.",
  'lite.verifications.error.err_verification_consumed':
    '[ES] The email verification id requested has already been consumed and is no longer valid.',
  'lite.verifications.error.err_verification_resent':
    '[ES] The email verification id requested has already been resent.',
  'lite.verifications.error.err_verification_not_found':
    '[ES] The link you requested could not be found. Please request a new link to continue.',
  reports_pos_migration_subtitle:
    'Durante los próximos meses, tus informes se moverán a la nube en admin.touchbistro.com',
  reports_pos_migration_title: 'Los informes del POS se están trasladando a la nube',
  reports_pos_migration_link: ' https://www.touchbistro.com/tb-cloud-reporting/',
  learn_more: 'Conocer más',
  you_have_unsaved_changes:
    'You have unsaved changes that will be lost if you decide to continue. \r\n\r\n Are you sure you want to leave this page?',
  beta: 'Beta',
  'lite.edit.save.success': '[ES] Changes successfully saved!',
  'lite.settings.title': '[ES] Settings',
  'lite.settings.venue_name.label': '[ES] Venue name',
  'lite.settings.venue_name.placeholder': "[ES] Emily's Cafe",
  'lite.settings.venue_name.error.required': '[ES] Venue Name is required',
  'lite.settings.venue_id.label': '[ES] Venue ID',
  'lite.settings.email.label': 'Correo electrónico del restaurante',
  'lite.settings.email.placeholder': 'elrestaurante@restaurante.com',
  'lite.settings.email.error.required': 'El correo electrónico del restaurante es obligatorio',
  'lite.settings.email.error.pattern':
    'El correo electrónico del restaurante debe ser una dirección de correo electrónico válida',
  'lite.settings.email.error.touchbistro.agents':
    'No utilice el correo electrónico de su agente de TouchBistro para crear este lugar de prueba.',
  'lite.settings.business_number.label': 'Numero de negocio',
  'lite.settings.business_number.placeholder': 'RV56765',
  'lite.settings.phone.label': '[ES] Phone',
  'lite.settings.phone.placeholder': '[ES] +1 555 555-5555',
  'lite.settings.phone.placeholder.noCountryCode': '[ES] 1234445555',
  'lite.settings.phone.error.required': '[ES] Phone is required',
  'lite.settings.phone.error.pattern.generic': 'El teléfono debe ser un número de teléfono válido.',
  'lite.settings.phone.error.pattern.northAmerican': 'El teléfono debe tener 10 dígitos.',
  'lite.settings.phone.error.pattern':
    '[ES] Phone must be a valid phone number. E.g.: +1 2223334444',
  'lite.settings.address_line_1.label': '[ES] Address',
  'lite.settings.address_line_1.placeholder': '[ES] 85 Richmond St West',
  'settings.address_line_1.error.required': '[ES] Address is required',
  'settings.address_line_2.label': '[ES] Apartment, Suite, etc.',
  'settings.address_line_2.placeholder': '[ES] Front',
  'settings.city.label': '[ES] City',
  'settings.city.placeholder': '[ES] Toronto',
  'settings.city.error.required': '[ES] City is required',
  'settings.city.error.min': '[ES] City name should contains at least 1 character',
  'lite.supported_countries': '[ES] Select',
  'lite.supported_countries_US': '[ES] United States of America',
  'lite.supported_countries_CA': '[ES] Canada',
  'settings.country.label': '[ES] Country',
  'settings.country.error.required': '[ES] Country is required',
  'settings.state.label': '[ES] State',
  'settings.state.placeholder': '[ES] ON',
  'lite.settings.state.error.required': '[ES] State is required',
  'settings.state.error.pattern':
    'Debe parecerse a ON, NY u otro código de estado/provincia de 2 letras',
  'settings.postal_zip_code.label': '[ES] Zip/Postal Code',
  'settings.postal_zip_code.placeholder': '[ES] 12312',
  'settings.postal_zip_code.error.required': '[ES] Zip/postal code is required',
  'lite.settings.currency.label': '[ES] Currency',
  'lite.supported_currency': '[ES] Select',
  'lite.supported_currency_CAD': '[ES] CAD',
  'lite.supported_currency_USD': '[ES] USD',
  'lite.settings.currency.error.required': '[ES] Currency is required',
  'lite.supported_timezone': '[ES] Select',
  'lite.supported_timezone_america_toronto': '[ES] America/Toronto',
  'lite.settings.timezone.label': '[ES] Timezone',
  'lite.settings.timezone.error.required': '[ES] Timezone is required',
  'lite.settings.save': '[ES] Save',
  'icon.helper': '[ES] This icon will appear on your connected products, such as your POS.',
  online_ordering_pickup: 'Recoger',
  online_ordering_pickup_delivery: '(Recogida y entrega)',
  pos_ingestion_status: 'POS Ingestion Status',
  detailed_view: 'Vista detallada',
  entries_per_page: 'Entradas por página',
  error_invalid_date: 'La fecha no es válida. Por favor, ingresa un rango de fechas válidas',
  error_invalid_time: '[ES] Please select or enter a valid time.',
  error_start_time_after_end_time: '[ES] Ending time must be after the starting time.',
  resync: 'Data Resync',
  resync_from: 'Resync from',
  resync_to: 'Resync to',
  report_moved_body:
    'El informe {{report_title}} se fusionó con el informe {{redirect_report_title}}.',
  reports_downtime_banner_placeholder_title: 'Lo sentimos, algo salió mal',
  reports_downtime_banner_placeholder_body:
    'Algo salió mal. Estamos trabajando en ello. Pedimos disculpas por las molestias.',
  retired_legacy_account_modal_title: 'Create and Sign in with a TouchBistro User Account',
  retired_legacy_account_modal_text:
    'To make it easier for staff to manage operations and access information, every team member can now create their own TouchBistro user account under <2>{{venueName}}</2>. These accounts are unique to each individual and can be assigned a role, giving each user a specific set of permissions to manage and view select aspects of the business in Cloud.<1></1><1></1>According to our records, members that can have admin rights to invite other users include: <2>{{adminUsernames}}</2>. Please contact them to proceed with your account set-up.<1></1><1></1>If your email was listed above, please log into Cloud with the credentials you used to set up your Admin user account.',
  got_it: '[ES] Got it!',
  visit_staff_page: '[ES] Visit Staff Page',
  signup_revoke_legacy_user_access_modal_title:
    '[ES] You No Longer Have Access to Cloud with this Legacy Account',
  signup_revoke_legacy_user_access_modal_text:
    '[ES] You will not be able to complete your tasks on Cloud until your account has been upgraded. Click continue to view the next steps to upgrade your account.',
  signup_reminder_modal_create_admin_button: '[ES] Create an Admin Account',
  signup_reminder_modal_title: 'Finish Creating the First Admin User Account',
  signup_reminder_modal_text:
    '[ES] The first admin user account for <1>{{venueName}}</1> has not been created yet. This step is required in order for staff to set up their own TouchBistro user accounts, which will make it easier for them to manage operations and share information.<2></2> According to our records, the first admin can be one of the following members: <1>{{email}}</1>. An email has been sent to them with instructions to complete their account creation. If this email has not been received, please click the resend button below.<2></2> If you have not been identified as the first admin, please disregard this message. If you hold authority and should have admin access but are not on this list please create an admin account.',
  signup_reminder_modal_cta: 'Resend Email',
  signup_confirmation_header: '¡Su cuenta en la nube se ha agregado con éxito!',
  signup_confirmation_body:
    'Gracias por aceptar la invitación. Ahora, tiene acceso a los lugares al iniciar sesión en la nube.',
  signup_confirmation_button: 'Ir a la nube',
  sales_by_table_and_server: 'Ventas por mesa y servidor',
  reports_by_server: 'por servidor',
  reports_by_table: 'por tabla',
  reports_actual_deposit: '[ES] Actual Deposit',
  reports_actual_deposit_required: '[ES] Please enter a value for the deposit',
  reports_deposit_action: '[ES] Action',
  reports_deposit_comment: '[ES] Comment',
  reports_deposit_date_updated: '[ES] Date Updated',
  reports_deposit_comment_error:
    '[ES] You cannot have more than 500 characters or any special characters',
  reports_deposit_comment_required: '[ES] A comment is required.',
  reports_deposit_business_day: '[ES] Business Day Deposit',
  reports_cash_deposit_summary: '[ES] Cash Deposit Summary',
  reports_expected_deposit: '[ES] Expected Deposit',
  reports_over_short: '[ES] Over/Short',
  reports_create_fails: '[ES] Unable to create Cash Deposit',
  reports_edit_fails: '[ES] Unable to adjust Cash Deposit',
  reports_edit_succeeds: '[ES] Deposit Updated',
  reports_create_tooltip_header: '[ES] One cash deposit per business day',
  reports_create_tooltip_body:
    '[ES] You can only create one cash deposit per business day. You can adjust the actual cash deposit by editing the entry for the business day.',
  reports_amount_multiple_decimal_error: "[ES] You can only have one '.' in the amount",
  reports_amount_too_many_characters_no_decimal: '[ES] You cannot have more than 16 characters',
  reports_amount_too_many_characters_decimal:
    '[ES] You cannot have more than 2 decimal places, or more than 16 characters',
  reports_cash_deposit_history_title: '[ES] History',
  reports_cash_deposit_history_subtitle: '[ES] Here is a list of changes made to this cash deposit',
  timesheet_summary_and_details_disclaimer:
    'Las pausas no remuneradas no se incluirán en el cálculo de las horas pagaderas.',
  start_to_end: '{{start_time}} to {{end_time}}',
  failed_to_retrieve_venue_setting:
    'No se pudo recuperar el rango de tiempo del informe del lugar.',
  payments_report: 'Informe de pagos',
  payments_report_payment_total: 'total de la transacción',
  payments_report_payment_count: 'Recuento de transacciones',
  payments_report_cash_tips_total: '[ES] Cash Tips Total',
  payments_report_tips_total: 'Total de propinas',
  payments_report_chart_title: 'Por medio de pago',
  audit_payments_reports_banner_title:
    'Los informes de pago se fusionarán y se moverán a una nueva sección titulada "Pagos".',
  audit_payments_reports_banner_description:
    'Durante las próximas semanas, se creará una nueva sección "Pagos" y los informes "Pagos" y "Detalles de pago" se fusionarán en un solo informe en esta misma sección. Aún podrá descargar informes individuales en su formato preferido y continuar recibiendo un informe individual como parte de sus informes programados.',
  menu_menu_item_cost_report: 'Costo del elemento de menú',
  net_sales: 'Ventas Netas',
  bill_paid_time: 'Hora de Cuenta Pagada',
  venue_admin_agent: 'Venue Admin Agent',
  venue_admin_agent_mimicking: '[ES] Viewing as {{mimickedUser}}',
  enable_menu_input: 'Habilitar edición de menú',
  cloud_access_successfully_enabled:
    'Se ha habilitado correctamente el acceso a la nube para {{venueName}}.',
  menu_editing_successfully_enabled:
    'Se ha habilitado correctamente la entrada de menú para {{venueName}}.',
  menu_input_already_enabled: 'La entrada de menú para {{venueName}} ya está habilitada.',
  menu_input_venue_not_found: 'No se envió venueXRefID al intentar habilitar la entrada de menú',
  'venue.settings.service_time.error.required': 'Se requiere tiempo de servicio',
  'venue.settings.timezone.error.required': 'Se requiere una zona horaria',
  payments_by_section: 'Payments by Section',
  labor_summary: 'Resumen de jornada',
  audit_summary: 'Resumen de auditoría',
  help_link_landing_title: '¡Visita el nuevo sitio de soporte de TouchBistro!',
  help_link_landing_body:
    'Hemos rediseñado para ti el sitio de soporte help.touchbistro.com para que resulte más fácil encontrar las respuestas que necesitas y solucionar problemas de manera más rápida. Actualizado con una funcionalidad de búsqueda renovada, guías de ayuda mas fáciles de seguir, videos instructivos y mucho más, el sitio de soporte de TouchBistro es la forma más rápida de obtener ayuda.',
  help_link_landing_link: 'Explore el sitio de soporte aquí',
  payments_report_decommission_body:
    'Este informe se ha reubicado bajo el nombre de "Informe de pagos" en la sección "Pagos"',
  report_decommission_moved_section: 'Este informe se ha movido a la sección "{{section}}".',
  report_decommission_merged_to:
    'Este reporte se ha fusionado con "{{merged_report}}" en la sección "{{section}}".',
  download_this_report: 'Obtener este informe',
  send_this_report: 'Enviar este informe',
  schedule_this_report: 'Programe este informe',
  select_wall_color: 'Seleccionar un color de pared',
  hex_code: 'Código hexadecimal',
  corporation: '[ES]Corporation',
  corporation_name: '[ES]Corporation Name',
  corporation_admins: '[ES]Admins',
  corporation_number_of_venues: '[ES]Number of venues',
  corporation_name_length_error: 'El nombre de la corporación debe tener menos de 100 caracteres',
  corporation_name_error: 'El nombre de la corporación no puede estar vacío',
  corporation_rootgroupxrefid: '[ES]Corporation Root Group ID',
  corporation_id: 'Identificacion del Corporación',
  account_type: '[ES]Account Type',
  account_type_upgraded: '[ES]Upgraded Email Account',
  account_type_legacy: '[ES]Legacy Account',
  corporation_salesforce_id: '[ES]Corporation Salesforce ID',
  corporation_salesforce_link:
    'https://touchbistro.lightning.force.com/lightning/r/Account/{{salesforceXRefID}}/view',
  venue_salesforce_id: 'Venue Salesforce ID',
  venue_salesforce_link:
    'https://touchbistro.lightning.force.com/lightning/r/Account/{{salesforceXRefID}}/view',
  first_name_length_error: '[ES]First Name must be less than 100 characters long',
  last_name_length_error: '[ES]Last Name must be less than 100 characters long',
  valid_email_error: '[ES]Enter a valid email address',
  valid_venues_list_error: '[ES]Enter a list of valid comma-separated venue IDs',
  multiuser_migration_form_submit_error: '[ES]There has been an error while submitting the form',
  multiuser_migration_form_submit_success:
    '[ES]Multi-Unit Migration Form has been successfully submitted',
  multiuser_migration_form_update_success:
    '[ES]User {{emailProvided}} already exists. They now have admin permissions to the following venues: {{listOfVenuesFromForm}}',
  multiuser_migration_form_archive_success:
    '[ES]The following users have been archived: {{listOfArchivedUsers}}',
  multiuser_migration_form_archive_error: '[ES]Archive process failed, please try again',
  payments_summary: 'Resumen de pagos',
  sales_trend: 'Tendencia de ventas',
  sales_trends: 'Tendencias de ventas',
  weekly_sales_trend: 'Tendencia de ventas semanales',
  monthly_sales_trend: 'Tendencia de ventas mensual',
  month: 'Mes',
  profile: 'Mi Perfil',
  pending_report_move_title:
    'El informe "{{reportName}}" se moverá a la sección "{{moveToSectionName}}".',
  pending_report_move_body:
    'En las próximas semanas, moveremos el informe "{{reportName}}" de la sección "{{previousSectionName}}" a la sección "{{moveToSectionName}}". Podrás descargarlo en formato CSV o XLSX. Seguirás recibiendo el informe si lo programaste.',
  pending_report_merge_title:
    'El reporte "{{reportName}}" se fusionó en un nuevo informe "{{mergeReportName}}".',
  pending_report_merge_body:
    '"{{reportName}}" e "{{reportNameTwo}}" se han fusionado en un nuevo informe "{{mergeReportName}}" en "{{mergeSectionName}}". Podrá descargar cada informe en su formato preferido. Recibirá el nuevo informe si ha programado previamente cualquiera de estos informes.',
  pending_report_rename_title:
    'El informe "{{oldReportName}}" se modificará y renombrará como "{{newReportName}}".',
  pending_report_rename_body:
    'Durante las próximas semanas, el informe {{oldReportName}} se modificará y renombrará como "{{newReportName}}". Seguirás recibiendo el informe con el nuevo nombre como parte de tus informes programados.',
  sales_trend_net_sales_ytd: 'Net Sales YTD',
  sales_trend_monthly_top_5_venues: 'Top Venues',
  sales_trend_monthly_top_3_months: 'Top Months',
  sales_trend_weekly_top_3_weeks: 'Top Weeks',
  sales_daily_hourly: 'Ventas diarias y por hora',
  unsaved_changes: '[ES]There are unsaved changes',
  report_merged_into_new_report: 'El informe seleccionado se ha integrado en un nuevo informe',
  configuration: '[ES]Configuration',
  your_reports: 'Tus informes',
  you_dont_have_any_scheduled_reports:
    'Usted no tiene ningún informe programado. Empecemos por programar su primer informe.',
  search_reports: 'Buscar informes...',
  basic_list_view_delete_prompt: '¿Está seguro de que desea eliminar {{candidateForDeletion}}?',
  your_reports_schedule_report: 'Programar informe',
  report_title: 'Título del informe',
  reports: 'Informes',
  created_by: 'Creado por',
  scheduled_report_deleted: '{{scheduled_report}} eliminado.',
  report_deleted: 'Informe eliminado',
  sorry_couldnt_find_reports_search:
    'Lo sentimos, no pudimos encontrar informes que se ajusten a los criterios de búsqueda. Puedes volver a intentarlo cambiando tu consulta.',
  every_2_weeks: 'Cada 2 semanas',
  'ge.guest_engagement': 'Interacción con los clientes',
  'ge.reward_redemptions': 'Redención de Recompensas',
  'ge.point_earnings': 'Puntos ganados',
  'ge.claimed_items': 'Artículos reclamados',
  'ge.digital_gift_card_usage': '[ES] Digital Gift Card Usage',
  'ge.download_report': 'Descargar este Informe',
  'ge.download_report_message':
    'Este informe solo se puede descargar. Seleccione un intervalo de fechas. La visualización en tiempo real aún no está disponible.',
  error_page_reasons: 'Posibles razones:',
  error_page_you_can: 'Puedes probar:',
  error_page_btn_contact: 'Soporte técnico',
  error_page_btn_support: 'Centro de soporte técnico',
  error_page_btn_dashboard: 'Tablero',
  'shared.server_error_err_unknown_code': '[ES] Unknown Code',
  'shared.server_error_err_verification_expired':
    '[ES] The email verification id requested has expired. Please request a new link to continue.',
  error_page_client_message: '[ES] Oops, something unexpected happened. Please try refreshing.',
  error_page_400_message: 'Lo sentimos. No podemos procesar su solicitud.',
  error_page_400_error_code: 'Código de error: 400 - Solicitud incorrecta',
  error_page_400_reason_1: 'La URL puede contener caracteres como *, &, %',
  error_page_400_reason_2: 'Si está subiendo un archivo, es posible que sea demasiado grande',
  error_page_400_reason_3: 'Las cookies pueden estar desactualizadas',
  error_page_400_option_1:
    'Verifique la URL que ingresó no contener caracteres no válidos como *, &, %',
  error_page_400_option_2: 'Verifique el tamaño del archivo que está tratando de subir',
  error_page_400_option_3: 'Elimina el caché y las cookies de tu navegador',
  error_page_400_option_4: 'Póngase en contacto con el centro de soporte técnico',
  error_page_401_message: 'Lo sentimos, no pudimos autenticar sus credenciales',
  error_page_401_error_code: 'Código de error: 401 - No autorizado',
  error_page_401_reason_1: 'La página puede haberse cargado mal',
  error_page_401_reason_2: 'La página que está viendo requiere autorizacion con usuario/contraseña',
  error_page_401_option_1: 'Recarga la página',
  error_page_401_option_2: 'Asegúrese de haber iniciado sesión en su tablero de TB',
  error_page_401_option_3: 'Póngase en contacto con el centro de soporte técnico',
  error_page_403_message: 'Lo sentimos, no tienes permiso para ver esta página.',
  error_page_403_error_code: 'Código de error: 403 - Prohibido',
  error_page_403_reason_1: 'Careces de los derechos para acceder a esta página',
  error_page_403_option_1:
    'Póngase en contacto con el administrador de su sitio para obtener derechos de acceso',
  error_page_403_option_2: 'Póngase en contacto con el centro de soporte técnico',
  error_page_404_message: 'Lo sentimos, no podemos encontrar la página que busca',
  error_page_404_error_code: 'Código de error: 404 - Página no encontrada',
  error_page_404_reason_1: 'La página que solicita no existe',
  error_page_404_reason_2: 'La URL que ingresó podría ser incorrecta',
  error_page_404_option_1: 'Escribir la URL de nuevo',
  error_page_404_option_2: 'Vuelva a su tablero de administración',
  error_page_404_option_3: 'Póngase en contacto con el centro de soporte técnico',
  error_page_422_message: 'Interacción no válida',
  error_page_422_error_code: 'Código de error: 422 - Entidad no procesable',
  error_page_422_reason_1: 'El valor ingresado es incorrecto o inválido',
  error_page_422_option_1: 'Cambie el valor ingresado y vuelva a intentar',
  error_page_422_option_2: 'Póngase en contacto con el centro de soporte técnico',
  error_page_5XX_message: 'Lo sentimos, hemos encontrado un error inesperado',
  error_page_5XX_error_code: 'Código de error: {{statusCode}} - Error interno del servidor',
  error_page_5XX_reason_1: 'Nuestros servicios están deshabilitados por mantenimiento programado',
  error_page_5XX_reason_2: 'Hay un error desconocido',
  error_page_5XX_option_1: 'Vuelva a intentar esta página después de un tiempo',
  error_page_5XX_option_2: 'Limpia la memoria caché y las cookies de tu navegador',
  error_page_5XX_option_3: 'Póngase en contacto con el centro de soporte técnico',
  select_date: '[ES] Select Date',
  select_a_date: '[ES] Select a Date',
  select_a_time: '[ES] Select a Time',
  resend_pin: 'Reenviar NIP al Staff',
  pin_sent: 'NIP enviado',
  pin_send_success: 'NIP enviado a {{email}}',
  pin_send_failure: 'Error al intentar enviar el NIP',
  no_timezone_warning: 'Por favor actualiza la configuración de tu zona horaria.',
  go_to_settings: 'Ir a configuración.',
  none: '[ES]None',
  unlimited: 'Ilimitado',
  user: '[ES] User',
  comment: '[ES] Comment',
  view_request: 'Ver solicitud',
  view_as_staff_member: '[ES] View as staff member',
  staff_signup_helper_text:
    'Su dirección de correo electrónico se utilizará para iniciar sesión en TouchBistro Cloud. Puede cambiar su correo electrónico en la configuración del perfil.',
  input_optional: '(Opcional)',
  input_required: '(Obligatorio)',
  base_warning_banner:
    '[ES] Heads up! You are now managing <1>{{domain}}</1> for <2>{{baseName}}</2>',
  base_warning_banner_venue:
    '[ES] Heads up! You are now managing <1>{{domain}}</1> for Venue <2>{{baseName}}</2>',
  base_warning_banner_group:
    '[ES] Heads up! You are now managing <1>{{domain}}</1> for Group <2>{{baseName}}</2>',
  base_warning_banner_corporation:
    '[ES] Heads up! You are now managing <1>{{domain}}</1> for Corporation <2>{{baseName}}</2>',
  base_warning_banner_multi_venue:
    '[ES] Heads up! You are now managing <1>{{domain}}</1> for multiple selected venues.',
  drop_file_here: '[ES] Drop file here or <1>click to browse</1>',
  image_file_support_info:
    '[ES] Supports {{fileTypes}}. Up to {{fileSize}} and at least {{imageWidth}} x {{imageHeight}}px',
  image_file_support_info_max_resolution:
    'Supports {{fileTypes}}. Up to {{fileSize}}, at least {{imageWidth}} x {{imageHeight}}px and up to {{imageMaxWidth}} x {{imageMaxHeight}}px',
  image_incorrect_minimum_resolution:
    '[ES] File must be over minimum resolution ({{imageWidth}} x {{imageHeight}}px)',
  image_incorrect_maximum_resolution:
    '[ES] File must be under maximum resolution ({{imageWidth}} x {{imageHeight}}px)',
  image_too_big: '[ES] File must be under {{maxFileSize}}',
  image_incorrect_file_type: '[ES] File must be of type {{fileTypes}}',
  crop_modal_title: '[ES] Crop Image',
  crop_modal_subtitle: '[ES] Only the image within the cropped area will be visible to diners.',
  error_unlicensed_venue: '[ES] Your venue does not have a valid licence, please contact support.',
  mixed: '[ES] Mixed',
  expand_pay: '[ES] Expand Pay',
  colapse_pay: '[ES] Collapse Pay',
  everyday: 'Todos los días',
  all_day: 'Todo el día',
  Copy: '[ES]Copy',
  Copied: '[ES]Copied',
  'day_parts.add_another_time': 'Agregar otro horario',
  'day_parts.add_another_day_part': 'Agregar otra parte del día',
  'day_parts.apply_to_days': 'Aplicar a los días',
  'day_parts.configure': 'Configurar las partes del día',
  'day_parts.day_part': 'Parte del día',
  'day_parts.create_day_part': 'Crear parte del día',
  'day_parts.instruction':
    'Crea partes del día dividiendo tus horas de negocio en diferentes segmentos (por ejemplo, Desayuno, Brunch, Almuerzo, Cena, etc.). Estas partes solo se pueden definir dentro de tu horario de servicio.',
  'day_parts.name': 'Nombre de la parte del día',
  'day_parts.remove': 'Eliminar parte del día',
  'day_parts.save_button': 'Guardar partes del día',
  'day_parts.save_message':
    'Los cambios realizados en sus partes del día afectarán la forma en que se le mostrarán los datos en sus reportes.',
  'day_parts.validation_times_overlap':
    'Esta parte del día se superpone con una existente. Por favor, ajusta estos horarios para que no se superpongan.',
  'day_parts.validation_duplicated_name':
    'Este nombre es el mismo que el de otra parte del día. Por favor, renombra esta parte del día.',
  'day_parts.validation_select_days':
    'Por favor, selecciona al menos un día al cual se aplicará este horario.',
  'weekdays.sunday_short': 'do',
  'weekdays.monday_short': 'lu',
  'weekdays.tuesday_short': 'ma',
  'weekdays.wednesday_short': 'mi',
  'weekdays.thursday_short': 'ju',
  'weekdays.friday_short': 'vi',
  'weekdays.saturday_short': 'sá',
  host: 'Anfitrión',
  host_role_desc:
    'Puede acceder al Sistema de Reservaciones y  al Sistema para Ordenar en linea y Entrega',
  overtime_rules_success: '[ES] Overtime Rules saved successfully',
  sun: '[ES] Sunday',
  mon: '[ES] Monday',
  tue: '[ES] Tuesday',
  wed: '[ES] Wednesday',
  thu: '[ES] Thursday',
  fri: '[ES] Friday',
  sat: '[ES] Saturday',
  closed: '[ES] Closed',
  showEntries: '[ES] Showing {{from}} to {{to}} of {{total}} entries',
  showPage: '[ES] Page {{current}}/{{total}}',
  entriesPerPage: '[ES] Entries per page',
  entries: '[ES] {{number}} Entries',
}

const es = {
  ...general,
  ...venueInfo,
  ...agent,
  ...backoffice,
  ...devices,
  ...floorplan,
  ...guestEngagement,
  ...lite,
  ...menus,
  ...ordering,
  ...permissions,
  ...reports,
  ...reservations,
  ...settings,
  ...menuManagement,
  ...staff,
  ...crm,
}

export default es
