import { FC, ReactNode } from 'react'
import {
  // eslint-disable-next-line no-restricted-imports
  Typography as MUITypography,
  TypographyTypeMap,
  TypographyProps as MUITypographyProps,
} from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { tbPalette } from '~/src/shared/styles/theme'

interface TypographyProps {
  variant: TypographyTypeMap['props']['variant']
  children?: ReactNode
  color?: keyof typeof tbPalette
  id?: string
  align?: MUITypographyProps['align']
  strikeThrough?: boolean
  noMargin?: boolean
  disabled?: boolean
  noWrap?: boolean
  inline?: boolean
  overflowEllipsis?: boolean
  textTransform?: TypographyTypeMap['props']['textTransform']
  breakWordWrap?: boolean
  underline?: boolean
}

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body: 'p',
  bodyBold: 'p',
  pretitle: 'p',
  subtitle: 'p',
  caption: 'p',
  code: 'p',
}

const useStyles = makeStyles()(() => ({
  strikeThrough: {
    textDecoration: 'line-through',
  },
  noMargin: {
    margin: 0,
  },
  disabled: {
    color: tbPalette.slate,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  breakWordWrap: {
    overflowWrap: 'break-word',
  },
  inline: {
    display: 'inline-block',
  },
  overflowEllipsis: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  underline: {
    textDecoration: 'underline',
  },
}))

const Typography: FC<TypographyProps> = ({
  id,
  align,
  children,
  color,
  variant,
  strikeThrough = false,
  noMargin = false,
  disabled = false,
  noWrap = false,
  inline = false,
  overflowEllipsis = false,
  textTransform = 'initial',
  breakWordWrap,
  underline,
}) => {
  const { cx, classes } = useStyles()
  let finalColor = color ? tbPalette[color] : undefined
  if (disabled) {
    finalColor = tbPalette.slate
  }

  return (
    <MUITypography
      id={id}
      data-pw={id}
      data-testid={id}
      variant={variant}
      align={align}
      variantMapping={variantMapping}
      className={cx(
        strikeThrough && classes.strikeThrough,
        noMargin && classes.noMargin,
        disabled && classes.disabled,
        noWrap && classes.noWrap,
        inline && classes.inline,
        overflowEllipsis && classes.overflowEllipsis,
        breakWordWrap && classes.breakWordWrap,
        underline && classes.underline
      )}
      color={finalColor}
      textTransform={textTransform}
    >
      {children}
    </MUITypography>
  )
}

export default Typography
